import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { centerProptype } from '../../redux/reducers/dispatchCenters';
import CentersListEntry from './CentersListEntry';

import './CentersList.scss';
import { removeAPITokenDispatch } from '../../redux/actions';
import { replaceText } from '../../helper';

/**
 * Wrapper component for all the center list entries.
 *
 * @compenent CenterListEntry - single center entry
 */
class CenterList extends PureComponent {
    componentDidMount() {
        removeAPITokenDispatch();
    }

    render() {
        return (
            <div className="centersList">
                {this.props.paginatedCenters.map(center => (
                    <CentersListEntry center={center} key={center.id} />
                ))}
                {this.props.paginatedCenters.length === 0 ? (
                    <div className="centersList__noResults">
                        {replaceText(this.props.texts, 'noresults')}
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

// PropTypes for this Component
CenterList.propTypes = {
    paginatedCenters: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    history: PropTypes.any,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        paginatedCenters: state.centersPagination.paginatedCenters,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(CenterList);
