export const mergeTwoArraysWithObjects = (arr1: any[], arr2: any[], identifier: string) => {
    const mergedMap = new Map();

    // Iterate over the first array and add objects to the map
    arr1.forEach(obj => {
        const id = obj[identifier];
        if (!mergedMap.has(id)) {
            mergedMap.set(id, obj);
        }
    });

    // Iterate over the second array and add/update objects in the map
    arr2.forEach(obj => {
        const id = obj[identifier];
        mergedMap.set(id, { ...mergedMap.get(id), ...obj });
    });

    // Convert the merged map values back to an array
    const mergedArray = Array.from(mergedMap.values());

    return mergedArray;
}