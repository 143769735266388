import logoLink from '../resources/boschhomecomfort.png';

export const cssVariables = {
    active: '#008ECF',
    inactive: '#005691',

    primary_light: '#CCE8F5',
    primary: '#008ECF',
    primary_dark: '#0072a6',
    text_on_primary: '#fff',
    primary_transparent: '#CCE8F5',

    secondary: '#005691',
    secondary_light: '#ccdde9',
    text_on_secondary: 'white',
    secondary_transparent: '#ccdde9',

    font_color: '#000000',
    link_color: '#005691',

    font: 'Roboto, Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#008ECF',
    activate_color: '#BFC0C2',
    deactivate_color: '#BFC0C2',
    delete_color: '#005691',    

    dropdown_background: '#CCE8F5',

};

export const logo = logoLink;
