import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Delete from '../icons/Delete';
import Edit from '../icons/Edit';

import { connect } from 'react-redux';
import Save from '../icons/Save';
import clsx from 'clsx';
import { isValidPhone, isValidText, replaceText } from '../../helper';

export const CenterPhonebookEntryComponent = ({
    entry,
    deleteEntry,
    saveEditedEntry,
    texts,
}) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [nameValidationError, setNameValidationError] = useState(false);
    const [roleValidationError, setRoleValidationError] = useState(false);
    const [name, setName] = useState(entry.name);
    const [role, setRole] = useState(entry.role);
    const [phone, setPhone] = useState(entry.phone);

    const validate = () => {
        setPhoneError(false);
        setNameError(false);
        setRoleValidationError(false);
        setNameValidationError(false);

        if (phone === '') setPhoneError(true);
        if (!isValidPhone(phone)) setPhoneError(true);
        if (name === '') setNameError(true);
        if (!isValidText(name)) setNameValidationError(true);
        if (name === '') setNameError(true);
        if (!isValidText(role)) setRoleValidationError(true);
        if (
            phone === '' ||
            name === '' ||
            !isValidText(name) ||
            !isValidText(role) ||
            !isValidPhone(phone)
        )
            return false;
        return true;
    };

    const editEntry = () => {
        setIsEditMode(true);
    };

    const saveEntry = entry => {
        const isValid = validate();
        if (isValid) {
            const newEntry = {
                ...entry,
                name: name,
                phone: phone,
                role: role,
            };
            setIsEditMode(false);
            saveEditedEntry(newEntry);
        }
    };

    if (isEditMode) {
        return (
            <div className="phonebook__entry phonebook__entry--edit">
                <div
                    className={clsx({
                        'has-error': nameError || nameValidationError,
                    })}>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        maxLength={100}
                    />
                    <span className="error-message">
                        {nameError &&
                            replaceText(texts, 'phonebook.error.name')}
                        {nameValidationError &&
                            replaceText(texts, 'phonebook.error.invalid')}
                    </span>
                </div>
                <div className={clsx({ 'has-error': roleValidationError })}>
                    <input
                        id="role"
                        type="text"
                        value={role}
                        onChange={e => setRole(e.target.value)}
                        maxLength={100}
                    />
                    <span className="error-message">
                        {replaceText(texts, 'phonebook.error.invalid')}
                    </span>
                </div>
                <div className={clsx({ 'has-error': phoneError })}>
                    <input
                        id="phone"
                        type="text"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        maxLength={18}
                    />
                    <span className="error-message">
                        {replaceText(texts, 'phonebook.error.phone')}
                    </span>
                </div>
                <span className="phonebook__entry-buttons">
                    <button
                        className="phonebook__save"
                        onClick={() => saveEntry(entry)}>
                        <Save />{' '}
                        <span>{replaceText(texts, 'action.save')}</span>
                    </button>
                </span>
            </div>
        );
    } else {
        return (
            <div className="phonebook__entry">
                <span className="phonebook__entry-value">{entry.name}</span>
                <span className="phonebook__entry-value">{entry.role}</span>
                <span className="phonebook__entry-value">{entry.phone}</span>
                <span className="phonebook__entry-buttons">
                    <button onClick={() => editEntry(entry)}>
                        <Edit />{' '}
                        <span>{replaceText(texts, 'action.edit')}</span>
                    </button>
                    <button onClick={() => deleteEntry(entry)}>
                        <Delete />{' '}
                        <span>{replaceText(texts, 'action.delete')}</span>
                    </button>
                </span>
            </div>
        );
    }
};

// PropTypes for this Component
CenterPhonebookEntryComponent.propTypes = {
    entry: PropTypes.object,
    deleteEntry: PropTypes.func,
    saveEditedEntry: PropTypes.func,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const CenterPhonebookEntry = connect(mapStateToProps)(
    CenterPhonebookEntryComponent
);
