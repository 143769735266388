import React from 'react';

function Toggle() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">

            <path
                d="M10.441 34.342a1.441 1.441 0 0 1-1.019-2.46l21.4-21.4a1.442 1.442 0 1 1 2.039 2.039l-21.4 21.4a1.434 1.434 0 0 1-1.019.421Z"
                data-name="Path 3083"
            />
            <path
                d="M17.237 32.259h11.311a.6.6 0 0 0 .6-.6v-8.4a5.349 5.349 0 0 0-.551-2.36l-11.364 11.36Z"
                data-name="Path 3084"
            />
            <path
                d="M19.362 17.852h-2.82a5.406 5.406 0 0 0-5.4 5.4v2.824l8.224-8.224Z"
                data-name="Path 3085"
            />
            <path d="M20.143 16.051a5.025 5.025 0 1 0-5.025-5.025 5.028 5.028 0 0 0 5.025 5.029" />
        </svg>
    );
}

export default Toggle;
