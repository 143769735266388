import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Headline.scss';

/**
 * simple headline component
 */

function Headline({ small, headline, subline, children }) {
    const classes = clsx('headline', {
        'headline--small': small,
    });
    return (
        <div className={classes}>
            <h2>
                <strong>{headline}</strong>
                <span>{subline}</span>
            </h2>
            {children}
        </div>
    );
}

Headline.defaultValues = {
    small: false,
};

// PropTypes for this Component
Headline.propTypes = {
    headline: PropTypes.string,
    subline: PropTypes.string,
    small: PropTypes.bool,
    children: PropTypes.any,
};

export default Headline;
