import React, { PureComponent } from 'react';
import { replaceText } from '../helper';
import { goToLogin } from '../OAuth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from './global/Header';

/**
 * Redirects to the login page
 */
class Login extends PureComponent {
    componentDidMount() {
        goToLogin();
    }
    render() {
        return (
            <div className="login">
                <Header />
                <div className="container">
                    {replaceText(this.props.texts, 'login.redirect')}
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(Login);
