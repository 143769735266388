import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './ToggleSwitch.scss';

/**
 * Simple toggle switch
 */

const ToggleSwitch = props => {
    const [toggleIsBusy, setToggleIsBusy] = useState(false);
    let toggleTimeoutRef = useRef();

    const toggle = () => {
        setToggleIsBusy(true);
        if (props.isDisabled || toggleIsBusy) return;
        props.changeHandler();
        toggleTimeoutRef.current = setTimeout(() => {
            setToggleIsBusy(false);
        }, 1000);
    };

    const classes = clsx('toggleSwitch', {
        'toggleSwitch--active': props.isActive,
        'toggleSwitch--disabled': props.isDisabled,
        'toggleSwitch--sub': props.sub,
        'toggleSwitch--busy': toggleIsBusy,
    });

    useEffect(() => {
        clearTimeout(toggleTimeoutRef.current);
    }, []);

    return (
        <label className={classes} id={props.id}>
            <span>{props.label}</span>
            <input type="checkbox" onChange={toggle} checked={props.isActive} />
            <div className="toggleSwitch__background">
                <div className="toggleSwitch__line"></div>
                <div className="toggleSwitch__switch"></div>
            </div>
        </label>
    );
};

// PropTypes for this Component
ToggleSwitch.propTypes = {
    changeHandler: PropTypes.func,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    id: PropTypes.string,
    sub: PropTypes.bool,
    isActive: PropTypes.bool,
};

export default ToggleSwitch;
