import React from 'react';

function AddUser() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <path d="m60,27.66c-17.95,0-32.5,14.55-32.5,32.5s14.55,32.5,32.5,32.5,32.5-14.55,32.5-32.5-14.55-32.5-32.5-32.5Zm15.51,35.41h-12.6v12.6c0,1.61-1.3,2.91-2.91,2.91s-2.91-1.3-2.91-2.91v-12.6h-12.6c-1.61,0-2.91-1.3-2.91-2.91s1.3-2.91,2.91-2.91h12.6v-12.6c0-1.61,1.3-2.91,2.91-2.91s2.91,1.3,2.91,2.91v12.6h12.6c1.61,0,2.91,1.3,2.91,2.91s-1.3,2.91-2.91,2.91Z"/>
        </svg>
    );
}

export default AddUser;
