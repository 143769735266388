import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Dropdown contents wrapper.
 */
class DropDownContent extends PureComponent {
    render() {
        return (
            <div>
                {this.props.items.map(item => (
                    <div
                        className="drowdown__item"
                        key={item.id}
                        onClick={() =>
                            this.props.clickElement(item.label, item.id)
                        }>
                        <span>{item.label}</span>
                    </div>
                ))}
            </div>
        );
    }
}

// PropTypes for this Component
DropDownContent.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    clickElement: PropTypes.func,
};

export default DropDownContent;
