import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './redux/store';

import './index.scss';
import App from './App';

/**
 * Provides the redux wrapper for the whole application.
 */

const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

document.getElementById('root').classList.add(process.env.REACT_APP_THEME);
