import {
    RESET_CURRENT_INVITE_CONFIG,
    SET_CURRENT_INVITE_CONFIG,
} from '../actionTypes';

export const initialState = {
    id: null,
    goodbyeText: null,
    logo: null,
    messageSender: null,
    messageSubject: null,
    messageText: null,
};

/**
 * current invite config reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const currentInviteConfigReducer = (state = initialState, action) => {
    if (action.type === SET_CURRENT_INVITE_CONFIG) {
        return {
            ...state,
            ...action.currentInviteConfig,
        };
    }

    if (action.type === RESET_CURRENT_INVITE_CONFIG) {
        return initialState;
    }

    return state;
};

export default currentInviteConfigReducer;
