import React from 'react';

function Edit() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <path d="m64.91,41.31l-36.19,36.18c-.51.49-.79,1.17-.78,1.87v12.84c-.02,1.42,1.13,2.59,2.55,2.6h12.89c.69,0,1.35-.27,1.82-.78l36.19-36.18-16.48-16.53Zm22.04,11.02l3.69-3.69c3.04-3.01,3.07-7.92.06-10.96-.02-.02-.04-.04-.06-.06l-5.51-5.51c-3.01-3.04-7.92-3.07-10.96-.06-.02.02-.04.04-.06.06l-3.69,3.69,16.53,16.54Z"/>
        </svg>
    );
}

export default Edit;
