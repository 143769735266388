import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Headline from '../global/Headline';
import Header from '../global/Header';
import UserForm from './UserForm';
import { replaceText } from '../../helper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * Wrapper component for the user form when editing a user
 *
 * @component Header - application header
 * @component Headline - simple headline
 * @component UserForm - user form
 */
const EditUser = ({ texts, match, users }) => {
    const [currentUser, setCurrentUser] = useState('');
    useEffect(() => {
        const id = parseInt(match.params.id);

        let displayedUser = users.filter(user => {
            return user.id === id;
        });

        if (typeof displayedUser[0] !== 'undefined') {
            setCurrentUser(displayedUser[0].email);
        }
    }, [match.params.id, users]);

    return (
        <div>
            <Header showLogout={true} />
            <div className="container">
                <Headline
                    headline={
                        replaceText(texts, 'user.edit') + ' - ' + currentUser
                    }
                />
                <UserForm />
            </div>
        </div>
    );
};

EditUser.propTypes = {
    texts: PropTypes.object,
    match: PropTypes.any,
    users: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        users: state.users,
    };
};

// Connect Props to Component
export default withRouter(connect(mapStateToProps)(EditUser));
