import { SET_TEXTS, SET_LANGUAGE } from '../actionTypes';
import { DEFAULT_LANGUAGE } from '../../config';

export const initialState = {
    language: DEFAULT_LANGUAGE,
    texts: {},
};

const textsReducer = (state = initialState, action) => {
    if (action.type === SET_TEXTS) {
        return {
            ...state,
            texts: action.texts,
        };
    }

    if (action.type === SET_LANGUAGE) {
        return {
            ...state,
            language: action.language,
        };
    }

    return state;
};

export default textsReducer;
