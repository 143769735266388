import { oauth } from '../OAuth';
import { getImageUrlToFetch, getImageUrlToUpload } from '../helper';
import { restCall } from './api';

export const uploadFile = async ({ file, centerId = null, type }) => {
    const formData = new FormData();
    let url = null;

    formData.append('file', file);

    if (centerId) {
        formData.append('dispatchCenterId', `${centerId}`);
    }

    const mergedParams = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    url = getImageUrlToUpload(type);
    return restCall(url, mergedParams, false);
};

export const getImage = async ({ id, type }) => {
    let url = null;
    const mergedParams = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

     url = getImageUrlToFetch(type) + id;
    return restCall(url, mergedParams, false);
};

export const deleteImage = async ({
    id,
    type
}) => {
    let url = null;
    const mergedParams = {
        method: 'DELETE',
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    url = getImageUrlToFetch(type) + id;
    return restCall(url, mergedParams, false);
};
