import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import clsx from 'clsx';

import { centerProptype } from '../../redux/reducers/dispatchCenters';
import {
    deleteCenterChatTextDispatch,
    addCenterChatTextDispatch,
    changeCenterChatTextsDispatch,
} from '../../redux/actions/dispatchCenters';
import CenterChatBlock from './CenterChatBlock';

import './CenterChatBlocks.scss';
import { replaceText } from '../../helper';

/**
 * Center chat blocks with the possibility to drag and drop
 */

const CenterChatBlocks = ({ centers, texts, centerId }) => {
    const [isOpen, setIsOpen] = useState(true);
    const inputRef = useRef(null);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const deleteText = text => {
        deleteCenterChatTextDispatch(text, centerId);
    };

    const addText = e => {
        e.preventDefault();
        const text = inputRef.current.value;
        inputRef.current.value = '';
        addCenterChatTextDispatch(text, centerId);
    };

    /* dnd */
    const moveCard = (dragIndex, hoverIndex) => {
        const currentCenter = centers.filter(
            center => center.id === centerId
        )[0];
        const centerChatBlocks = currentCenter.chatBlocks;

        const arraymove = (arr, fromIndex, toIndex) => {
            let element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        };

        arraymove(centerChatBlocks, dragIndex, hoverIndex);

        changeCenterChatTextsDispatch(centerChatBlocks, centerId);
    };

    /* dnd end */

    const currentCenter = centers.filter(center => center.id === centerId)[0];
    const centerChatBlocks = currentCenter.chatBlocks;
    const classes = clsx('centerChatBlocks', {
        'centerChatBlocks--open': isOpen,
    });

    return (
        <div className={classes}>
            <h3 onClick={toggle}>
                {replaceText(texts, 'blocks')}
                <span className="centerChatBlocks__toggle"></span>
            </h3>
            {isOpen && (
                <div>
                    <p>{replaceText(texts, 'blocks.info.1')}</p>
                    <p>{replaceText(texts, 'blocks.info.2')}</p>
                    <div>
                        {centerChatBlocks.length < 20 ? (
                            <React.Fragment>
                                <input
                                    placeholder="Textblock eingeben. Max. 80 Zeichen"
                                    maxLength={80}
                                    ref={inputRef}
                                    type="text"></input>
                                <button
                                    className="btn btn--primary"
                                    onClick={addText}>
                                    {replaceText(texts, 'action.add')}
                                </button>
                            </React.Fragment>
                        ) : (
                            <span className="alert alert--error">
                                {replaceText(texts, 'blocks.max')}
                            </span>
                        )}
                    </div>
                    <div className="centerChatBlocks__list">
                        {centerChatBlocks.map((chatBlock, i) => (
                            <CenterChatBlock
                                key={chatBlock}
                                index={i}
                                id={i}
                                text={chatBlock}
                                moveCard={moveCard}
                                deleteText={deleteText}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

// PropTypes for this Component
CenterChatBlocks.propTypes = {
    centerId: PropTypes.number,
    centers: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        centers: state.dispatchCenters.centers,
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(CenterChatBlocks);
