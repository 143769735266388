/**
 * check if a given email is unique across all users
 * @param {object} data
 * @param {string} data.email
 * @param {array} data.users
 * @param {string} data.id
 * @returns {boolean} is unique
 */
export const isUniqueMail = ({ email, users, id }) => {
    let unique = true;

    users.forEach(user => {
        if (user.id === id) return;
        if (user.email.toLowerCase() === email.toLowerCase()) unique = false;
    });

    return unique;
};

/**
 * check if a given name is unique across all centers
 * @param {object} data
 * @param {string} data.name
 * @param {array} data.centers
 * @param {string} data.id
 * @returns {boolean} is unique
 */
export const isUniqueName = ({ name, centers, id }) => {
    let unique = true;

    centers.forEach(center => {
        if (center.id === id) return;
        if (center.name.toLowerCase() === name.toLowerCase()) unique = false;
    });

    return unique;
};
