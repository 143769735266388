import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Caret from '../icons/Caret';
import clsx from 'clsx';
import { CenterPhonebookEntry } from './CenterPhonebookEntry';
import { replaceText } from '../../helper';

export const CenterPhonebookEntriesComponent = ({
    data,
    deleteEntry,
    saveEditedEntry,
    texts,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [sortDirection, setSortDirection] = useState('ASC');

    const updateSearchTerm = e => {
        setSearchTerm(e.target.value);
    };

    const updateSorting = sortingKey => {
        if (sortingKey === sortBy) {
            const newSortingDirection =
                sortDirection === 'ASC' ? 'DESC' : 'ASC';
            setSortDirection(newSortingDirection);
        } else {
            setSortBy(sortingKey);
            setSortDirection('ASC');
        }
    };

    const sortEntries = (a, b) => {
        return `${a[sortBy]}`.localeCompare(b[sortBy]);
    };

    /**
     * this filters the entries
     * @param {object} entry
     * @returns {boolean}
     */
    const filterEntries = entry => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const lowerCaseName = entry.name.toLowerCase();
        const lowerCaseRole = entry.role.toLowerCase();
        const lowerCasePhone = entry.phone.toLowerCase();

        return (
            lowerCaseName.indexOf(lowerCaseSearchTerm) !== -1 ||
            lowerCaseRole.indexOf(lowerCaseSearchTerm) !== -1 ||
            lowerCasePhone.indexOf(lowerCaseSearchTerm) !== -1
        );
    };

    let sortedAndFilteredData = data.filter(filterEntries).sort(sortEntries);
    if (sortDirection === 'DESC')
        sortedAndFilteredData = sortedAndFilteredData.reverse();

    const nameClasses = clsx('phonebook__entry-value', {
        'is-active': sortBy === 'name',
        'sort-desc': sortBy === 'name' && sortDirection === 'DESC',
    });

    const phoneClasses = clsx('phonebook__entry-value', {
        'is-active': sortBy === 'phone',
        'sort-desc': sortBy === 'phone' && sortDirection === 'DESC',
    });

    const roleClasses = clsx('phonebook__entry-value', {
        'is-active': sortBy === 'role',
        'sort-desc': sortBy === 'role' && sortDirection === 'DESC',
    });

    return (
        <>
            <h2>{replaceText(texts, 'phonebook.entries')}</h2>
            <div className="phonebook__search">
                <h3>{replaceText(texts, 'phonebook.search')}</h3>
                <input
                    id="searchTerm"
                    type="text"
                    placeholder={replaceText(texts, 'phonebook.searchTerm')}
                    value={searchTerm}
                    onChange={updateSearchTerm}
                />
            </div>
            <div className="phonebook__entries">
                <div className="phonebook__entry phonebook__entry--header">
                    <span
                        className={nameClasses}
                        onClick={() => updateSorting('name')}>
                        {replaceText(texts, 'name')} <Caret />
                    </span>
                    <span
                        className={roleClasses}
                        onClick={() => updateSorting('role')}>
                        {replaceText(texts, 'position')} <Caret />
                    </span>
                    <span
                        className={phoneClasses}
                        onClick={() => updateSorting('phone')}>
                        {replaceText(texts, 'phone')} <Caret />
                    </span>
                    <span></span>
                </div>
                {sortedAndFilteredData.map(entry => (
                    <CenterPhonebookEntry
                        entry={entry}
                        deleteEntry={deleteEntry}
                        saveEditedEntry={saveEditedEntry}
                        key={entry.contactId}
                    />
                ))}
            </div>
        </>
    );
};

// PropTypes for this Component
CenterPhonebookEntriesComponent.propTypes = {
    data: PropTypes.array,
    deleteEntry: PropTypes.func,
    saveEditedEntry: PropTypes.func,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const CenterPhonebookEntries = connect(mapStateToProps)(
    CenterPhonebookEntriesComponent
);
