import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Headline from '../global/Headline';
import Header from '../global/Header';

import UserForm from './UserForm';
import { replaceText } from '../../helper';

/**
 * Wrapper component for the user form when creating a new user
 *
 * @component Header - application header
 * @component Headline - simple headline
 * @component UserForm - user form
 */

const NewUser = ({ texts }) => {
    return (
        <div>
            <Header showLogout={true} />
            <div className="container">
                <Headline headline={replaceText(texts, 'action.newuser')} />
                <UserForm />
            </div>
        </div>
    );
};

NewUser.propTypes = {
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(NewUser);
