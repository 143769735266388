import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../icons/LoadingSpinner';

import './Loading.scss';

/**
 * Loading
 * shows the loading spinner with a given text
 */

function Loading(props) {
    return (
        <div className="loading">
            <LoadingSpinner />
            <span className="loading__text">{props.text}</span>
        </div>
    );
}

Loading.propTypes = {
    text: PropTypes.string,
};

export default Loading;
