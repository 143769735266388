import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
    updateUserFilterDispatchThen,
    setFilteredUsersDispatch,
    setUserCurrentPageDispatch,
    toggleUserFilterVisibilityDispatch,
    setUsersFilteredDispatch,
    resetUserFiltersDispatch,
} from '../../redux/actions/users';

import { userProptypes } from '../../redux/reducers/users';

import '../global/Filter.scss';
import { filterItemsByProperty } from '../../helper/filterHelper';
import { replaceText } from '../../helper';

/**
 * Filter for the user list.
 */

class UserFilter extends PureComponent {
    constructor(props) {
        super(props);

        this.dispatchCenterRef = React.createRef();
        this.nameRef = React.createRef();
        this.emailRef = React.createRef();
        this.role0Ref = React.createRef();
        this.role1Ref = React.createRef();
        this.role2Ref = React.createRef();
        this.level1Ref = React.createRef();
        this.level2Ref = React.createRef();
        this.level3Ref = React.createRef();
    }

    updateInput = (ref, property) => {
        let value = ref.current.value;

        if (property === 'role') {
            value = {
                [this.role0Ref.current.value]: this.role0Ref.current.checked,
                [this.role1Ref.current.value]: this.role1Ref.current.checked,
                [this.role2Ref.current.value]: this.role2Ref.current.checked,
            };
        }

        updateUserFilterDispatchThen({ property, value }).then(() => {
            setUserCurrentPageDispatch(1);
        });
    };

    filterUsers = () => {
        const filteredUsersByDispatchCenter = this.filterUsersByProperty(
            this.props.sortedUsers,
            'dispatchCenter'
        );
        const filteredUsersByName = this.filterUsersByProperty(
            filteredUsersByDispatchCenter,
            'name'
        );
        const filteredUsersByEmail = this.filterUsersByProperty(
            filteredUsersByName,
            'email'
        );
        const filteredUsersByLevel1 = this.filterUsersByProperty(
            filteredUsersByEmail,
            'level1'
        );
        const filteredUsersByLevel2 = this.filterUsersByProperty(
            filteredUsersByLevel1,
            'level2'
        );
        const filteredUsersByLevel3 = this.filterUsersByProperty(
            filteredUsersByLevel2,
            'level3'
        );
        const filteredUsersByRole = this.filterUsersByProperty(
            filteredUsersByLevel3,
            'role'
        );

        setFilteredUsersDispatch(filteredUsersByRole);
        setUsersFilteredDispatch(
            this.props.sortedUsers.length !== filteredUsersByRole.length
        );
    };

    filterUsersByProperty = (users, property) => {
        return filterItemsByProperty({
            items: users,
            property,
            filterProperties: this.props.filterProperties,
        });
    };

    clearFilter = () => {
        resetUserFiltersDispatch();

        this.dispatchCenterRef.current.value = '';
        this.nameRef.current.value = '';
        this.emailRef.current.value = '';
        this.level1Ref.current.checked = false;
        this.level2Ref.current.checked = false;
        this.level3Ref.current.checked = false;
        this.role0Ref.current.checked = false;
        this.role1Ref.current.checked = false;
        this.role2Ref.current.checked = false;
    };

    componentDidMount() {
        this.filterUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filterProperties !== this.props.filterProperties || prevProps.sortedUsers !== this.props.sortedUsers) {
            this.filterUsers();
        }
    }

    render() {
        const classes = clsx('userFilter', {
            'userFilter--open': this.props.filterOpen,
        });
        return (
            <div className={classes}>
                <div
                    className="userFilter__toggle"
                    onClick={toggleUserFilterVisibilityDispatch}>
                    {this.props.filterOpen ? (
                        <span>
                            {replaceText(this.props.texts, 'filter.close')}
                        </span>
                    ) : (
                        <span>
                            {replaceText(this.props.texts, 'filter.open')}
                        </span>
                    )}
                </div>

                <div className="userFilter__filters">
                    <div className="">
                        <label htmlFor="name">
                            <h3>{replaceText(this.props.texts, 'name')}</h3>
                        </label>
                        <input
                            id="name"
                            type="text"
                            ref={this.nameRef}
                            onChange={() => {
                                this.updateInput(this.nameRef, 'name');
                            }}
                        />
                    </div>

                    <div className="">
                        <label htmlFor="email">
                            <h3>{replaceText(this.props.texts, 'email')}</h3>
                        </label>
                        <input
                            id="email"
                            type="text"
                            ref={this.emailRef}
                            onChange={() => {
                                this.updateInput(this.emailRef, 'email');
                            }}
                        />
                    </div>

                    <div>
                        <label htmlFor="dispatchCenter">
                            <h3>{replaceText(this.props.texts, 'center')}</h3>
                        </label>
                        <input
                            id="dispatchCenter"
                            type="text"
                            ref={this.dispatchCenterRef}
                            onChange={() => {
                                this.updateInput(
                                    this.dispatchCenterRef,
                                    'dispatchCenter'
                                );
                            }}
                        />

                        <label htmlFor="level1">
                            <h3>Level-1</h3>
                        </label>
                        <input
                            id="level1"
                            type="text"
                            ref={this.level1Ref}
                            onChange={() => {
                                this.updateInput(
                                    this.level1Ref,
                                    'level1'
                                );
                            }}
                        />
                        <label htmlFor="level2">
                            <h3>Level-2</h3>
                        </label>
                        <input
                            id="level2"
                            type="text"
                            ref={this.level2Ref}
                            onChange={() => {
                                this.updateInput(
                                    this.level2Ref,
                                    'level2'
                                );
                            }}
                        />
                        <label htmlFor="level3">
                            <h3>Level-3</h3>
                        </label>
                        <input
                            id="level3"
                            type="text"
                            ref={this.level3Ref}
                            onChange={() => {
                                this.updateInput(
                                    this.level3Ref,
                                    'level3'
                                );
                            }}
                        />
                    </div>

                    <div>
                        <h3>{replaceText(this.props.texts, 'role')}</h3>
                        <p
                            style={{
                                display:
                                    this.props.currentUserrole === 'superadmin'
                                        ? 'block'
                                        : 'none',
                            }}>
                            <input
                                id="superadmin"
                                type="checkbox"
                                ref={this.role0Ref}
                                name="role"
                                value="superadmin"
                                onChange={() => {
                                    this.updateInput(this.role0Ref, 'role');
                                }}
                            />
                            <label htmlFor="superadmin">
                                {replaceText(
                                    this.props.texts,
                                    'role.superadmin'
                                )}
                            </label>
                        </p>

                        <p>
                            <input
                                id="admin"
                                type="checkbox"
                                ref={this.role1Ref}
                                name="role"
                                value="admin"
                                onChange={() => {
                                    this.updateInput(this.role1Ref, 'role');
                                }}
                            />
                            <label htmlFor="admin">
                                {replaceText(this.props.texts, 'role.admin')}
                            </label>
                        </p>
                        <p>
                            <input
                                id="user"
                                type="checkbox"
                                ref={this.role2Ref}
                                name="role"
                                value="user"
                                onChange={() => {
                                    this.updateInput(this.role2Ref, 'role');
                                }}
                            />
                            <label htmlFor="user">
                                {replaceText(this.props.texts, 'role.user')}
                            </label>
                        </p>
                    </div>
                </div>
                {this.props.isFiltered ? (
                    <div
                        className="userFilter__clear"
                        onClick={this.clearFilter}>
                        {replaceText(this.props.texts, 'filter.clear')}
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

// PropTypes for this Component
UserFilter.propTypes = {
    filterProperties: PropTypes.shape({
        username: PropTypes.string,
        dispatchCenter: PropTypes.string,
        role: PropTypes.object,
    }),
    currentUserrole: PropTypes.string,
    sortedUsers: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
    filterOpen: PropTypes.bool,
    isFiltered: PropTypes.bool,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        filterProperties: state.userFilters.filterProperties,
        filterOpen: state.userFilters.open,
        isFiltered: state.userFilters.isFiltered,
        sortedUsers: state.userSorting.sortedUsers,
        currentUserrole: state.currentUser.role,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(UserFilter);
