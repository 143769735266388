import { roles, defaultScopes, DEBUG } from '../config';
import store from '../redux/store';

const clientIdAuth = process.env.REACT_APP_AUTH_CLIENT_ID;
const clientIdApp = process.env.REACT_APP_APP_CLIENT_ID;

/** USERS
 *
 * USERS a combination of the backend entities USER and SCOPE
 * SCOPES are used to determine the role of a USER (superadmin, admin or user)
 */

/**
 * maps given scopes to a certain user role
 * @param {array} scopeArray
 * @returns {string} role
 */
export const scopeToRole = scopeArray => {
    let role = 'none';

    scopeArray.forEach(scope => {
        if (role === 'none') {
            if (scope.name.indexOf(roles['user'].scope) > -1) {
                role = 'user';
            }
        }
        if (role === 'user') {
            if (scope.name.indexOf(roles['admin'].scope) > -1) {
                role = 'admin';
            }
        }
        if (role === 'admin' || role === 'user') {
            if (scope.name.indexOf(roles['api'].scope) > -1) {
                role = 'api';
            }
        }
        if (role === 'admin' || role === 'api' || role === 'user') {
            if (scope.name.indexOf(roles['superadmin'].scope) > -1) {
                role = 'superadmin';
            }
        }
    });

    return role;
};

/**
 * map multiple users to data structure needed
 * @param {array} users
 * @returns {array} mappedUsers
 */
export const mapUsers = users => {
    const mappedUsers = users
        .filter(user => {
            if (user.scopeList[0]) {
                return user.scopeList[0].name !== 'emeyex.call';
            } else return true;
        })
        .map(mapUser);
    return mappedUsers;
};

/**
 * map a single user to data structure needed
 * @param {object} user
 * @returns {object} mappedUser
 */
export const mapUser = user => ({
    ...user,
    role: scopeToRole(user.scopeList),
    _links: undefined,
});

/**
 * map the current user to the data structure needed
 * @param {object} currentUser
 * @returns {object} mappedCurrentUser
 */
export const mapCurrentUser = currentUser => ({
    ...currentUser,
    role: currentUser.role.toLowerCase(),
    _links: undefined,
});

/**
 * remaps a mapped user to the data structure needed for the backend api
 * @param {object} mappedUser
 * @returns {object} cleanedUser
 */
export const remapUser = mappedUser => {
    const { role, organization, scopeList, ...cleanedUser } = mappedUser;
    return cleanedUser;
};

/**
 * map scopes to the data structure needed
 * @param {array} scopes
 * @returns {array} mappesScopes
 */
export const mapScopes = scopes => {
    const mappedScopes = scopes.map(mapScope);
    return mappedScopes;
};

/**
 * map a single scope to the data structure needed
 * @param {object} scope
 * @returns {object} mappedScope
 */
export const mapScope = scope => ({
    id: scope.id,
    name: scope.name,
    ref: scope._links.self.href,
});

/**
 * gets the scopes for a certain role as text
 * @param {string} role
 * @returns {string} scopes
 */
export const getScopes = role => {
    let roleScope = role;
    Object.keys(roles).forEach(key => {
        if (key === role) roleScope = roles[role].scope;
    });

    const scopeRefs = store
        .getState()
        .scopes.filter(scope => {
            let useRef = false;

            // role scope
            if (roleScope === scope.name) useRef = true;

            // default scopes
            defaultScopes.forEach(dScope => {
                if (dScope.name === scope.name) useRef = true;
            });

            return useRef;
        })
        .map(scope => scope.ref);

    return scopeRefs.join('\r\n');
};

/**
 * filter users belonging to a certain organisation
 * @param {string} orgId - id of the organisation
 * @returns {array} filtered users
 */
export const usersWithOrganizationId = orgId => {
    return store
        .getState()
        .users.filter(user => user.organization.id === orgId);
};

/** DISPATCH CENTERS
 *
 * dispatchCenters are a combination of the backend entities ORGANIZATION and DISPATCH_CENTER
 * All required properties get mapped via helper functions
 */

/**
 * map dispatch centers and organizations to the data structure needed
 * @param {array} organizations
 * @param {array} dispatchCenters
 * @returns {array} mapped centers
 */
export const mapDispatchCenters = (organizations, dispatchCenters) => {
    const centers = mergeCenters(organizations, dispatchCenters);
    if (DEBUG) console.log('mergedCenters', centers);
    return centers;
};

/**
 * merge dispatch centers and organizations into one entity
 * @param {array} organizations
 * @param {array} dispatchCenters
 * @returns {array} merged centers
 */
export const mergeCenters = (organizations, dispatchCenters) => {
    return organizations.map(org => {
        let merged = { ...mapOrganization(org) };
        dispatchCenters.forEach(dc => {
            if (org.id === dc.organizationId) {
                merged = { ...mergeCenter(org, dc) };
            }
        });

        return merged;
    });
};

/**
 * merge one organization with corresponding dispatch center
 * @param {object} org organization
 * @param {object} addOn dispatch center add on
 * @returns {object} merged center
 */
export const mergeCenter = (org, addOn) => {
    const mappedOrganization = mapOrganization(org);
    const mappedAddOn = mapAddOn(addOn);
    const mergedCenter = { ...mappedAddOn, ...mappedOrganization };

    if (DEBUG) console.log('mergedCenter', mergedCenter);
    return mergedCenter;
};

/**
 * map the add on to the data structure needed
 * @param {object} addOn dispatch center add on
 * @returns {object} mapped add on
 */
export const mapAddOn = addOn => {
    if (DEBUG) console.log('mapAddOn', addOn);
    let end = '';
    let centerRef = '';
    if (addOn.end) end = addOn.end;
    if (addOn.goodbyeMessages) end = addOn.goodbyeMessages[0];
    if (addOn._links && addOn._links.self) centerRef = addOn._links.self.href;
    return {
        end,
        smsText: addOn.smsText,
        addOnId: addOn.id,
        chatBlocks: addOn.chatBlocks ? addOn.chatBlocks : [],
        centerRef,
        language: addOn.language,
    };
};

/**
 * map the organization into the data structure needed
 * @param {object} center
 * @returns {object} mapped organization
 */
export const mapOrganization = center => ({
    id: center.id,
    name: center.name,
    ref: center._links.self.href,
    organizationId: center.id,
    ipRestriction: center.ipRestriction,
});

/**
 * remaps a mapped center to the data structure needed for the backend api
 * @param {object} mappedCenter
 * @returns {object} cleanedCenter
 */
export const remapDispatchCenterToOrganization = mappedCenter => {
    const { ref, end, smsText, chatBlocks, ...cleanedCenter } = mappedCenter;
    if (DEBUG) console.log('remapDispatchCenterToOrganization', cleanedCenter);
    return cleanedCenter;
};

/**
 * remaps a mapped center for configuring conferencing to the necessary backend data structure
 * @param {object} mappedCenter
 * @returns {object} cleanedCenter
 */
export const remapConferenceConfigToOrganization = mappedCenter => {
    const {
        ref,
        conferenceEnd,
        emailText,
        senderName,
        subject,
        ...cleanedConfig
    } = mappedCenter;
    if (DEBUG)
        console.log('remapConferenceConfigToOrganization', cleanedConfig);
    return cleanedConfig;
};

/**
 * remaps a mapped dispatch center to the data structure needed for the backend api
 * @param {object} mappedCenter
 * @returns {object} cleanedAddOn
 */
export const remapDispatchCenterToAddOn = mappedCenter => {
    const { ref, ...cleanedAddOn } = mappedCenter;
    cleanedAddOn.organization_id = cleanedAddOn.id;
    cleanedAddOn.goodbyeMessages = [cleanedAddOn.end || ''];
    cleanedAddOn.smsText = cleanedAddOn.smsText || '';
    cleanedAddOn.chatBlocks = cleanedAddOn.chatBlocks || [];
    if (DEBUG) console.log('remapDispatchCenterToAddon', cleanedAddOn);
    return cleanedAddOn;
};

/**
 * remaps a mapped dispatch center to the data structure needed for the backend api
 * @param {object} mappedCenter
 * @returns {object} cleanedAddOn
 */
export const remapDispatchCenterConferenceConfigToAddOn = mappedCenter => {
    const { ref, ...cleanedAddOn } = mappedCenter;
    cleanedAddOn.organization_id = cleanedAddOn.id;
    cleanedAddOn.senderName = cleanedAddOn.senderName || '';
    cleanedAddOn.subject = cleanedAddOn.subject || '';
    cleanedAddOn.emailText = cleanedAddOn.emailText || '';
    cleanedAddOn.conferenceEnd = [cleanedAddOn.conferenceEnd || ''];
    if (DEBUG)
        console.log('remapDispatchCenterConferenceConfigToAddon', cleanedAddOn);
    return cleanedAddOn;
};

/**
 * get the corresponding organization reference to a dispatch center
 * @param {object} center
 * @returns {string} reference
 */
export const getOrganization = center => {
    const reference = store
        .getState()
        .dispatchCenters.centers.filter(
            c => center.toLowerCase() === c.name.toLowerCase()
        )
        .map(c => c.ref);

    return reference[0];
};

/**
 * gets the addOn id for the given organization id
 * @param {array} centers
 * @param {string} orgId
 * @returns {string} addOn id
 */
export const getAddOnId = (centers, organizationId) => {
    return centers.filter(center => center.id === organizationId)[0].addOnId;
};

/** CONFERENCE CONFIG HELPERS */

/**
 * merge one organization with corresponding dispatch center
 * @param {object} org organization
 * @param {object} addOn dispatch center add on
 * @returns {object} merged center
 */
export const mergeCenterForConferenceConfig = (org, addOn) => {
    const mappedOrganization = mapOrganizationForConferenceConfig(org);
    const mappedAddOn = mapAddOnForConferenceConfig(addOn);
    const mergedCenter = { ...mappedAddOn, ...mappedOrganization };

    if (DEBUG) console.log('mergeCenterForConferenceConfig', mergedCenter);
    return mergedCenter;
};

/**
 * map the add on to the data structure needed
 * @param {object} addOn dispatch center add on
 * @returns {object} mapped add on
 */
export const mapAddOnForConferenceConfig = addOn => {
    if (DEBUG) console.log('mapAddOnForConferenceConfig', addOn);
    let conferenceEnd = '';
    let centerRef = '';
    console.log(conferenceEnd);
    if (addOn.conferenceEnd) conferenceEnd = addOn.conferenceEnd;
    if (addOn.goodbyeMessages) conferenceEnd = addOn.goodbyeMessages[0];
    if (addOn._links && addOn._links.self) centerRef = addOn._links.self.href;
    return {
        senderName: addOn.senderName,
        subject: addOn.subject,
        emailText: addOn.emailText,
        conferenceEnd: addOn.conferenceEnd,
        addOnId: addOn.id,
        centerRef,
    };
};

/**
 * map the organization into the data structure needed
 * @param {object} center
 * @returns {object} mapped organization
 */
export const mapOrganizationForConferenceConfig = center => ({
    id: center.id,
    senderName: center.senderName,
    subject: center.subject,
    emailText: center.emailText,
    conferenceEnd: center.conferenceEnd,
    ref: center._links.self.href,
    organizationId: center.id,
});

/** OTHER HELPERS */

/**
 * creates a randomized secret
 * @returns {string} secret
 */
export const createSecret = () => {
    return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    );
};

/**
 * get the secrets of the dispatch centers
 * @param {array} centers
 * @returns {array} secrets
 */
export const getDispatchCenterSecrets = centers => {
    return centers.map(center => ({
        secret: center.secret,
        id: center.id,
    }));
};

/**
 * get a specific secret for a given id
 * @param {string} id
 * @returns {string} secret
 */
export const getSecretForId = id => {
    const secretObj = store
        .getState()
        .dispatchCenters.secrets.filter(secret => secret.id === id);
    return secretObj[0].secret;
};

/**
 * gets the welcome messages at given order number
 * @param {array} results
 * @param {string} order
 * @returns {object} welcome message
 */
export const getWelcomeMessageAt = (results, order) => {
    const welcomeMessage = results.filter(result => result.order === order)[0];
    return welcomeMessage;
};

/**
 * get relevant applications for the client id
 * @param {array} applications
 * @returns {array} applications
 */
export const getRelevantApplications = applications => {
    return applications.filter(
        app => app.clientId === clientIdApp || app.clientId === clientIdAuth
    );
};

/**
 * get the relevant application refs
 * @returns {string} refs
 */
export const getRelevantApplicationsRefs = () => {
    const refs = store.getState().applications.map(app => app._links.self.href);
    return refs.join('\r\n');
};

/**
 * map the configs to the data structure needed
 * @param {array} configs
 * @returns {array} mapped configs
 */
export const mapApplicationConfigs = configs => {
    const mappedConfigs = configs.map(mapApplicationConfig);
    return mappedConfigs;
};

/**
 * map a single config
 * @param {object} config
 * @returns {object} mapped config
 */
export const mapApplicationConfig = config => {
    return {
        id: config.id,
        enabledFeatures: config.enabledFeatures,
        dispatchCenterId: config.dispatchCenter.id,
        ref: config._links.self.href,
    };
};

/**
 * maps a given contact
 * @param {object} contact
 * @returns {object}
 */
export const mapContact = contact => {
    const splitRef = contact._links.contact.href.split('/');
    const contactId = splitRef[splitRef.length - 1];

    return {
        ...contact,
        contactRef: contact._links.contact.href,
        contactId,
    };
};

/**
 * map the invite configs to the data structure needed
 * @param {array} configs
 * @returns {array} mapped configs
 */
export const mapInviteConfigs = configs => {
    const mappedConfigs = configs.map(mapInviteConfig);
    return mappedConfigs;
};

/**
 * map a single config
 * @param {object} config
 * @returns {object} mapped config
 */
export const mapInviteConfig = config => {
    return {
        id: config.id,
        goodbyeText: config.goodbyeText,
        logo: config.logo,
        messageSender: config.messageSender,
        messageSubject: config.messageSubject,
        messageText: config.messageText,
        ref: config._links.self.href,
    };
};

/**
 * map the session report configs to the data structure needed
 * @param {array} configs
 * @returns {array} mapped configs
 */
export const mapSessionReportConfigs = configs => {
    const mappedConfigs = configs.map(mapSessionReportConfig);
    return mappedConfigs;
};

/**
 * map a single config
 * @param {object} config
 * @returns {object} mapped config
 */
export const mapSessionReportConfig = config => {
    return {
        id: config.id,
        hideUserName: config.hideUserName,
        hideTargetNumber: config.hideTargetNumber,
        hideLocation: config.hideLocation,
        hidePhotos: config.hidePhotos,
        hideChat: config.hideChat,
        hideLog: config.hideLog,
        hideNotes: config.hideNotes,
        hideRecordings: config.hideRecordings,
        hideFileUploads: config.hideFileUploads,
    };
};
