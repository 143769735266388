import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './CenterPhonebook.scss';
import { CenterPhonebookEntries } from './CenterPhonebookEntries';
import { CenterPhonebookForm } from './CenterPhonebookForm';
import {
    addContactAPI,
    deleteContactAPI,
    editContactAPI,
    getDispatchCenterContactsAPI,
} from '../../api/contactsAPI';
import { isValidPhone, isValidText, replaceText } from '../../helper';
import { DEFAULT_TIMEOUT } from '../../config';

export const CenterPhonebookComponent = ({ id, centerRef, texts }) => {
    useEffect(() => {
        async function getData() {
            const data = await getDispatchCenterContactsAPI(id);

            setData(data);
        }
        getData();
    }, [id]);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameValidationError, setNameValidationError] = useState(false);
    const [role, setRole] = useState('');
    const [roleValidationError, setRoleValidationError] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [data, setData] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const showFormSubmitText = () => {
        setIsFormSubmitted(true);
        setTimeout(() => {
            setIsFormSubmitted(false);
        }, DEFAULT_TIMEOUT);
    };

    const validate = () => {
        setPhoneError(false);
        setNameError(false);
        setRoleValidationError(false);
        setNameValidationError(false);

        if (phone === '') setPhoneError(true);
        if (!isValidPhone(phone)) setPhoneError(true);
        if (name === '') setNameError(true);
        if (!isValidText(name)) setNameValidationError(true);
        if (name === '') setNameError(true);
        if (!isValidText(role)) setRoleValidationError(true);

        if (
            phone === '' ||
            name === '' ||
            !isValidText(role) ||
            !isValidText(name) ||
            !isValidPhone(phone)
        )
            return false;
        return true;
    };

    const saveEntry = async e => {
        e.preventDefault();

        if (validate()) {
            const apiData = {
                phone,
                name,
                role,
                dispatchCenter: centerRef,
            };
            const contact = await addContactAPI({ data: apiData });
            const newData = [...data, contact];

            setData(newData);
            setName('');
            setRole('');
            setPhone('');
            showFormSubmitText();
        }
    };

    const saveEditedEntry = async ({ phone, name, role, contactId }) => {
        const apiData = {
            phone,
            name,
            role,
            dispatchCenter: centerRef,
        };
        const editedContact = await editContactAPI({
            data: apiData,
            contactId,
        });

        const newData = data.map(contact => {
            if (contact.contactId === contactId) {
                return editedContact;
            }
            return contact;
        });

        setData(newData);
    };

    const deleteEntry = async ({ contactId }) => {
        const newData = data.filter(entry => entry.contactId !== contactId);

        await deleteContactAPI({ contactId });

        setData(newData);
    };

    return (
        <div className="phonebook">
            <CenterPhonebookForm
                name={name}
                setName={setName}
                role={role}
                setRole={setRole}
                phone={phone}
                setPhone={setPhone}
                saveEntry={saveEntry}
                nameError={nameError}
                phoneError={phoneError}
                roleValidationError={roleValidationError}
                nameValidationError={nameValidationError}>
                <div
                    className={`phonebook__success-message ${
                        isFormSubmitted
                            ? ''
                            : 'phonebook__success-message--isHidden'
                    }`}>
                    <span>
                        {replaceText(texts, 'phonebook.contact.created')}
                    </span>
                </div>
            </CenterPhonebookForm>

            <CenterPhonebookEntries
                data={data}
                deleteEntry={deleteEntry}
                saveEditedEntry={saveEditedEntry}
            />
        </div>
    );
};

// PropTypes for this Component
CenterPhonebookComponent.propTypes = {
    id: PropTypes.number,
    centerRef: PropTypes.string,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const CenterPhonebook = connect(mapStateToProps)(
    CenterPhonebookComponent
);
