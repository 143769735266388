/**
 * All used action types for redux flow
 */

// USERS
export const ADD_USER = 'ADD_USER';
export const REPLACE_USERS = 'REPLACE_USERS';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FILTER = 'UPDATE_USER_FILTER';
export const SET_FILTERED_USERS = 'SET_FILTERED_USERS';
export const SET_USER_CURRENT_PAGE = 'SET_USER_CURRENT_PAGE';
export const SET_PAGINATED_USERS = 'SET_PAGINATED_USERS';
export const TOGGLE_USER_FILTER_VISIBILITY = 'TOGGLE_USER_FILTER_VISIBILITY';
export const CLOSE_USER_FILTER = 'CLOSE_USER_FILTER';
export const TOGGLE_USER_SORTING_ORDER = 'TOGGLE_SORTING_ORDER';
export const SET_USER_SORTING_PROPERTY = 'SET_USER_SORTING_PROPERTY';
export const SET_SORTED_USERS = 'SET_SORTED_USERS';
export const SET_USERS_FILTERED = 'SET_USER_FILTERED';
export const RESET_USER_FILTER = 'RESET_USER_FILTER';

// CURRENT_USER
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';

// DISPATCH CENTERS
export const ADD_DISPATCH_CENTER = 'ADD_DISPATCH_CENTER';
export const ADD_DISPATCH_CENTERS = 'ADD_DISPATCH_CENTERS';
export const REMOVE_CENTER = 'REMOVE_CENTER';
export const UPDATE_CENTER = 'UPDATE_CENTER';
export const UPDATE_CENTER_FILTER = 'UPDATE_CENTER_FILTER';
export const SET_FILTERED_CENTERS = 'SET_FILTERED_CENTERS';
export const TOGGLE_CENTERS_SORTING_ORDER = 'TOGGLE_CENTERS_SORTING_ORDER';
export const TOGGLE_CENTER_FILTER_VISIBILITY =
    'TOGGLE_CENTER_FILTER_VISIBILITY';
export const CLOSE_CENTER_FILTER = 'CLOSE_CENTER_FILTER';
export const SET_CENTERS_SORTING_PROPERTY = 'SET_CENTERS_SORTING_PROPERTY';
export const SET_SORTED_CENTERS = 'SET_SORTED_CENTERS';
export const SET_CENTER_CURRENT_PAGE = 'SET_CENTER_CURRENT_PAGE';
export const SET_PAGINATED_CENTERS = 'SET_PAGINATED_CENTERS';
export const SET_CENTERS_FILTERED = 'SET_CENTER_FILTERED';
export const RESET_CENTER_FILTER = 'RESET_CENTER_FILTER';
export const ADD_CENTER_SECRET = 'ADD_CENTER_SECRET';
export const REMOVE_CENTER_SECRET = 'REMOVE_CENTER_SECRET';
export const ADD_CENTER_CHAT_TEXT = 'ADD_CENTER_CHAT_TEXT';
export const DELETE_CENTER_CHAT_TEXT = 'DELETE_CENTER_CHAT_TEXT';
export const CHANGE_CENTER_CHAT_TEXTS = 'CHANGE_CENTER_CHAT_TEXTS';

// SYSTEM MESSAGES
export const SAVE_SYSTEM_MESSAGE = 'SAVE_SYSTEM_MESSAGE';
export const SAVE_SYSTEM_FOOTER = 'SAVE_SYSTEM_FOOTER';

// USER SCOPES
export const ADD_USER_SCOPES = 'ADD_USER_SCOPES';

// APPLICATIONS
export const ADD_APPLICATIONS = 'ADD_APPLICATIONS';

// API TOKEN
export const ADD_API_TOKEN = 'ADD_API_TOKEN';
export const REMOVE_API_TOKEN = 'REMOVE_API_TOKEN';

// APPLICATION CONFIGS
export const OVERWRITE_APPLICATION_CONFIGS = 'OVERWRITE_APPLICATION_CONFIGS';

// TEXTS
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_TEXTS = 'SET_TEXTS';

// INVITE CONFIGS
export const OVERWRITE_INVITE_CONFIGS = 'OVERWRITE_INVITE_CONFIGS';
export const SET_CURRENT_INVITE_CONFIG = 'SET_CURRENT_INVITE_CONFIG';
export const RESET_CURRENT_INVITE_CONFIG = 'RESET_CURRENT_INVITE_CONFIG';

// SESSION REPORT CONFIGS
export const OVERWRITE_SESSION_REPORT_CONFIGS =
    'OVERWRITE_SESSION_REPORT_CONFIGS';
export const SET_CURRENT_SESSION_REPORT_CONFIG =
    'SET_CURRENT_SESSION_REPORT_CONFIG';
export const RESET_CURRENT_SESSION_REPORT_CONFIG =
    'RESET_CURRENT_SESSION_REPORT_CONFIG';

// ORGANIGRAM CONFIG
export const OVERWRITE_ORGANIGRAM_CONFIGS = 'OVERWRITE_ORGANIGRAM_CONFIGS';
export const SET_CURRENT_ORGANIGRAM_CONFIG = 'SET_CURRENT_ORGANIGRAM_CONFIG';
export const RESET_CURRENT_ORGANIGRAM_CONFIG =
    'RESET_CURRENT_ORGANIGRAM_CONFIG';

// DISCLAIMER
export const SET_DISCLAIMER_DISPATCHER = 'SET_DISCLAIMER_DISPATCHER';
export const SET_DISCLAIMER_DISPATCHER_ENABLED =
    'SET_CHECKBOX_DISPATCHER_ENABLED';

export const SET_CONFIRMATION_TEXT = 'SET_CONFIRMATION_TEXT';
export const SET_CONFIRMATION_ENABLED_FOR_DISPATCHER =
    'SET_CONFIRMATION_ENABLED_FOR_DISPATCHER';

export const SET_DISCLAIMER_CALLER = 'SET_DISCLAIMER_CALLER';
export const SET_DISCLAIMER_CALLER_ENABLED = 'SET_DISCLAIMER_CALLER_ENABLED';

export const RESET_DISCLAIMER_SETTINGS = 'RESET_DISCLAIMER_SETTINGS';
