import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// import Caret from './icons/Caret';
import {
    setUserCurrentPageDispatch,
    setPaginatedUsersDispatch,
} from '../../redux/actions/users';

import { userProptypes } from '../../redux/reducers/users';

import '../global/Pagination.scss';

/**
 * Pagination navigation for the current user list.
 */
class UserPagination extends PureComponent {
    paginateUsers = () => {
        const paginatedUsers = this.props.filteredUsers.slice(
            (this.props.currentPage - 1) * this.props.itemsPerPage,
            this.props.currentPage * this.props.itemsPerPage
        );
        setPaginatedUsersDispatch(paginatedUsers);
    };

    componentDidMount() {
        this.paginateUsers();
    }

    componentDidUpdate() {
        this.paginateUsers();
    }

    render() {
        const { currentPage, itemsPerPage, filteredUsers } = this.props;
        const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            const classes = clsx('userPagination__page', {
                'userPagination__page--active': i === currentPage,
            });
            const page = (
                <button
                    className={classes}
                    key={i}
                    onClick={() => {
                        setUserCurrentPageDispatch(i);
                    }}>
                    {i}
                </button>
            );

            pages.push(page);
        }

        return <div className="userPagination">{pages}</div>;
    }
}

// PropTypes for this Component
UserPagination.propTypes = {
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    filteredUsers: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentPage: state.userPagination.currentPage,
        itemsPerPage: state.userPagination.itemsPerPage,
        filteredUsers: state.userFilters.filteredUsers,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(UserPagination);
