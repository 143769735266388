import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { userProptypes } from '../../redux/reducers/users';
import {
    getCurrentUserOrgStructure,
    getUserConfiguration,
} from '../../api/userConfigurationAPI';
import { replaceText } from '../../helper';
import { getOrganigramConfig } from '../../api/organigramAPI';
import { setCurrentOrganigramConfigDispatch } from '../../redux/actions';
import Dropdown from '../global/Dropdown';
import DropDownContent from '../global/DropDownContent';

const UserOrganigram = ({
    texts,
    users,
    match,
    dispatchCenters,
    currentOrganigramConfig,
    currentDispatchCenter,
    selectDispatchCenterExternally,
    setOrgLevelById,
}) => {
    const [dispatchCenter, setDispatchCenter] = useState(
        replaceText(texts, 'dropdown.default')
    );
    const [availableLevels, setAvailableLevels] = useState([[], [], []]);

    // dropdown values represent currently set or selected org structures.
    // dropdown array is zero-based. level start at 1.
    const [dropdowns, setDropdowns] = useState([
        replaceText(texts, 'dropdown.default'),
        replaceText(texts, 'dropdown.default'),
        replaceText(texts, 'dropdown.default'),
    ]);
    const [
        shouldResetToPreviousDispatchCenter,
        setShouldResetToPreviousDispatchCenter,
    ] = useState(false);
    const [isNewUserInitialConfig, setIsNewUserInitialConfig] = useState(false);

    const prevTexts = useRef();

    const setOrgLevel = ({ label, orgLevel }) => {
        const newLevelState = [...dropdowns];
        newLevelState[orgLevel - 1] = label;
        setDropdowns(newLevelState);
        // clear org level if default value is selected 
        if (label === replaceText(texts, 'dropdown.default')) {
            setOrgLevelById(orgLevel, '', '');
            return;
        }
        // set org level
        currentOrganigramConfig[orgLevel].map(item => {
            if (item.name === label) {
                setOrgLevelById(orgLevel, item.id, item.name);
            }
            return null;
        });
    };

    const getSelectedDispatcherId = label => {
        let currentCenter = dispatchCenters.filter(center => {
            return label === center.name;
        });
        return currentCenter[0].addOnId;
    };

    // Fetch organigram config if one exists and populate corresponding dropdown boxes
    const fetchAndSetOrganigramConfig = async addOnId => {
        const organigramConfig = await getOrganigramConfig(addOnId);

        const newLevels = {
            1: [],
            2: [],
            3: [],
        };

        for (var item in organigramConfig) {
            /*
             * Name and the id of organigram item added
             * Levels begin at 1
             */

            newLevels[organigramConfig[item].level] = [
                ...newLevels[organigramConfig[item].level],
                {
                    name: organigramConfig[item].name,
                    id: organigramConfig[item].id,
                },
            ];
        }

        setCurrentOrganigramConfigDispatch({
            1: newLevels[1],
            2: newLevels[2],
            3: newLevels[3],
        });
    };

    const fetchAndSetUserOrgStructures = async userEmail => {
        let postUserConfigRef = null;
        let currentUserOrgItems = null;

        postUserConfigRef = await getUserConfiguration(userEmail);
        if (postUserConfigRef !== null) {
            currentUserOrgItems = await getCurrentUserOrgStructure(
                postUserConfigRef
            );
        }
        if (currentUserOrgItems !== null) {
            // array is zero based so level 1 === 0
            const newState = [...dropdowns];
            currentUserOrgItems.forEach(item => {
                const { level, name, id } = item;
                newState[level - 1] = name;
                setOrgLevelById(level, id);
            });
            setDropdowns(newState);
        }
    };

    // componentDidMount
    useEffect(() => {
        prevTexts.current = texts;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // componentDidMMount
    useEffect(() => {
        const id = parseInt(match.params.id);

        if (id) {
            const user = users.filter(user => user.id === id)[0];

            // Hydrate redux store with organigram data

            let currentUser = users.filter(user => {
                return id === user.id;
            });
            let currentUserOrgId = currentUser[0].organization.id;
            let currentCenter = dispatchCenters.filter(center => {
                return currentUserOrgId === center.id;
            });
            fetchAndSetOrganigramConfig(currentCenter[0].addOnId);
            fetchAndSetUserOrgStructures(user.email);
        } else {
            setIsNewUserInitialConfig(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentOrganigramConfig) {
            const mapOrganigramListItem = organigramListItem => ({
                label: organigramListItem.name,
                id: organigramListItem.id,
            });

            setAvailableLevels([
                currentOrganigramConfig[1].map(mapOrganigramListItem),
                currentOrganigramConfig[2].map(mapOrganigramListItem),
                currentOrganigramConfig[3].map(mapOrganigramListItem),
            ]);
        }
    }, [currentOrganigramConfig]);

    useEffect(() => {
        // If admin changes the language, change dropdown texts
        if (texts && texts !== prevTexts.current) {
            const newDropdownState = [...dropdowns];
            const defaultText = replaceText(
                prevTexts.current,
                'dropdown.default'
            );

            if (dispatchCenter === defaultText) {
                setDispatchCenter(replaceText(texts, 'dropdown.default'));
            }

            dropdowns.forEach((dropdownLevel, index) => {
                if (dropdownLevel === defaultText) {
                    newDropdownState[index] = replaceText(
                        texts,
                        'dropdown.default'
                    );
                    setDropdowns(newDropdownState);
                }
            });
        }
        prevTexts.current = texts;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [texts]);

    useEffect(() => {
        const resetToPreviousDispatchCenter = () => {
            setDispatchCenter(dispatchCenter);
            setShouldResetToPreviousDispatchCenter(false);
        };

        const setDispatchCenterAndFetchOrganigramConfig = dispatchCenter => {
            const selectedCenterAddOnId =
                getSelectedDispatcherId(dispatchCenter);
            setDispatchCenter(dispatchCenter);
            fetchAndSetOrganigramConfig(selectedCenterAddOnId);
        };

        const shouldFetchOrganigramConfig = currentDispatchCenter => {
            return (
                dispatchCenter !== replaceText(texts, 'dropdown.default') &&
                dispatchCenter !== currentDispatchCenter &&
                dropdowns[0] === replaceText(texts, 'dropdown.default') &&
                dropdowns[1] === replaceText(texts, 'dropdown.default') &&
                dropdowns[2] === replaceText(texts, 'dropdown.default')
            );
        };

        const shouldResetOrganigramDropdownValues = () => {
            return (
                (dispatchCenter !== replaceText(texts, 'dropdown.default') &&
                    dispatchCenter !== currentDispatchCenter &&
                    dropdowns[0] !== replaceText(texts, 'dropdown.default')) ||
                dropdowns[1] !== replaceText(texts, 'dropdown.default') ||
                dropdowns[2] !== replaceText(texts, 'dropdown.default')
            );
        };

        const confirmOrganigramChangeWarning = () => {
            return window.confirm(
                replaceText(texts, 'organigram.change.warning')
            );
        };

        const resetOrganigramDropdownValues = () => {
            setDropdowns([
                replaceText(texts, 'dropdown.default'),
                replaceText(texts, 'dropdown.default'),
                replaceText(texts, 'dropdown.default'),
            ]);
            setOrgLevelById(1, '');
            setOrgLevelById(2, '');
            setOrgLevelById(3, '');
        };
        /* If change of dispatch center was triggered from within this component,
         * reset it to previous value stored in dispatchCenter state.
         */
        if (shouldResetToPreviousDispatchCenter) {
            resetToPreviousDispatchCenter();
            return;
        }

        if (currentDispatchCenter === replaceText(texts, 'dropdown.default')) {
            return;
        }

        if (isNewUserInitialConfig) {
            setDispatchCenterAndFetchOrganigramConfig(currentDispatchCenter);
            setIsNewUserInitialConfig(false);
            return;
        }

        if (shouldFetchOrganigramConfig(currentDispatchCenter)) {
            setDispatchCenterAndFetchOrganigramConfig(currentDispatchCenter);
            return;
        }

        if (shouldResetOrganigramDropdownValues()) {
            if (confirmOrganigramChangeWarning()) {
                resetOrganigramDropdownValues();
                setDispatchCenterAndFetchOrganigramConfig(
                    currentDispatchCenter
                );
                return;
            } else {
                setDispatchCenter(dispatchCenter);
                setShouldResetToPreviousDispatchCenter(true);
                selectDispatchCenterExternally(dispatchCenter);
                return;
            }
        }

        selectDispatchCenterExternally(currentDispatchCenter);
        setDispatchCenter(currentDispatchCenter);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDispatchCenter]);

    useEffect(() => {
        // componentWillUnmount equivalent
        return () => {
            setCurrentOrganigramConfigDispatch({
                1: [],
                2: [],
                3: [],
            });
        };
    }, []);

    const defaultDropdownLevel = {
        label: replaceText(texts, 'dropdown.default'),
        id: 'select',
    }

    return (
        <div className="organigramSelect">
            <div>
                <label>
                    <h3>Level - 1</h3>
                </label>
                <Dropdown
                    label={dropdowns[0]}
                    clickHandler={event =>
                        setOrgLevel({ label: event.label, orgLevel: 1 })
                    }>
                    <DropDownContent items={[defaultDropdownLevel, ...availableLevels[0]]} />
                </Dropdown>
            </div>
            <div>
                <label>
                    <h3>Level - 2</h3>
                </label>
                <Dropdown
                    label={dropdowns[1]}
                    clickHandler={event =>
                        setOrgLevel({ label: event.label, orgLevel: 2 })
                    }>
                    <DropDownContent items={[defaultDropdownLevel, ...availableLevels[1]]} />
                </Dropdown>
            </div>
            <div>
                <label>
                    <h3>Level - 3</h3>
                </label>
                <Dropdown
                    label={dropdowns[2]}
                    clickHandler={event =>
                        setOrgLevel({ label: event.label, orgLevel: 3 })
                    }>
                    <DropDownContent items={[defaultDropdownLevel, ...availableLevels[2]]} />
                </Dropdown>
            </div>
        </div>
    );
};

// PropTypes for Component
UserOrganigram.propTypes = {
    edit: PropTypes.bool,
    users: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
    currentUserRole: PropTypes.string,
    currentUserOrganization: PropTypes.any,
    currentOrganigramConfig: PropTypes.object,
    dispatchCenters: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
            ref: PropTypes.string,
        })
    ),
    history: PropTypes.any,
    match: PropTypes.any,
    texts: PropTypes.object,
    currentDispatchCenter: PropTypes.string,
    selectDispatchCenterExternally: PropTypes.string,
    setOrgLevelById: PropTypes.func,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        users: state.users,
        currentUserRole: state.currentUser.role,
        currentUserOrganization: state.currentUser.organization,
        currentOrganigramConfig: state.currentOrganigramConfig,
        dispatchCenters: state.dispatchCenters.centers,
        texts: state.texts.texts,
    };
};

// Connect and Dispatch to Component
export default withRouter(connect(mapStateToProps)(UserOrganigram));
