import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './MessageFooter.scss';
import { saveSystemFooterDispatch } from '../../redux/actions';
import { replaceText } from '../../helper';

/**
 * Form for the message displayed in the footer of the application frontend.
 */
class MessageFooter extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            health: 'GREEN',
            tooltip: '',
            saveState: 'START',
        };

        this.tooltipRef = React.createRef();
    }

    changeRadio = e => {
        const health = e.target.value;

        this.setState({ health });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.save();
    };

    save = async () => {
        this.setState({ saveState: 'SAVING' });
        await saveSystemFooterDispatch({ ...this.state })
            .then(() => {
                this.setState({ saveState: 'SAVED' });
            })
            .catch(e => {
                //error
            });
    };

    changeTooltip = () => {
        this.setState({
            tooltip: this.tooltipRef.current.value,
        });
    };

    updateFooter = () => {
        const { health, tooltip } = this.props;
        this.setState({
            health,
            tooltip,
        });
    };

    componentDidMount() {
        this.updateFooter();
    }

    render() {
        return (
            <form
                autoComplete="off"
                className="messageFooter"
                onSubmit={this.handleSubmit}>
                <div>
                    <h3>{replaceText(this.props.texts, 'health')}</h3>
                    <p>
                        <input
                            checked={this.state.health === 'GREEN'}
                            value="GREEN"
                            type="radio"
                            name="health"
                            id="green"
                            onChange={this.changeRadio}
                        />
                        <label htmlFor="green">
                            <span>
                                {replaceText(this.props.texts, 'health.green')}
                            </span>
                            <span className="green"></span>
                        </label>
                    </p>
                    <p>
                        <input
                            checked={this.state.health === 'YELLOW'}
                            value="YELLOW"
                            type="radio"
                            name="health"
                            id="yellow"
                            onChange={this.changeRadio}
                        />
                        <label htmlFor="yellow">
                            <span>
                                {replaceText(this.props.texts, 'health.yellow')}
                            </span>
                            <span className="yellow"></span>
                        </label>
                    </p>
                    <p>
                        <input
                            checked={this.state.health === 'RED'}
                            value="RED"
                            type="radio"
                            name="health"
                            id="red"
                            onChange={this.changeRadio}
                        />
                        <label htmlFor="red">
                            <span>
                                {replaceText(this.props.texts, 'health.red')}
                            </span>
                            <span className="red"></span>
                        </label>
                    </p>
                </div>
                <div>
                    <label htmlFor="tooltip">
                        <h3>
                            {replaceText(this.props.texts, 'health.tooltip')}
                        </h3>
                    </label>
                    <input
                        type="text"
                        id="tooltip"
                        placeholder={replaceText(
                            this.props.texts,
                            'health.placeholder'
                        )}
                        defaultValue={this.state.tooltip}
                        onChange={this.changeTooltip}
                        ref={this.tooltipRef}
                        maxLength={100}
                    />
                    <div className="hint">
                        <span>
                            {replaceText(this.props.texts, 'max.chars', 100)}
                        </span>
                    </div>
                </div>
                <div>
                    <div>
                        {this.state.saveState === 'SAVING' && (
                            <div className="messageFooter__save">
                                {replaceText(this.props.texts, 'saving')}
                            </div>
                        )}
                        {this.state.saveState === 'SAVED' && (
                            <div className="messageFooter__save">
                                {replaceText(this.props.texts, 'saved')}
                            </div>
                        )}
                        <button
                            type="submit"
                            className="btn btn--primary"
                            disabled={this.state.saveState === 'SAVING'}>
                            {replaceText(this.props.texts, 'action.save')}
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

// PropTypes for this Component
MessageFooter.propTypes = {
    tooltip: PropTypes.string,
    health: PropTypes.string,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        tooltip: state.messages.messageFooter.tooltip,
        health: state.messages.messageFooter.health,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(MessageFooter);
