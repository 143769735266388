import { REMOVE_CURRENT_USER, SET_CURRENT_USER } from '../actionTypes';

export const initialState = {
    id: null,
    email: null,
    role: null,
    organization: null,
    isLoggedIn: false,
    error: null,
};

/**
 * current user reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const currentUserReducer = (state = initialState, action) => {
    if (action.type === REMOVE_CURRENT_USER) {
        return {
            ...initialState,
        };
    }
    if (action.type === SET_CURRENT_USER) {
        return {
            ...state,
            isLoggedIn: true,
            ...action.currentUser,
        };
    }
    return state;
};

export default currentUserReducer;
