import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import { DEFAULT_TIMEOUT, RTE_TOOLBAR } from '../../config';
import { replaceText } from '../../helper';
import { connect } from 'react-redux';

import './CenterDisclaimer.scss';
import {
    ApplicationConfig,
    DisclaimerConfiguration,
    DispatchCenter,
    FEATURES,
} from '../../types';
import ToggleSwitch from '../global/ToggleSwitch';
import {
    getDisclaimerConfigurationForDispatchCenter,
    resetGivenConfirmationsForDispatchCenter,
    updateDisclaimerConfiguration,
} from '../../api/disclaimerAPI';
import {
    resetDisclaimerSettingsDispatch,
    setConfirmationEnabledForDispatcherDispatch,
    setConfirmationTextDispatch,
    setDisclaimerCallerDispatch,
    setDisclaimerCallerEnabledDispatch,
    setDisclaimerDispatch,
    setDisclaimerDispatcherEnabledDispatch,
} from '../../redux/actions';
import { resetUserDisclaimerAcceptedDispatch } from '../../redux/actions/users';
import { Reset } from '../icons/Reset';

interface CenterDisclaimerProps {
    texts: { [key: string]: string };
    currentCenter: DispatchCenter;
    currentApplicationConfig: ApplicationConfig;
    disclaimer: DisclaimerConfiguration;
}

const CenterDisclaimerComponent: React.FC<CenterDisclaimerProps> = ({
    texts,
    currentApplicationConfig,
    currentCenter,
    disclaimer,
}) => {
    // Proxy RTE values
    const [dispatcherText, setDispatcherText] = useState(
        RichTextEditor.createEmptyValue()
    );
    const [callerText, setCallerText] = useState(
        RichTextEditor.createEmptyValue()
    );
    const [isConfirmationRequested, setIsConfirmationRequested] =
        useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const showFormSubmitText = (textType: String) => {
        if (textType === 'confirm') {
            setIsConfirmationRequested(true);
            setTimeout(() => {
                setIsConfirmationRequested(false);
            }, DEFAULT_TIMEOUT);
        } else {
            setIsFormSubmitted(true);
            setTimeout(() => {
                setIsFormSubmitted(false);
            }, DEFAULT_TIMEOUT);
        }
    };

    // load disclaimer data
    useEffect(() => {
        const loadDisclaimerData = async () => {
            const data = await getDisclaimerConfigurationForDispatchCenter(
                currentCenter.addOnId
            );

            if (data) {
                if (data.confirmationText)
                    setConfirmationTextDispatch(data.confirmationText);
                if (data.disclaimerTextDispatcher)
                    setDisclaimerDispatch(data.disclaimerTextDispatcher);
                if (data.disclaimerTextBystander)
                    setDisclaimerCallerDispatch(data.disclaimerTextBystander);
                if (data.enabledForBystander !== null)
                    setDisclaimerCallerEnabledDispatch(
                        data.enabledForBystander
                    );
                if (data.enabledForDispatcher !== null)
                    setDisclaimerDispatcherEnabledDispatch(
                        data.enabledForDispatcher
                    );
                if (data.confirmationEnabledForDispatcher !== null)
                    setConfirmationEnabledForDispatcherDispatch(
                        data.confirmationEnabledForDispatcher
                    );
            } else {
                await updateDisclaimerConfiguration({
                    addOnId: currentCenter.addOnId,
                    disclaimerTextDispatcher: '',
                    disclaimerTextBystander: '',
                    confirmationText: '',
                    enabledForDispatcher: false,
                    enabledForBystander: false,
                    confirmationEnabledForDispatcher: false,
                });
            }
        };

        loadDisclaimerData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // map string to RichTextEditor value
        setDispatcherText(
            RichTextEditor.createValueFromString(
                disclaimer.disclaimerTextDispatcher,
                'html'
            )
        );
    }, [disclaimer.disclaimerTextDispatcher]);

    useEffect(() => {
        // map string to RichTextEditor value
        setCallerText(
            RichTextEditor.createValueFromString(
                disclaimer.disclaimerTextBystander,
                'html'
            )
        );
    }, [disclaimer.disclaimerTextBystander]);

    useEffect(() => {
        return () => {
            resetDisclaimerSettingsDispatch();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        };
    }, []);

    // FEATURES
    const dispatcherDisclaimerEnabled =
        currentApplicationConfig.enabledFeatures.indexOf(
            FEATURES.DISCLAIMER_DISPATCHER
        ) > -1;
    const callerDisclaimerEnabled =
        currentApplicationConfig.enabledFeatures.indexOf(
            FEATURES.DISCLAIMER_BYSTANDER
        ) > -1;

    // save everything
    const save = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const result = await updateDisclaimerConfiguration({
            disclaimerTextDispatcher: dispatcherText.toString('html'),
            disclaimerTextBystander: callerText.toString('html'),
            addOnId: currentCenter.addOnId,
            confirmationText: disclaimer.confirmationText,
            enabledForDispatcher: disclaimer.enabledForDispatcher,
            enabledForBystander: disclaimer.enabledForBystander,
            confirmationEnabledForDispatcher:
                disclaimer.confirmationEnabledForDispatcher,
        });

        // update states for proxied values
        if (result) {
            setDisclaimerDispatch(result.disclaimerTextDispatcher);
            setDisclaimerCallerDispatch(result.disclaimerTextBystander);
        }
        showFormSubmitText('save');
    };

    // toggle one of the switches
    const toggle = async (id: string, currentValue: boolean) => {
        const data = await getDisclaimerConfigurationForDispatchCenter(
            currentCenter.addOnId
        );

        const {
            disclaimerTextDispatcher,
            disclaimerTextBystander,
            confirmationText,
            enabledForDispatcher,
            enabledForBystander,
            confirmationEnabledForDispatcher,
        } = data;

        await updateDisclaimerConfiguration({
            disclaimerTextDispatcher,
            disclaimerTextBystander,
            addOnId: currentCenter.addOnId,
            confirmationText,
            enabledForDispatcher:
                id === 'enabledForDispatcher'
                    ? !currentValue
                    : enabledForDispatcher,
            enabledForBystander:
                id === 'enabledForBystander'
                    ? !currentValue
                    : enabledForBystander,
            confirmationEnabledForDispatcher:
                id === 'confirmationEnabledForDispatcher'
                    ? !currentValue
                    : confirmationEnabledForDispatcher,
        });

        if (id === 'enabledForDispatcher') {
            setDisclaimerDispatcherEnabledDispatch(!currentValue);
        }
        if (id === 'enabledForBystander') {
            setDisclaimerCallerEnabledDispatch(!currentValue);
        }
        if (id === 'confirmationEnabledForDispatcher') {
            setConfirmationEnabledForDispatcherDispatch(!currentValue);
        }
    };

    // reset the confirmations for the center of the current user
    const resetGivenConfirmations = async () => {
        await resetGivenConfirmationsForDispatchCenter(currentCenter.addOnId);
        resetUserDisclaimerAcceptedDispatch(currentCenter.id);
        showFormSubmitText('confirm');
    };

    return (
        <form className="center-disclaimer" onSubmit={save}>
            {dispatcherDisclaimerEnabled && (
                <div className="center-disclaimer__group">
                    <h2>{replaceText(texts, 'disclaimer.dispatcher')}</h2>
                    <div className="center-disclaimer__area">
                        <label htmlFor="dispatcherText" className="flex">
                            <h3>
                                {replaceText(
                                    texts,
                                    'disclaimer.dispatcher.text'
                                )}
                            </h3>
                            <ToggleSwitch
                                isActive={disclaimer.enabledForDispatcher}
                                label={replaceText(
                                    texts,
                                    `disclaimer.dispatcher.enabled`
                                )}
                                id={'enabledForDispatcher'}
                                changeHandler={() => {
                                    toggle(
                                        'enabledForDispatcher',
                                        disclaimer.enabledForDispatcher
                                    );
                                }}
                            />
                        </label>
                        <div className="center-disclaimer__row">
                            <RichTextEditor
                                toolbarConfig={RTE_TOOLBAR}
                                readOnly={false}
                                value={
                                    dispatcherText ||
                                    RichTextEditor.createEmptyValue()
                                }
                                placeholder={replaceText(
                                    texts,
                                    'disclaimer.dispatcher.placeholder'
                                )}
                                onChange={
                                    setDispatcherText
                                }></RichTextEditor>{' '}
                            <span>
                                <span className="hint">
                                    {replaceText(texts, 'max.chars', 10000)}
                                </span>
                                <br />
                                {replaceText(
                                    texts,
                                    'disclaimer.dispatcher.hint'
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="center-disclaimer__area">
                        <label htmlFor="confirmationText" className="flex">
                            <h3>
                                {replaceText(texts, 'disclaimer.confirmation')}
                            </h3>
                            <ToggleSwitch
                                isActive={
                                    disclaimer.confirmationEnabledForDispatcher
                                }
                                label={replaceText(
                                    texts,
                                    `disclaimer.confirmation.enabled`
                                )}
                                id={'confirmationEnabledForDispatcher'}
                                changeHandler={() => {
                                    toggle(
                                        'confirmationEnabledForDispatcher',
                                        disclaimer.confirmationEnabledForDispatcher
                                    );
                                }}
                            />
                        </label>
                        <div className="center-disclaimer__row">
                            <textarea
                                id="confirmationText"
                                value={disclaimer.confirmationText}
                                onChange={e =>
                                    setConfirmationTextDispatch(
                                        e.currentTarget.value
                                    )
                                }
                                placeholder={replaceText(
                                    texts,
                                    'disclaimer.confirmation.placeholder'
                                )}
                                maxLength={255}></textarea>
                            <span>
                                <span className="hint">
                                    {replaceText(texts, 'max.chars', 255)}
                                </span>
                                <br />
                                {replaceText(
                                    texts,
                                    'disclaimer.confirmation.hint'
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="center-disclaimer__area flex justify-start align-start">
                        <div className="flex__group">
                        <h3>{replaceText(texts, 'disclaimer.reset')}</h3>
                            <button
                                type="button"
                                className="btn btn--primary"
                                disabled={isConfirmationRequested}
                                onClick={resetGivenConfirmations}>
                                <Reset />
                            </button>
                        </div>
                        <div className="flex__group">
                            <span className="hint ml-20">
                                {replaceText(texts, 'disclaimer.reset.hint')}
                            </span>
                        </div>
                        <div
                            className={`confirmation-request-succeeded-message ${
                                isConfirmationRequested
                                    ? 'success-message--isShown'
                                    : ''
                            }`}>
                            <span>
                                {replaceText(
                                    texts,
                                    'disclaimer.confirmation.requested'
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {callerDisclaimerEnabled && (
                <div className="center-disclaimer__group">
                    <h2>{replaceText(texts, 'disclaimer.caller')}</h2>
                    <div className="center-disclaimer__area">
                        <label htmlFor="callerText" className="flex">
                            <h3>
                                {replaceText(texts, 'disclaimer.caller.text')}
                            </h3>
                            <ToggleSwitch
                                isActive={disclaimer.enabledForBystander}
                                label={replaceText(
                                    texts,
                                    `disclaimer.caller.enabled`
                                )}
                                id={'enabledForBystander'}
                                changeHandler={() => {
                                    toggle(
                                        'enabledForBystander',
                                        disclaimer.enabledForBystander
                                    );
                                }}
                            />
                        </label>
                        <div className="centerForm__row">
                            <RichTextEditor
                                toolbarConfig={RTE_TOOLBAR}
                                readOnly={false}
                                value={
                                    callerText ||
                                    RichTextEditor.createEmptyValue()
                                }
                                placeholder={replaceText(
                                    texts,
                                    'disclaimer.caller.placeholder'
                                )}
                                onChange={setCallerText}></RichTextEditor>{' '}
                            <span>
                                <span className="hint">
                                    {replaceText(texts, 'max.chars', 10000)}
                                </span>
                                <br />
                                {replaceText(texts, 'disclaimer.caller.hint')}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {(dispatcherDisclaimerEnabled || callerDisclaimerEnabled) && (
                <div className="center-disclaimer__group">
                    <div>
                        <button
                            type="submit"
                            className="btn btn--primary"
                            disabled={isFormSubmitted}>
                            {replaceText(texts, 'action.save.text')}
                        </button>
                    </div>
                    <div
                        className={`success-message ${
                            isFormSubmitted ? 'success-message--isShown' : ''
                        }`}>
                        <span>{replaceText(texts, 'settings.saved')}</span>
                    </div>
                </div>
            )}
        </form>
    );
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        disclaimer: state.disclaimer as DisclaimerConfiguration,
    };
};

// Connect Props and Dispatch to Component
export const CenterDisclaimer = connect(mapStateToProps)(
    CenterDisclaimerComponent
);
