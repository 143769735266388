import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
    updateCenterFilterDispatchThen,
    setFilteredCentersDispatch,
    setCenterCurrentPageDispatch,
    toggleCenterFilterVisibilityDispatch,
    setCentersFilteredDispatch,
    resetCenterFiltersDispatch,
} from '../../redux/actions/dispatchCenters';

import { centerProptype } from '../../redux/reducers/dispatchCenters';

import '../global/Filter.scss';
import { filterItemsByProperty } from '../../helper/filterHelper';
import { replaceText } from '../../helper';

/**
 * Filter for the center list.
 */
const CentersFilter = ({
    filterProperties,
    filterOpen,
    isFiltered,
    sortedCenters,
    texts,
}) => {
    const nameRef = useRef(null);
    const ipRestriction = useRef(null);

    const updateInput = (ref, property) => {
        let value = ref.current.value;

        updateCenterFilterDispatchThen({ property, value }).then(() => {
            setCenterCurrentPageDispatch(1);
        });
    };

    const clearFilter = () => {
        resetCenterFiltersDispatch();

        nameRef.current.value = '';
        ipRestriction.current.value = '';
    };

    useEffect(() => {
        const filterCenters = () => {
            const filteredCentersByName = filterCentersByProperty(
                sortedCenters,
                'name'
            );
            const filteredCentersByIP = filterCentersByProperty(
                filteredCentersByName,
                'ipRestriction'
            );

            setFilteredCentersDispatch(filteredCentersByIP);
            setCentersFilteredDispatch(
                sortedCenters.length !== filteredCentersByIP.length
            );
        };

        const filterCentersByProperty = (centers, property) => {
            return filterItemsByProperty({
                items: centers,
                property,
                filterProperties: filterProperties,
            });
        };

        filterCenters();
    }, [filterProperties, sortedCenters]);

    const classes = clsx('centersFilter', {
        'centersFilter--open': filterOpen,
    });
    return (
        <div className={classes}>
            <div
                className="centersFilter__toggle"
                onClick={toggleCenterFilterVisibilityDispatch}>
                {filterOpen ? (
                    <span>{replaceText(texts, 'filter.close')}</span>
                ) : (
                    <span>{replaceText(texts, 'filter.open')}</span>
                )}
            </div>

            <div className="centersFilter__filters">
                <div>
                    <label htmlFor="name">
                        <h3>{replaceText(texts, 'name')}</h3>
                    </label>
                    <input
                        id="name"
                        type="text"
                        ref={nameRef}
                        onChange={() => {
                            updateInput(nameRef, 'name');
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="ipRestriction">
                        <h3>{replaceText(texts, 'ip.restriction')}</h3>
                    </label>
                    <input
                        id="ipRestriction"
                        type="text"
                        ref={ipRestriction}
                        onChange={() => {
                            updateInput(ipRestriction, 'ipRestriction');
                        }}
                    />
                </div>
            </div>
            {isFiltered ? (
                <div className="centersFilter__clear" onClick={clearFilter}>
                    {replaceText(texts, 'filter.clear')}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

// PropTypes for this Component
CentersFilter.propTypes = {
    filterProperties: PropTypes.shape({
        name: PropTypes.string,
    }),
    currentUserrole: PropTypes.string,
    sortedCenters: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    filterOpen: PropTypes.bool,
    isFiltered: PropTypes.bool,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        filterProperties: state.centersFilters.filterProperties,
        filterOpen: state.centersFilters.open,
        isFiltered: state.centersFilters.isFiltered,
        sortedCenters: state.centersSorting.sortedCenters,
        currentUserrole: state.currentUser.role,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(CentersFilter);
