import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// import Caret from './icons/Caret';
import {
    setCenterCurrentPageDispatch,
    setPaginatedCentersDispatch,
} from '../../redux/actions/dispatchCenters';

import { centerProptype } from '../../redux/reducers/dispatchCenters';

import '../global/Pagination.scss';

/**
 * Pagination navigation for the current center list.
 */
class CenterPagination extends PureComponent {
    paginateCenters = () => {
        const paginatedCenters = this.props.filteredCenters.slice(
            (this.props.currentPage - 1) * this.props.itemsPerPage,
            this.props.currentPage * this.props.itemsPerPage
        );
        setPaginatedCentersDispatch([...paginatedCenters]);
    };

    componentDidMount() {
        this.paginateCenters();
    }

    componentDidUpdate() {
        this.paginateCenters();
    }

    render() {
        const { currentPage, itemsPerPage, filteredCenters } = this.props;
        const totalPages = Math.ceil(filteredCenters.length / itemsPerPage);
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            const classes = clsx('centersPagination__page', {
                'centersPagination__page--active': i === currentPage,
            });
            const page = (
                <button
                    className={classes}
                    key={i}
                    onClick={() => {
                        setCenterCurrentPageDispatch(i);
                    }}>
                    {i}
                </button>
            );

            pages.push(page);
        }

        return (
            <div className="centersPagination">
                {/* <button
                    className="pagination__page pagination__page--prev"
                    onClick={() => {
                        if (currentPage > 1) {
                            setCurrentPageDispatch(currentPage - 1);
                        }
                    }}>
                    <Caret />
                </button> */}

                {pages}

                {/* <button
                    className="pagination__page pagination__page--next"
                    onClick={() => {
                        if (currentPage < totalPages) {
                            setCurrentPageDispatch(currentPage + 1);
                        }
                    }}>
                    <Caret />
                </button> */}
            </div>
        );
    }
}

// PropTypes for this Component
CenterPagination.propTypes = {
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    filteredCenters: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentPage: state.centersPagination.currentPage,
        itemsPerPage: state.centersPagination.itemsPerPage,
        filteredCenters: state.centersFilters.filteredCenters,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(CenterPagination);
