import { SET_USER_CURRENT_PAGE, SET_PAGINATED_USERS } from '../actionTypes';

export const initialState = {
    currentPage: 1,
    itemsPerPage: 10,
    paginatedUsers: [],
};

/**
 * user pagination reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducer = (state = initialState, action) => {
    if (action.type === SET_USER_CURRENT_PAGE) {
        return {
            ...state,
            currentPage: action.currentPage,
        };
    }
    if (action.type === SET_PAGINATED_USERS) {
        return {
            ...state,
            paginatedUsers: action.paginatedUsers,
        };
    }
    return state;
};

export default reducer;
