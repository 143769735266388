import {
    UPDATE_USER_FILTER,
    SET_FILTERED_USERS,
    TOGGLE_USER_FILTER_VISIBILITY,
    SET_USERS_FILTERED,
    RESET_USER_FILTER,
    CLOSE_USER_FILTER,
} from '../actionTypes';

export const initialState = {
    filterProperties: {
        dispatchCenter: '',
        username: '',
        role: {},
    },
    filteredUsers: [],
    isFiltered: false,
    open: false,
};

/**
 * user filter reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === UPDATE_USER_FILTER) {
        return {
            ...state,
            filterProperties: {
                ...state.filterProperties,
                [action.filter.property]: action.filter.value,
            },
        };
    }
    if (action.type === SET_FILTERED_USERS) {
        return {
            ...state,
            filteredUsers: action.filteredUsers,
        };
    }
    if (action.type === RESET_USER_FILTER) {
        return {
            ...state,
            filterProperties: { ...initialState.filterProperties },
        };
    }
    if (action.type === SET_USERS_FILTERED) {
        return {
            ...state,
            isFiltered: action.isFiltered,
        };
    }
    if (action.type === TOGGLE_USER_FILTER_VISIBILITY) {
        return {
            ...state,
            open: !state.open,
        };
    }
    if (action.type === CLOSE_USER_FILTER) {
        return {
            ...state,
            open: false,
        };
    }
    return state;
};

export default reducers;
