import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LogoutButton from '../LogoutButton';

import './Header.scss';
import Navigation from './Navigation';
import { defaultFeatures, VERSION } from '../../config';
import { getLogo, getTitle, replaceText } from '../../helper';
import LanguageSelect from './LanguageSelect';
import { connect } from 'react-redux';
import { changeLanuage } from '../../redux/actions';
import { applicationProptype } from '../../redux/reducers/applicationConfigs';
import { changeFeature } from '../centers/CenterFeatures';

/**
 * Application header with logo, navigation and logout
 *
 * @component Navigation
 * @component LogoutButton
 */

const Header = ({
    showLogout,
    applicationConfigs,
    dispatchCenters,
    currentOrganization,
    texts,
    language,
}) => {
    useEffect(() => {
        if (
            currentOrganization &&
            applicationConfigs.length > 0 &&
            dispatchCenters.length > 0
        ) {
            let currentCenterId;
            let currentEnabledFeatures;

            const currentCenter = dispatchCenters.filter(
                center => center.organizationId === currentOrganization.id
            );
            if (currentCenter.length > 0) {
                currentCenterId = currentCenter[0].addOnId;
            }
            const filteredApplicationsConfigs = applicationConfigs.filter(
                config => config.dispatchCenterId === currentCenterId
            );

            if (filteredApplicationsConfigs.length > 0) {
                currentEnabledFeatures =
                    filteredApplicationsConfigs[0].enabledFeatures;
            }

            // Upon user login, remove MULTILANGUAGE as enabledFeature database entry.
            defaultFeatures.map(feature => {
                const currentConfig = filteredApplicationsConfigs[0];
                const enabledFeatures = currentEnabledFeatures;
                if (currentEnabledFeatures.length !== 0) {
                    const isActive =
                        currentEnabledFeatures.indexOf(feature) !== -1;

                    if (feature === 'MULTILANGUAGE' && isActive) {
                        changeFeature({
                            feature,
                            enabledFeatures,
                            currentConfig,
                            currentCenter,
                        });
                    }
                    return null;
                } else {
                    return null;
                }
            });
        }
    }, [applicationConfigs, currentOrganization, dispatchCenters]);

    return (
        <header className="header">
            <div className="wrapper">
                <div className="logo">
                    <Link to="/user">
                        <img src={getLogo()} alt="logo" />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getTitle(),
                            }}
                        />
                    </Link>
                </div>
                <div className="version">
                    <span>
                        {replaceText(texts, 'version')}
                        <strong>{VERSION}</strong>
                    </span>
                </div>
            </div>

            <Navigation />

            <div className="flex">
                <LanguageSelect
                    language={language}
                    selectLanguageHandler={changeLanuage}
                />

                {showLogout ? <LogoutButton /> : ''}
            </div>
        </header>
    );
};

// PropTypes for this Component
Header.propTypes = {
    showLogout: PropTypes.bool,
    hideLanguage: PropTypes.bool,
    applicationConfigs: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    dispatchCenters: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    currentOrganization: PropTypes.object,
    texts: PropTypes.object,
    language: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        applicationConfigs: state.applicationConfigs,
        dispatchCenters: state.dispatchCenters.centers,
        currentOrganization: state.currentUser.organization,
        texts: state.texts.texts,
        language: state.texts.language,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(Header);
