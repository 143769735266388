import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { userProptypes } from '../../redux/reducers/users';
import UserListEntry from './UserListEntry';

import './UserList.scss';
import { replaceText } from '../../helper';

/**
 * Wrapper Component for all the user list entries.
 *
 * @component UserListEntry - single user entry
 */

const UserList = ({ paginatedUsers, texts }) => {
    return (
        <div className="userList">
            {paginatedUsers.map(user => (
                <UserListEntry user={user} key={user.id} />
            ))}
            {paginatedUsers.length === 0 ? (
                <div className="userList__noResults">
                    {replaceText(texts, 'noresults')}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

// PropTypes for this Component
UserList.propTypes = {
    paginatedUsers: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        paginatedUsers: state.userPagination.paginatedUsers,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(UserList);
