import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCurrentOrganigramConfigDispatch } from '../../redux/actions';
import { centerProptype } from '../../redux/reducers/dispatchCenters';
import Add from '../icons/Add';
import Remove from '../icons/Remove';

import './Organigram.scss';
import {
    deleteOrganigramConfig,
    getOrganigramConfig,
    postOrganigramConfig,
} from '../../api/organigramAPI';
import { replaceText } from '../../helper';
import { DEFAULT_MAX_DISPLAY_LENGTH } from '../../config';

const Organigram = ({ texts, centers, match }) => {
    const levelInputRefs = [useRef(null), useRef(null), useRef(null)];
    const [levels, setLevels] = useState({
        1: [],
        2: [],
        3: [],
    });
    const [centerAddOnId, setCenterAddOnId] = useState(null);
    const [organizationId, setOrganizationId] = useState(null);

    const addNewItem = async (key, i) => {
        const isInputInArray = levels[key].some(
            orgItem => orgItem.name === levelInputRefs[i].current.value
        );

        if (levels[key].length + 1 <= 30) {
            if (!isInputInArray && levelInputRefs[i].current.value.length > 0) {
                const newLevels = { ...levels };
                let newOrgItem = {};

                if (organizationId) {
                    newOrgItem = await postOrganigramConfig(
                        centerAddOnId,
                        levelInputRefs[i].current.value,
                        i + 1
                    );
                }
                if (
                    newOrgItem &&
                    Object.keys(newOrgItem).length !== 0 &&
                    Object.getPrototypeOf(newOrgItem) === Object.prototype
                ) {
                    newLevels[key] = [
                        ...newLevels[key],
                        {
                            name: levelInputRefs[i].current.value,
                            id: newOrgItem.id,
                        },
                    ];
                    setLevels(newLevels);
                    setCurrentOrganigramConfigDispatch({
                        1: levels[1],
                        2: levels[2],
                        3: levels[3],
                    });
                    levelInputRefs[i].current.value = '';
                } else {
                    newLevels[key] = [
                        ...newLevels[key],
                        {
                            name: levelInputRefs[i].current.value,
                        },
                    ];
                    setLevels(newLevels);
                    setCurrentOrganigramConfigDispatch({
                        1: levels[1],
                        2: levels[2],
                        3: levels[3],
                    });
                    levelInputRefs[i].current.value = '';
                }
            }
        }
    };

    const deleteItem = async (level, item) => {
        const newLevels = { ...levels };

        if (organizationId) {
            let result = await deleteOrganigramConfig(item[1].id);

            if (result.status && result.status === 204) {
                newLevels[level] = newLevels[level].filter(
                    itemToDelete => itemToDelete.name !== item[1].name
                );

                setLevels(newLevels);
            } else if (result.status && result.status === 409) {
                window.alert(replaceText(texts, 'organigram.deleteError'));
            }
        } else {
            newLevels[level] = newLevels[level].filter(
                itemToDelete => itemToDelete.name !== item[1].name
            );

            setLevels(newLevels);
        }
    };

    const fetchOrganigramConfig = async addOnId => {
        const organigramConfig = await getOrganigramConfig(addOnId);

        const newLevels = {
            1: [],
            2: [],
            3: [],
        };

        for (var item in organigramConfig) {
            /*
             * Name and the id of organigram item added
             * Levels begin at 1
             */

            newLevels[organigramConfig[item].level] = [
                ...newLevels[organigramConfig[item].level],
                {
                    name: organigramConfig[item].name,
                    id: organigramConfig[item].id,
                },
            ];
        }
        setLevels(newLevels);

        setCurrentOrganigramConfigDispatch({
            1: newLevels[1],
            2: newLevels[2],
            3: newLevels[3],
        });
    };

    // componentDidMount
    useEffect(() => {
        const id = parseInt(match.params.id);

        if (id) {
            setOrganizationId(id);
            const center = centers.filter(center => center.id === id)[0];
            setCenterAddOnId(center.addOnId);
            fetchOrganigramConfig(center.addOnId);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCurrentOrganigramConfigDispatch({
            1: levels[1],
            2: levels[2],
            3: levels[3],
        });
    }, [levels]);

    // componentWillUnmount
    useEffect(() => {
        return () => {
            setCurrentOrganigramConfigDispatch({
                1: [],
                2: [],
                3: [],
            });
        };
    }, []);

    return (
        <div className="organigram">
            <span>{replaceText(texts, 'organigram.description')}</span>
            <div className="add-levels__container">
                {Object.keys(levels).map((key, i) => (
                    <div className="add-level" key={i}>
                        <span>Level - {i + 1}</span>
                        <div
                            className={
                                levels[key].length + 1 <= 30
                                    ? 'level-input'
                                    : 'level-input level-input--isDisabled'
                            }>
                            <input
                                type="text"
                                maxLength="50"
                                ref={levelInputRefs[i]}
                                placeholder={`z.B. ${
                                    i === 0
                                        ? 'Land'
                                        : i === 1
                                        ? 'Organisation'
                                        : 'Abteilung'
                                }`}
                            />
                            <span
                                onClick={() => {
                                    addNewItem(key, i);
                                }}>
                                <Add />
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="level-lists">
                {/* Map out each level */}
                {Object.keys(levels).map((level, i) => (
                    <div className="level-list" key={i}>
                        <ul>
                            {Object.entries(levels[level]).map((item, j) => (
                                <li key={j}>
                                    <label title={item[1].name}>
                                        <span>
                                            {item[1].name.length >
                                            DEFAULT_MAX_DISPLAY_LENGTH
                                                ? item[1].name.substring(
                                                      0,
                                                      DEFAULT_MAX_DISPLAY_LENGTH
                                                  ) + '...'
                                                : item[1].name}
                                        </span>
                                    </label>
                                    <span
                                        onClick={() => {
                                            deleteItem(level, item);
                                        }}>
                                        <Remove />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

// PropTypes for this Component
Organigram.propTypes = {
    centers: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    match: PropTypes.any,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        centers: state.dispatchCenters.centers,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(connect(mapStateToProps)(Organigram));
