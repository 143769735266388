import { oauth } from '../OAuth';
import { restCall } from './api';

const dispatchCentersEndpoint = process.env.REACT_APP_DISPATCHCENTERS_ENDPOINT;
const organigramConfigEndpoint =
    process.env.REACT_APP_ORG_STRUCTURE_CONFIG_ENDPOINT;
const getOrganigramConfigAPI =
    process.env.REACT_APP_ORG_STRUCTURE_DISPATCH_CENTER;

// DISPATCHER ONLY

/**
 * add all details related to dispatch center organigram config
 * @returns {object} response data
 */
export const postOrganigramConfig = async (addOnId, item, level) => {
    const dispatchCenterId = `${dispatchCentersEndpoint}/${addOnId}`;
    let url = organigramConfigEndpoint;
    let data = {};

    data = {
        dispatchCenter: dispatchCenterId,
        name: item,
        level: level,
    };

    const mergedParams = {
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};

/**
 * get all details related to dispatch center-centric organigram config
 * @returns {object} response data
 */
export const getOrganigramConfig = async centerId => {
    const dispatchCenterConfigURL = `${getOrganigramConfigAPI}/${centerId}`;

    const res = await restCall(dispatchCenterConfigURL, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    }).catch(e => {});

    return { ...res._embedded.orgStructureItems };
};

/**
 * delete specified organigram config via center addOnId
 * @returns {object} response data
 */

export const deleteOrganigramConfig = async organigramItemId => {
    if (organigramItemId) {
        const params = {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${oauth.bearerToken}`,
            },
            method: 'DELETE',
        };

        return await fetch(
            `${organigramConfigEndpoint}/${organigramItemId}`,
            params
        );
    }
};
