import { ADD_USER_SCOPES } from '../actionTypes';

export const initialState = [];

/**
 * scopes reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === ADD_USER_SCOPES) {
        return [...action.scopes];
    }
    return state;
};

export default reducers;
