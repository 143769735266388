import React from 'react';
import PropTypes from 'prop-types';

import Headline from '../global/Headline';
import Header from '../global/Header';

import CenterForm from './CenterForm';
import { connect } from 'react-redux';
import { replaceText } from '../../helper';

/**
 * Wrapper component for the center form when creating a new center
 *
 * @component Header - application header
 * @component Headline - simple headline
 * @component CenterForm - center form
 */

const NewCenter = ({ texts }) => {
    return (
        <div>
            <Header showLogout={true} />
            <div className="container">
                <Headline headline={replaceText(texts, 'action.newcenter')} />
                <CenterForm />
            </div>
        </div>
    );
};

NewCenter.propTypes = {
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(NewCenter);
