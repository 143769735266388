import {
    SET_SORTED_USERS,
    SET_USER_SORTING_PROPERTY,
    TOGGLE_USER_SORTING_ORDER,
} from '../actionTypes';

export const initialState = {
    sortingOrder: 'DESC',
    sortingProperty: 'name',
    sortedUsers: [],
};

/**
 * user sorting reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducer = (state = initialState, action) => {
    if (action.type === SET_USER_SORTING_PROPERTY) {
        return {
            ...state,
            sortingProperty: action.sortingProperty,
            sortingOrder: 'DESC',
        };
    }
    if (action.type === TOGGLE_USER_SORTING_ORDER) {
        return {
            ...state,
            sortingOrder: state.sortingOrder === 'DESC' ? 'ASC' : 'DESC',
        };
    }
    if (action.type === SET_SORTED_USERS) {
        return {
            ...state,
            sortedUsers: action.sortedUsers,
        };
    }
    return state;
};

export default reducer;
