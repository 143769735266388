/**
 * filter the given items by a property
 * @param {object} data
 * @param {object} data.filterProperties
 * @param {array} data.items
 * @param {string} data.property
 * @returns {array} filtered items
 */
export const filterItemsByProperty = ({
    filterProperties,
    items,
    property,
}) => {
    if (
        filterProperties[property] === '' ||
        filterProperties[property] === null ||
        typeof filterProperties[property] === 'undefined'
    ) {
        return items;
    }

    // filter roles
    if (property === 'role') {
        const roles = filterProperties.role;
        const keys = Object.keys(roles);
        if (keys.every(key => !roles[key])) return items;
        return items.filter(item => {
            return keys.some(key =>
                roles[key] ? item.role.toLowerCase() === key : false
            );
        });
    }

    // filter dispatch center
    if (property === 'dispatchCenter') {
        return items.filter(
            item =>
                item.organization &&
                item.organization.name &&
                item.organization.name
                    .toLowerCase()
                    .indexOf(filterProperties[property].toLowerCase()) !== -1
        );
    }

    // filter rest
    return items.filter(item => {
        return (
            item[property] &&
            item[property]
                .toLowerCase()
                .indexOf(filterProperties[property].toLowerCase()) !== -1
        );
    });
};
