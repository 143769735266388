/**
 * dispatchCenters are a combination of the backend entities ORGANIZATION and DISPATCH_CENTER
 * All required properties get mapped via helper functions
 */

import {
    ADD_DISPATCH_CENTER,
    ADD_DISPATCH_CENTERS,
    REMOVE_CENTER,
    UPDATE_CENTER,
    ADD_CENTER_SECRET,
    REMOVE_CENTER_SECRET,
    ADD_CENTER_CHAT_TEXT,
    DELETE_CENTER_CHAT_TEXT,
    CHANGE_CENTER_CHAT_TEXTS,
} from '../actionTypes';
import PropTypes from 'prop-types';

export const initialState = {
    secrets: [],
    centers: [],
};

/**
 * dispatch center reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const dispatchCentersReducer = (state = initialState, action) => {
    if (action.type === ADD_CENTER_SECRET) {
        return {
            ...state,
            secrets: [...state.secrets, action.secret],
        };
    }
    if (action.type === REMOVE_CENTER_SECRET) {
        return {
            ...state,
            secrets: state.secrets.filter(secret => secret.id !== action.id),
        };
    }
    if (action.type === ADD_DISPATCH_CENTER) {
        if (
            state.centers
                .map(center => {
                    return center.name.toLowerCase();
                })
                .indexOf(action.center.name.toLowerCase()) === -1
        ) {
            return { ...state, centers: [...state.centers, action.center] };
        }
    }
    if (action.type === ADD_DISPATCH_CENTERS) {
        return { secrets: action.secrets, centers: [...action.centers] };
    }
    if (action.type === REMOVE_CENTER) {
        return {
            ...state,
            centers: state.centers.filter(center => center.id !== action.id),
        };
    }
    if (action.type === UPDATE_CENTER) {
        return {
            ...state,
            centers: state.centers.map(center => {
                if (center.id === action.center.id) {
                    return { ...center, ...action.center };
                }
                return center;
            }),
        };
    }

    if (action.type === ADD_CENTER_CHAT_TEXT) {
        return {
            ...state,
            centers: state.centers.map(center => {
                if (center.id === action.id) {
                    return {
                        ...center,
                        chatBlocks: [...center.chatBlocks, action.text],
                    };
                }
                return center;
            }),
        };
    }

    if (action.type === DELETE_CENTER_CHAT_TEXT) {
        return {
            ...state,
            centers: state.centers.map(center => {
                if (center.id === action.id) {
                    return {
                        ...center,
                        chatBlocks: center.chatBlocks.filter(
                            chatBlock => chatBlock !== action.text
                        ),
                    };
                }
                return center;
            }),
        };
    }

    if (action.type === CHANGE_CENTER_CHAT_TEXTS) {
        return {
            ...state,
            centers: state.centers.map(center => {
                if (center.id === action.id) {
                    return {
                        ...center,
                        chatBlocks: [...action.texts],
                    };
                }
                return center;
            }),
        };
    }
    return state;
};

export const centerProptype = {
    name: PropTypes.string,
    id: PropTypes.number,
    addOnId: PropTypes.number,
    smsText: PropTypes.string,
    goodbyeMessage: PropTypes.string,
    ref: PropTypes.string,
    chatBlocks: PropTypes.arrayOf(PropTypes.string),
    centerRef: PropTypes.string,
};

export default dispatchCentersReducer;
