/**
 * dispatchCenters are a combination of the backend entities ORGANIZATION and DISPATCH_CENTER
 * All required properties get mapped via helper functions
 */

import {
    ADD_DISPATCH_CENTER,
    ADD_DISPATCH_CENTERS,
    SET_SORTED_CENTERS,
    SET_CENTERS_SORTING_PROPERTY,
    TOGGLE_CENTERS_SORTING_ORDER,
    SET_CENTER_CURRENT_PAGE,
    SET_PAGINATED_CENTERS,
    UPDATE_CENTER_FILTER,
    SET_FILTERED_CENTERS,
    TOGGLE_CENTER_FILTER_VISIBILITY,
    SET_CENTERS_FILTERED,
    RESET_CENTER_FILTER,
    REMOVE_CENTER,
    UPDATE_CENTER,
    ADD_CENTER_SECRET,
    REMOVE_CENTER_SECRET,
    ADD_CENTER_CHAT_TEXT,
    DELETE_CENTER_CHAT_TEXT,
    CHANGE_CENTER_CHAT_TEXTS,
    CLOSE_CENTER_FILTER,
} from '../actionTypes';
import {
    addOrganizationAPI,
    getOrganizationAPI,
    updateOrganizationAPI,
} from '../../api/organizationAPI';
import {
    mapDispatchCenters,
    getDispatchCenterSecrets,
    mergeCenter,
    usersWithOrganizationId,
    createSecret,
} from '../../api/apiHelper';
import store from '../store';
import {
    addDispatchCenterAPI,
    getDispatchCenterAPI,
    updateDispatchCenterAPI,
    deleteDispatchCenterAPI,
} from '../../api/dispatchCenterAPI';
import { replaceText } from '../../helper';
import reduxStore from '../store';

/**
 * All dispatch center actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const addDispatchCenter = center => {
    return { type: ADD_DISPATCH_CENTER, center };
};

const addCenterSecret = secret => {
    return { type: ADD_CENTER_SECRET, secret };
};

export const addDispatchCenterDispatch = async center => {
    const secret = createSecret();
    const organization = await addOrganizationAPI({ center, secret });

    const tempMerge = mergeCenter(organization, center);
    const dispatchAddon = await addDispatchCenterAPI(tempMerge);

    const mergedCenter = mergeCenter(organization, dispatchAddon);

    store.dispatch(addDispatchCenter(mergedCenter));
    store.dispatch(addCenterSecret({ secret, id: organization.id }));

    return dispatchAddon.id;
};

export const addDispatchCenterNameOnlyDispatch = async centerName => {
    addDispatchCenterDispatch({ name: centerName });
};

const addDispatchCenters = ({ centers, secrets }) => {
    return { type: ADD_DISPATCH_CENTERS, centers, secrets };
};

export const getDispatchCentersDispatch = async () => {
    const organizations = await getOrganizationAPI();
    const dispatchCenters = await getDispatchCenterAPI();

    const secrets = getDispatchCenterSecrets(organizations);

    const centers = mapDispatchCenters(organizations, dispatchCenters);
    store.dispatch(addDispatchCenters({ centers, secrets }));
};

const updateCenter = center => {
    return { type: UPDATE_CENTER, center };
};

export const updateCenterDispatch = async center => {
    const updatedOrganization = await updateOrganizationAPI(center);
    const updatedCenter = await updateDispatchCenterAPI(center);

    const mergedCenter = mergeCenter(updatedOrganization, updatedCenter);

    store.dispatch(updateCenter(mergedCenter));
};

const removeCenter = id => {
    return { type: REMOVE_CENTER, id };
};

const removeSecret = id => {
    return { type: REMOVE_CENTER_SECRET, id };
};

export const removeCenterDispatch = async (orgId) => {
    if (usersWithOrganizationId(orgId).length === 0) {
        await deleteDispatchCenterAPI(orgId);

        store.dispatch(removeCenter(orgId));
        store.dispatch(removeSecret(orgId));
    } else {
        const texts = reduxStore.getState().texts.texts;
        window.alert(replaceText(texts, 'dispatchcenter.deleteError'));
    }
};

// SORTING

const toggleDispatchCenterSortingOrder = () => {
    return { type: TOGGLE_CENTERS_SORTING_ORDER };
};

export const toggleDispatchCenterSortingOrderDispatch = () => {
    store.dispatch(toggleDispatchCenterSortingOrder());
};

const setDispatchCenterSortingProperty = sortingProperty => {
    return { type: SET_CENTERS_SORTING_PROPERTY, sortingProperty };
};

export const setDispatchCenterSortingPropertyDispatch = sortingProperty => {
    store.dispatch(setDispatchCenterSortingProperty(sortingProperty));
};

const setSortedDispatchCenters = sortedCenters => {
    return { type: SET_SORTED_CENTERS, sortedCenters };
};

export const setSortedDispatchCentersDispatch = sortedCenters => {
    store.dispatch(setSortedDispatchCenters(sortedCenters));
};

// PAGINATION

const setCenterCurrentPage = currentPage => {
    return { type: SET_CENTER_CURRENT_PAGE, currentPage };
};

export const setCenterCurrentPageDispatch = currentPage => {
    store.dispatch(setCenterCurrentPage(currentPage));
};

const setPaginatedCenters = paginatedCenters => {
    return { type: SET_PAGINATED_CENTERS, paginatedCenters };
};

export const setPaginatedCentersDispatch = paginatedCenters => {
    store.dispatch(setPaginatedCenters(paginatedCenters));
};

// FILTERING

const updateCenterFilter = filter => {
    return { type: UPDATE_CENTER_FILTER, filter };
};

export const updateCenterFilterDispatchThen = filter => {
    return new Promise(resolve => {
        store.dispatch(updateCenterFilter(filter));
        resolve();
    });
};

const setFilteredCenters = filteredCenters => {
    return { type: SET_FILTERED_CENTERS, filteredCenters };
};

export const setFilteredCentersDispatch = filteredCenters => {
    store.dispatch(setFilteredCenters(filteredCenters));
};

const toggleCenterFilterVisibility = () => {
    return { type: TOGGLE_CENTER_FILTER_VISIBILITY };
};

export const toggleCenterFilterVisibilityDispatch = () => {
    store.dispatch(toggleCenterFilterVisibility());
};

const closeCenterFilter = () => {
    return { type: CLOSE_CENTER_FILTER };
};

export const closeCenterFilterDispatch = () => {
    store.dispatch(closeCenterFilter());
};

const setCentersFiltered = isFiltered => {
    return { type: SET_CENTERS_FILTERED, isFiltered };
};

export const setCentersFilteredDispatch = isFiltered => {
    store.dispatch(setCentersFiltered(isFiltered));
};

const resetCenterFilters = () => {
    return { type: RESET_CENTER_FILTER };
};

export const resetCenterFiltersDispatch = () => {
    store.dispatch(resetCenterFilters());
};

// CHAT TEXTS
const addCenterChatText = (text, id) => {
    return { type: ADD_CENTER_CHAT_TEXT, text, id };
};

export const addCenterChatTextDispatch = (text, id) => {
    store.dispatch(addCenterChatText(text, id));
};

const deleteCenterChatText = (text, id) => {
    return { type: DELETE_CENTER_CHAT_TEXT, text, id };
};

export const deleteCenterChatTextDispatch = (text, id) => {
    store.dispatch(deleteCenterChatText(text, id));
};

const changeCenterChatTexts = (texts, id) => {
    return { type: CHANGE_CENTER_CHAT_TEXTS, texts, id };
};

export const changeCenterChatTextsDispatch = (texts, id) => {
    store.dispatch(changeCenterChatTexts(texts, id));
};
