import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    updateUserDispatch,
    removeUserDispatch,
} from '../../redux/actions/users';

import Edit from '../icons/Edit';
import Toggle from '../icons/Toggle';
import ToggleDeactivated from '../icons/ToggleDeactivated';
import Delete from '../icons/Delete';

import '../global/ListEntry.scss';
import { replaceText } from '../../helper';
import { ApplicationConfig, DispatchCenter, User } from '../../types';
import Checked from '../icons/Checked';

/**
 * User list entry with options to manipulate.
 */

interface Props {
    user: User;
    currentUser: {
        role: string;
        organization: {
            id: Number
        }
    };
    history: any;
    texts: { [key: string]: string };
    applicationConfigs: ApplicationConfig[];
    dispatchCenters: {
        centers: DispatchCenter[];
    };
}

type UserListEntryProps = ConnectedProps<typeof connector> & Props;

const UserListEntry: React.FC<UserListEntryProps> = ({
    user,
    texts,
    applicationConfigs,
    dispatchCenters,
    currentUser,
}) => {
    const [displayOrgStructures, setDisplayOrgStructures] = useState(false);
    const [displayDisclaimerCheck, setDisplayDisclaimerCheck] = useState(false);

    const history = useHistory();

    const edit = id => {
        history.push(`/edit/user/${id}`);
    };

    const toggleState = id => {
        updateUserDispatch({
            ...user,
            activated: !user.activated,
        });
    };

    const deleteUser = id => {
        const { name, email } = user;
        if (window.confirm(replaceText(texts, 'user.prompt', name))) {
            removeUserDispatch(id, email);
        }
    };

    const {
        name,
        email,
        activated,
        role,
        level1,
        level2,
        level3,
        dispatchCenter,
        disclaimerAccpeted,
        id,
    } = user;

    const userClass = clsx(
        'col col--name',
        activated ? 'col col--name--green' : 'col col--name--red'
    );

    useEffect(() => {
        if (
            dispatchCenters?.centers?.length !== 0 &&
            applicationConfigs?.length !== 0
        ) {
            const currentCenter = dispatchCenters.centers.find(
                center => center.organizationId === currentUser.organization.id
            );
            const currentConfig = applicationConfigs.find(
                config => config.dispatchCenterId === currentCenter.addOnId
            );
            if (currentUser.role === 'superadmin') {
                setDisplayDisclaimerCheck(true);
                setDisplayOrgStructures(true);
            } else {
                if (
                    currentConfig.enabledFeatures.indexOf(
                        'DISCLAIMER_DISPATCHER'
                    ) !== -1
                ) {
                    setDisplayDisclaimerCheck(true);
                }
                if (
                    currentConfig.enabledFeatures.indexOf('ORG_STRUCTURE') !==
                    -1
                ) {
                    setDisplayOrgStructures(true);
                }
            }
        }
    }, [
        dispatchCenters,
        applicationConfigs,
        currentUser
    ]);

    return (
        <div className="userListEntry">
            <div className={userClass} title={name}>
                {name}
            </div>
            <div className="col col--email" title={email}>
                {email}
            </div>
            {currentUser.role === 'superadmin' ? (
                <div className="col col--dispatchCenter" title={dispatchCenter}>
                    {dispatchCenter}
                </div>
            ) : (
                ''
            )}
            <div className="col col--role" title={role}>
                {replaceText(texts, `role.${role}`)}
            </div>
            {displayOrgStructures && (
                <>
                    <div className="col col--level1" title={level1}>
                        {level1}
                    </div>
                    <div className="col col--level2" title={level1}>
                        {level2}
                    </div>
                    <div className="col col--level3" title={level1}>
                        {level3}
                    </div>
                </>
            )}
            {displayDisclaimerCheck && (
                <div
                    className="col col--disclaimer"
                    title={''} // ! TODO
                >
                    {disclaimerAccpeted ? <Checked /> : ''}
                </div>
            )}
            <div className="col col--options userListEntry__buttons">
                <button
                    title={replaceText(texts, 'action.edit')}
                    onClick={() => edit(id)}>
                    <Edit />{' '}
                </button>
                <button
                    title={
                        activated
                            ? replaceText(texts, 'action.deactivate')
                            : replaceText(texts, 'action.activate')
                    }
                    onClick={() => toggleState(id)}
                    className={activated ? 'deactivate' : 'activate'}>
                    {activated ? <Toggle /> : <ToggleDeactivated />}{' '}
                </button>
                <button
                    title={replaceText(texts, 'action.delete')}
                    onClick={() => deleteUser(id)}>
                    <Delete />
                </button>
            </div>
        </div>
    );
};

// Map Redux State To Props
const mapStateToProps = (state: Props) => {
    return {
        currentUser: state.currentUser,
        texts: state.texts.texts,
        applicationConfigs: state.applicationConfigs,
        dispatchCenters: state.dispatchCenters,
    };
};

// Connect Props to Component
const connector = connect(mapStateToProps);
export default connector(UserListEntry);
