import PropTypes from 'prop-types';
import { OVERWRITE_INVITE_CONFIGS } from '../actionTypes';

export const initialState = [];

/**
 * invite config reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === OVERWRITE_INVITE_CONFIGS) {
        return [...action.configs];
    }
    return state;
};

export const inviteProptype = {
    id: PropTypes.number,
    goodbyeText: PropTypes.string,
    logo: PropTypes.string,
    messageSender: PropTypes.string,
    messageSubject: PropTypes.string,
    messageText: PropTypes.string,
    ref: PropTypes.string,
};

export default reducers;
