import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { centerProptype } from '../../redux/reducers/dispatchCenters';
import { isUniqueName } from '../../helper/validationHelper';
import {
    addDispatchCenterDispatch,
    updateCenterDispatch,
} from '../../redux/actions/dispatchCenters';

import './CenterForm.scss';
import CenterChatBlocksWrapper from './CenterChatBlocksWrapper';
import UploadForm from './UploadForm';
import { RTE_TOOLBAR } from '../../config';
import { applicationProptype } from '../../redux/reducers/applicationConfigs';
import { isValidSms, replaceText } from '../../helper';

import LanguageSelect from '../global/LanguageSelect';
import Organigram from './Organigram';
import { postOrganigramConfig } from '../../api/organigramAPI';

/**
 * Center form to edit or create a center.
 * Functions depend on the role of the center creating or editing.

 * @compnent CenterChatBlocksWrapper - Edit the chat blocks
 */

const CenterForm = ({
    centers,
    match,
    history,
    applicationConfigs,
    texts,
    currentOrganigramConfig,
}) => {
    const nameRef = useRef(null);
    // const ipRestrictionRef = useRef(null);
    const smsRef = useRef(null);

    const [error, setError] = useState({});
    const [name, setName] = useState('');
    const [ipRestriction, setIpRestriction] = useState('');
    const [smsText, setSmsText] = useState('');
    const [addOnId, setAddOnId] = useState(null);
    const [end, setEnd] = useState(RichTextEditor.createEmptyValue());
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const validateForm = () => {
        let hasFormError = false;
        const formError = {
            name: false,
            smsText: false,
            end: false,
            lang: false,
        };
        const id = parseInt(match.params.id);

        if (
            name === '' ||
            /[^a-zA-Z\d\s:ß_&()+-]/gi.test(name) ||
            !isUniqueName({
                name: name,
                centers: centers,
                id,
            })
        ) {
            hasFormError = true;
            formError.name = true;
        }

        if (
            smsText.indexOf('{0}') === -1 ||
            smsText === '' ||
            !isValidSms(smsText)
        ) {
            hasFormError = true;
            formError.smsText = true;
        }

        if (end.toString('markdown').length > 1000) {
            hasFormError = true;
            formError.end = true;
        }

        if (isNew) {
            if (selectedLanguage.length === 0) {
                hasFormError = true;
                formError.lang = true;
            }
        }

        if (hasFormError) {
            setError({
                ...error,
                ...formError,
            });
        } else {
            setError({});
        }

        return hasFormError;
    };

    const prepareSMSPlaceHolder = smsText => {
        switch (true) {
            // xxx{0}xxx
            case /\S\{0\}\S/.test(smsText):
                return smsText.replace('{0}', ' {0} ');
            // xxx{0} xxx
            case /\S\{0\}\s/.test(smsText):
                return smsText.replace('{0} ', ' {0} ');
            // xxx {0}xxx
            case /\s\{0\}\S/.test(smsText):
                return smsText.replace(' {0}', ' {0} ');
            // xxx{0}
            case /\S\{0\}$/.test(smsText):
                return smsText.replace('{0}', ' {0}');
            // {0}xxx
            case /^\{0\}\S/.test(smsText):
                return smsText.replace('{0}', '{0} ');
            default:
                return smsText;
        }
    };

    const save = async (e, id) => {
        e.preventDefault();

        if (validateForm()) {
            return;
        }

        const data = {
            name,
            ipRestriction,
            smsText: prepareSMSPlaceHolder(smsText),
            end: end.toString('markdown'),
            language: selectedLanguage,
        };

        try {
            if (typeof id === 'number' && !Number.isNaN(id)) {
                const center = centers.filter(center => center.id === id)[0];
                const updatedCenter = {
                    ...center,
                    ...data,
                };

                await updateCenterDispatch(updatedCenter);
            } else {
                const newCenter = {
                    ...data,
                };
                const newCenterAddOnId = await addDispatchCenterDispatch(
                    newCenter
                );

                Object.keys(currentOrganigramConfig).forEach(level => {
                    currentOrganigramConfig[level].forEach(item => {
                        postItem(newCenterAddOnId, item, level);
                    });
                });
            }

            history.push(`/center`);
        } catch (error) {
            console.error(error);
        }
    };

    const postItem = (newCenterAddOnId, item, level) => {
        return postOrganigramConfig(newCenterAddOnId, item.name, level);
    };

    const updateInput = () => {
        setName(nameRef.current.value);
        setSmsText(smsRef.current.value);
    };

    const updateSmsText = () => {
        const smsInputValue = smsRef.current.value;

        if (
            smsText.indexOf('{0}') === -1 ||
            smsText === '' ||
            !isValidSms(smsText)
        ) {
            setError({
                ...error,
                smsText: true,
            });
        } else {
            setSmsText(smsInputValue);
            setError({
                ...error,
                smsText: false,
            });
        }
    };

    const updateInputRichText = value => {
        setEnd(value);
    };

    const handleLanguageSelection = selection => {
        setSelectedLanguage(selection);
    };

    useEffect(() => {
        const id = parseInt(match.params.id);

        if (id) {
            const center = centers.filter(center => center.id === id)[0];

            if (center && center.name) setName(center.name);
            if (center && center.ipRestriction)
                setIpRestriction(center.ipRestriction);
            if (center && center.smsText) setSmsText(center.smsText);
            if (center && center.language) setSelectedLanguage(center.language);
            if (center && center.addOnId) setAddOnId(center.addOnId);
            if (center && center.end)
                setEnd(
                    center.end
                        ? RichTextEditor.createValueFromString(
                              center.end,
                              'markdown'
                          )
                        : RichTextEditor.createEmptyValue()
                );
        }
    }, [centers, match]);

    const id = parseInt(match.params.id);
    const isNew = typeof id !== 'number' || Number.isNaN(id);

    let logoUploadFeatureEnabled = false;
    let isOrganigramFeatureEnabled = false;
    let bidiFeatureEnabled = false;

    if (!isNew && addOnId) {
        const currentConfig = applicationConfigs.filter(
            config => config.dispatchCenterId === addOnId
        )[0];
        if (currentConfig) {
            logoUploadFeatureEnabled =
                currentConfig.enabledFeatures.indexOf('LOGO_UPLOAD') !== -1;
            isOrganigramFeatureEnabled =
                currentConfig.enabledFeatures.indexOf('ORG_STRUCTURE') !== -1;
            bidiFeatureEnabled = currentConfig.enabledFeatures.indexOf('BIDI') !== -1;
        }
    }

    return (
        <form
            autoComplete="off"
            className="centerForm"
            onChange={updateInput}
            onSubmit={e => save(e, id)}>
            <h2>{replaceText(texts, 'center.general')}</h2>
            <div className={clsx({ 'has-error': error.name })}>
                <label htmlFor="name">
                    <h3>{replaceText(texts, 'name')}</h3>
                </label>
                <div className="centerForm__row">
                    <input
                        id="name"
                        type="text"
                        ref={nameRef}
                        value={name}
                        onChange={() => {}}
                        maxLength={255}
                    />
                    <span className="hint">
                        {replaceText(texts, 'max.chars', 255)}
                    </span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'name.error')}
                </span>
            </div>
            {isOrganigramFeatureEnabled && (
                <div>
                    <label htmlFor="organigram">
                        <h3>{replaceText(texts, 'organigram', 255)}</h3>
                    </label>
                    <div className="centerForm__row">
                        <Organigram />
                    </div>
                </div>
            )}
            <div className={clsx({ 'has-error': error.smsText })}>
                <label htmlFor="sms">
                    <h3>{replaceText(texts, 'sms.text')}</h3>
                </label>
                <div className="centerForm__row">
                    <textarea
                        id="sms"
                        type="text"
                        ref={smsRef}
                        placeholder={replaceText(texts, 'sms.placeholder')}
                        value={smsText}
                        onChange={() => {}}
                        onKeyUp={updateSmsText}
                        maxLength={255}></textarea>{' '}
                    <span>
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 255)}
                        </span>
                        {replaceText(texts, 'sms.info')}
                    </span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'sms.error')}
                </span>
            </div>
            {logoUploadFeatureEnabled && (
                <div>
                    <h3>{replaceText(texts, 'logo')}</h3>
                    <div className="centerForm__row">
                        <UploadForm type="centerLogo" />
                        <span>
                            <span className="hint">
                                {replaceText(texts, 'max.size', 2000)}
                            </span>

                            {replaceText(texts, 'logo.hint')}
                        </span>
                    </div>
                </div>
            )}
            {bidiFeatureEnabled && (
                <div>
                    <h3>{replaceText(texts, 'feature.BIDI')}</h3>
                    <div className="centerForm__row">
                        <UploadForm type="backgroundImage" />
                        <span>
                            <span className="hint">
                                {replaceText(texts, 'max.size', 5000)}
                            </span>
                            {replaceText(texts, 'video.background.hint')}
                        </span>
                    </div>
                </div>
            )}
            <div className={clsx({ 'has-error': error.lang })}>
                <h3>{replaceText(texts, 'language.select')}</h3>
                <div className="language-select">
                    <LanguageSelect
                        id="dispatch-center-create-button"
                        language={selectedLanguage}
                        selectLanguageHandler={handleLanguageSelection}
                    />
                </div>
                <span className="error-message">
                    {replaceText(texts, 'lang.error')}
                </span>
            </div>
            <div className={clsx({ 'has-error': error.end })}>
                <label htmlFor="end">
                    <h3>{replaceText(texts, 'sessionend')}</h3>
                </label>
                <div className="centerForm__row">
                    <RichTextEditor
                        toolbarConfig={RTE_TOOLBAR}
                        id="end"
                        readOnly={false}
                        value={end || RichTextEditor.createEmptyValue()}
                        placeholder={replaceText(
                            texts,
                            'sessionend.placeholder'
                        )}
                        onChange={updateInputRichText}></RichTextEditor>{' '}
                    <span>
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 1000)}
                        </span>

                        {replaceText(texts, 'sessionend.hint')}
                    </span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'sessionend.error')}
                </span>
            </div>
            {!isNew && <CenterChatBlocksWrapper centerId={id} />}
            <div>
                <button type="submit" className="btn btn--primary">
                    {isNew
                        ? replaceText(texts, 'action.newcenter')
                        : replaceText(texts, 'action.save.changes')}
                </button>
            </div>
        </form>
    );
};

// PropTypes for this Component
CenterForm.propTypes = {
    centers: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    match: PropTypes.any,
    history: PropTypes.any,
    applicationConfigs: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    texts: PropTypes.object,
    currentOrganigramConfig: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        centers: state.dispatchCenters.centers,
        applicationConfigs: state.applicationConfigs,
        texts: state.texts.texts,
        currentOrganigramConfig: state.currentOrganigramConfig,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(connect(mapStateToProps)(CenterForm));
