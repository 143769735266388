import {
    UPDATE_CENTER_FILTER,
    SET_FILTERED_CENTERS,
    TOGGLE_CENTER_FILTER_VISIBILITY,
    SET_CENTERS_FILTERED,
    RESET_CENTER_FILTER,
    CLOSE_CENTER_FILTER,
} from '../actionTypes';

export const initialState = {
    filterProperties: {
        name: '',
        ipRestriction: '',
    },
    filteredCenters: [],
    isFiltered: false,
    open: false,
};

/**
 * dispatch centers filters reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === UPDATE_CENTER_FILTER) {
        return {
            ...state,
            filterProperties: {
                ...state.filterProperties,
                [action.filter.property]: action.filter.value,
            },
        };
    }
    if (action.type === SET_FILTERED_CENTERS) {
        return {
            ...state,
            filteredCenters: action.filteredCenters,
        };
    }
    if (action.type === RESET_CENTER_FILTER) {
        return {
            ...state,
            filterProperties: { ...initialState.filterProperties },
        };
    }
    if (action.type === SET_CENTERS_FILTERED) {
        return {
            ...state,
            isFiltered: action.isFiltered,
        };
    }
    if (action.type === TOGGLE_CENTER_FILTER_VISIBILITY) {
        return {
            ...state,
            open: !state.open,
        };
    }
    if (action.type === CLOSE_CENTER_FILTER) {
        return {
            ...state,
            open: false,
        };
    }
    return state;
};

export default reducers;
