import PropTypes from 'prop-types';
import { OVERWRITE_SESSION_REPORT_CONFIGS } from '../actionTypes';

export const initialState = [];

/**
 * invite config reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === OVERWRITE_SESSION_REPORT_CONFIGS) {
        return [...action.configs];
    }
    return state;
};

export const sessionReportProptype = {
    id: PropTypes.number,
    hideUserName: PropTypes.bool,
    hideTargetNumber: PropTypes.bool,
    hideLocation: PropTypes.bool,
    hidePhotos: PropTypes.bool,
    hideChat: PropTypes.bool,
    hideLog: PropTypes.bool,
    hideNotes: PropTypes.bool, 
    hideRecordings: PropTypes.bool,
    hideFileUploads: PropTypes.bool,
    _links: PropTypes.object,
};

export default reducers;
