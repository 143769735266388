import React from 'react';

function Add() {
    return (
        <svg
            data-name="Plus Icon"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30">
            <circle data-name="Ellipse 291" cx="15" cy="15" r="15" />
            <g
                data-name="Group 4461"
                fill="none"
                stroke="#fafafa"
                strokeLinecap="round"
                strokeWidth="2.5">
                <path data-name="Path 3000" d="M15 9v12" />
                <path data-name="Path 3001" d="M21 15H9" />
            </g>
        </svg>
    );
}

export default Add;
