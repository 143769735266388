import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/index';

/* eslint-disable no-underscore-dangle */
const store = configureStore(
    {
        reducer: rootReducer /* preloadedState, */,
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */

export default store;
