import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { removeCenterDispatch } from '../../redux/actions/dispatchCenters';

import Edit from '../icons/Edit';
import Delete from '../icons/Delete';

import '../global/ListEntry.scss';
import './CentersListEntry.scss';
import { centerProptype } from '../../redux/reducers/dispatchCenters';
import { replaceText } from '../../helper';

/**
 * Center list entry with options to manipulate.
 */
class CentersListEntry extends PureComponent {
    edit = id => {
        this.props.history.push(`/edit/center/${id}`);
    };

    delete = (id, addOnId) => {
        const { name } = this.props.center;
        if (
            window.confirm(replaceText(this.props.texts, 'center.prompt', name))
        ) {
            removeCenterDispatch(id, addOnId);
        }
    };

    render() {
        const { name, id, ipRestriction, end, smsText, addOnId } =
            this.props.center;
        const currentRole = this.props.currentRole;
        let chatBlocks = this.props.center.chatBlocks
            ? this.props.center.chatBlocks.join(', ')
            : '';

        return (
            <div className="centersListEntry">
                <div className="col col--name" title={name}>
                    {name}
                </div>
                <div className="col col--ip" title={ipRestriction}>
                    {ipRestriction}
                </div>
                <div className="col col--sms" title={smsText}>
                    {smsText}
                </div>
                <div className="col col--end" title={end}>
                    {end}
                </div>
                <div className="col col--chatblocks" title={chatBlocks}>
                    {chatBlocks}
                </div>

                <div className="col col--options centersListEntry__buttons">
                    <button onClick={() => this.edit(id)}>
                        <Edit />{' '}
                    </button>
                    {currentRole === 'superadmin' && (
                        <button onClick={() => this.delete(id, addOnId)}>
                            <Delete />
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
CentersListEntry.propTypes = {
    center: PropTypes.shape(centerProptype),
    currentRole: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentRole: state.currentUser.role,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default withRouter(connect(mapStateToProps)(CentersListEntry));
