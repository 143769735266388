import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { applicationProptype } from '../../redux/reducers/applicationConfigs';
import { replaceText } from '../../helper';
import ToggleSwitch from '../global/ToggleSwitch';
import { withRouter } from 'react-router-dom';
import './SessionReportView.scss';
import {
    patchSessionReportConfig,
    postSessionReportConfig,
} from '../../api/sessionReportAPI';
import {
    getSessionReportConfigsDispatch,
    setCurrentSessionReportDispatch,
} from '../../redux/actions';
import { centerProptype } from '../../redux/reducers/dispatchCenters';

const SessionReportView = ({
    centers,
    match,
    texts,
    currentSessionReportConfig,
    applicationConfigs,
    currentCenter,
}) => {
    const sessionReportEntries = [
        'hideUserName',
        'hideTargetNumber',
        'hideLocation',
        'hidePhotos',
        'hideChat',
        'hideLog',
        'hideNotes',
        'hideRecordings',
        'hideFileUploads',
    ];

    const id = parseInt(match.params.id);
    const center = centers.filter(center => center.id === id)[0];
    const addOnId = center.addOnId;
    const currentConfig = applicationConfigs.find(
        config => config.dispatchCenterId === currentCenter.addOnId
    );

    const changeEntry = async (entry, isActive) => {
        await patchSessionReportConfig(
            currentSessionReportConfig.id,
            entry,
            !isActive
        );
        await setCurrentSessionReportDispatch(addOnId);
        await getSessionReportConfigsDispatch();
    };

    useEffect(() => {
        if (!currentSessionReportConfig.id) {
            const data = {
                addOnId: currentConfig.dispatchCenterId,
                hideUserName: false,
                hideTargetNumber: false,
                hideLocation: false,
                hidePhotos: false,
                hideChat: false,
                hideLog: false,
                hideNotes: false,
                hideRecordings: false,
                hideFileUploads: false,
            };
            postSessionReportConfig(data).then(() => {
                setCurrentSessionReportDispatch(currentConfig.dispatchCenterId);
            });
        }
    }, [currentConfig, currentSessionReportConfig.id]);

    return (
        <div className="edit-session-report">
            <h2>{replaceText(texts, 'session.report.heading')}</h2>
            <p>{replaceText(texts, 'session.report.info')}</p>
            {sessionReportEntries.map(entry => {
                let isActive = null;
                Object.entries(currentSessionReportConfig).map(currentEntry => {
                    // If entry value matches currentEntry key, declare isActive as value (bool) of currentEntry key
                    if (entry === currentEntry[0]) isActive = currentEntry[1];
                    return null;
                });

                return (
                    <div className="centerFeatures__toggleWrapper" key={entry}>
                        <ToggleSwitch
                            // The negative of isActive will be displayed
                            isActive={!isActive}
                            label={replaceText(
                                texts,
                                `report.show.${entry
                                    .replace('hide', '')
                                    .toLowerCase()}`
                            )}
                            id={entry}
                            changeHandler={() => {
                                changeEntry(entry, isActive);
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

// PropTypes for this Component
SessionReportView.propTypes = {
    applicationConfigs: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    currentCenter: PropTypes.shape(centerProptype),
    centers: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    match: PropTypes.any,
    texts: PropTypes.object,
    currentSessionReportConfig: PropTypes.object,
};

// Map Redux State to Props
const mapStateToProps = (state, ownProps) => {
    return {
        applicationConfigs: state.applicationConfigs,
        currentCenter: state.dispatchCenters.centers.find(
            center => center.id === Number(ownProps.match.params.id)
        ),
        centers: state.dispatchCenters.centers,
        texts: state.texts.texts,
        currentSessionReportConfig: state.currentSessionReportConfig,
    };
};

export default withRouter(connect(mapStateToProps)(SessionReportView));
