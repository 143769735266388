import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend as Backend } from 'react-dnd-html5-backend';
import CenterChatBlocks from './CenterChatBlocks';
import PropTypes from 'prop-types';

/**
 * Wrapper for the center chat blocks
 * @component DndProvider - Drag & Drop Provider
 * @component CenterChatBlocks - center chat blocks
 */
export default function CenterChatBlocksWrapper({ centerId }) {
    return (
        <DndProvider backend={Backend}>
            <CenterChatBlocks centerId={centerId} />
        </DndProvider>
    );
}

CenterChatBlocksWrapper.propTypes = {
    centerId: PropTypes.number,
};
