import {
    RESET_CURRENT_ORGANIGRAM_CONFIG,
    SET_CURRENT_ORGANIGRAM_CONFIG,
} from '../actionTypes';

export const initialState = { 1: [], 2: [], 3: [] };

/**
 * current invite config reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const currentOrganigramConfigReducer = (state = initialState, action) => {
    if (action.type === SET_CURRENT_ORGANIGRAM_CONFIG) {
        return {
            ...state,
            ...action.currentOrganigramConfig,
        };
    }

    if (action.type === RESET_CURRENT_ORGANIGRAM_CONFIG) {
        return initialState;
    }

    return state;
};

export default currentOrganigramConfigReducer;
