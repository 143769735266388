import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import clsx from 'clsx';

import './Navigation.scss';
import { replaceText } from '../../helper';

/**
 * Application navigation depending on the user role
 */

class Navigation extends PureComponent {
    // forces a reload of route component
    navigateToDummyRouteAndBack = path => {
        const { history, match } = this.props;

        if (match.path === path) {
            // Navigate to a dummy route
            setTimeout(() => {
                history.push('/dummy');
            }, 50);

            // Navigate back
            setTimeout(() => {
                // Navigate back to /user
                history.push(path);
            }, 100);
        }
    };
    render() {
        return (
            <nav className="navigation">
                <Link
                    className={clsx('btn btn--primary', {
                        'btn--active': this.props.match.path === '/user',
                    })}
                    to="/user"
                    onClick={() => this.navigateToDummyRouteAndBack('/user')}>
                    {replaceText(this.props.texts, 'user')}
                </Link>
                <Link
                    className={clsx('btn btn--primary', {
                        'btn--active': this.props.match.path === '/center',
                    })}
                    to="/center"
                    onClick={() => this.navigateToDummyRouteAndBack('/center')}>
                    {replaceText(this.props.texts, 'center')}
                </Link>
                {this.props.currentRole === 'superadmin' && (
                    <Link
                        className={clsx('btn btn--primary', {
                            'btn--active':
                                this.props.match.path === '/messages',
                        })}
                        to="/messages">
                        {replaceText(this.props.texts, 'messages')}
                    </Link>
                )}
            </nav>
        );
    }
}

// PropTypes for this Component
Navigation.propTypes = {
    currentRole: PropTypes.string,
    match: PropTypes.any,
    texts: PropTypes.object,
    history: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentRole: state.currentUser.role,
        texts: state.texts.texts,
    };
};

export default withRouter(connect(mapStateToProps)(Navigation));
