import {
    SET_SORTED_CENTERS,
    SET_CENTERS_SORTING_PROPERTY,
    TOGGLE_CENTERS_SORTING_ORDER,
} from '../actionTypes';

export const initialState = {
    sortingOrder: 'DESC',
    sortingProperty: 'name',
    sortedCenters: [],
};

/**
 * dispatch centers sorting reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducer = (state = initialState, action) => {
    if (action.type === SET_CENTERS_SORTING_PROPERTY) {
        return {
            ...state,
            sortingProperty: action.sortingProperty,
            sortingOrder: 'DESC',
        };
    }
    if (action.type === TOGGLE_CENTERS_SORTING_ORDER) {
        return {
            ...state,
            sortingOrder: state.sortingOrder === 'DESC' ? 'ASC' : 'DESC',
        };
    }
    if (action.type === SET_SORTED_CENTERS) {
        return {
            ...state,
            sortedCenters: action.sortedCenters,
        };
    }

    return state;
};

export default reducer;
