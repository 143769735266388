import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logout } from '../OAuth';
import { postLogout } from '../api/api';
import { connect } from 'react-redux';
import { replaceText } from '../helper';

/**
 * Logout button to log the current user out of the admin ui.
 */
class LogoutButton extends React.Component {
    state = {
        logout: false,
    };

    handleClick = () => {
        postLogout().then(() => {
            logout();
        });
        this.setState({ logout: true });
    };

    render() {
        if (this.state.logout) {
            return <Redirect to={'/login'} />;
        }
        return (
            <button className="btn btn--secondary" onClick={this.handleClick}>
                {replaceText(this.props.texts, 'logout')}
            </button>
        );
    }
}

// PropTypes for this Component
LogoutButton.propTypes = {
    match: PropTypes.any,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(LogoutButton);
