import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import {
    updateUserDispatch,
    addUserDispatch,
    updateUserOrganigramLevelsDispatch,
} from '../../redux/actions/users';

import { userProptypes } from '../../redux/reducers/users';
import { applicationProptype } from '../../redux/reducers/applicationConfigs';

import Dropdown from '../global/Dropdown';
import DropDownContent from '../global/DropDownContent';

import { roles, emailRegex } from '../../config';
import { getPasswordHash } from '../../api/api';
import { isUniqueMail } from '../../helper/validationHelper';

import './UserForm.scss';

import {
    getUserConfiguration,
    postUserConfiguration,
    putUserOrgStructure,
} from '../../api/userConfigurationAPI';
import { isValidIpChars, replaceText } from '../../helper';
import UserOrganigram from './UserOrganigram';
import { getUserAPI, sendPasswordChangeInvite } from '../../api/userAPI';

/**
 * User form to edit or create a user.
 * Functions depend on the role of the user creating or editing.
 *
 * @component Dropdown - custom dropdown
 * @component DropdownContent - dropdown content wrapper
 */

class UserForm extends PureComponent {
    static DEFINE_PASSWORD = 'password.assignment.defined';
    static INVITE_PASSWORD_CHANGE = 'password.assignment.invite';

    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            passwordChanged: false,
            password: '',
            passwordRepeat: '',
            passwordHash: '',
            passwordAssignmentMethod: [],
            passwordChangeDisabled: false,
            needsPasswordReset: false,
            name: '',
            email: '',
            ipRestriction: '',
            role: 'user',
            roleLabel: 'Nutzer',
            preventPasswordUpdate: false,
            dispatchCenter: replaceText(this.props.texts, 'dropdown.default'),
            level1: '',
            level1Name: '',
            level2: '',
            level2Name: '',
            level3: '',
            level3Name: '',
            error: {},
            needsPasswordInvitation: false,
            fromAzure: false,
        };

        this.nameRef = React.createRef();
        this.emailRef = React.createRef();
        this.ipRef = React.createRef();
    }

    validateForm = () => {
        let hasError = false;
        const error = {};
        const id = parseInt(this.props.match.params.id);

        if (this.state.name === '') {
            hasError = true;
            error.name = true;
        } else {
            error.name = false;
        }

        if (
            this.state.email === '' ||
            !emailRegex.test(this.state.email.toLowerCase()) ||
            !isUniqueMail({
                email: this.state.email,
                users: this.props.users,
                id,
            })
        ) {
            hasError = true;
            error.email = true;
        } else {
            error.email = false;
        }

        if (
            this.state.dispatchCenter === 'Bitte wählen' ||
            this.state.dispatchCenter === '' ||
            typeof this.state.dispatchCenter === 'undefined'
        ) {
            hasError = true;
            error.dispatchCenter = true;
        } else {
            error.dispatchCenter = false;
        }

        if (
            this.state.passwordChanged &&
            this.state.password === '' &&
            !this.state.needsPasswordReset &&
            !this.state.needsPasswordInvitation
        ) {
            hasError = true;
            error.passwordEmpty = true;
        } else {
            error.passwordEmpty = false;
        }

        // PASSWORD NOT EQAUL
        if (this.state.password !== this.state.passwordRepeat) {
            hasError = true;
            error.passwordRepeat = true;
        } else {
            error.passwordRepeat = false;
        }

        if (hasError) {
            this.setState({
                error: {
                    ...this.state.error,
                    ...error,
                },
            });
        } else {
            this.setState({ error: {} });
        }
        return hasError;
    };

    save = async (e, id) => {
        e.preventDefault();

        if (this.validateForm()) {
            return;
        }

        let password = this.state.passwordHash;

        let data = {
            name: this.state.name,
            email: this.state.email,
            role: this.state.role,
            ipRestriction: this.state.ipRestriction,
            organization: { name: this.state.dispatchCenter },
            needsPasswordReset: this.state.needsPasswordReset,
            passwordChangeDisabled: this.state.passwordChangeDisabled,
        };

        if (this.state.passwordChanged && !this.state.needsPasswordInvitation) {
            password = await getPasswordHash(this.state.password);
            data = { ...data, password };
        }

        try {
            if (typeof id === 'number' && !Number.isNaN(id)) {
                const user = this.props.users.filter(user => user.id === id)[0];
                const updatedUser = {
                    ...user,
                    ...data,
                };

                await updateUserDispatch(updatedUser);

                let postUserConfigRef = await getUserConfiguration(user.email);

                if (
                    typeof postUserConfigRef === 'undefined' ||
                    postUserConfigRef === null
                ) {
                    await postUserConfiguration({
                        userID: user.email,
                        language: 'de',
                    });
                }

                if (postUserConfigRef) {
                    this.overwriteOrganigramUserConfig(postUserConfigRef);
                }

                if (this.state.needsPasswordInvitation) {
                    await sendPasswordChangeInvite(this.state.email);
                }
            } else {
                if (this.state.needsPasswordInvitation) {
                    data = { ...data, needsPasswordReset: true };
                }
                const newUser = {
                    ...data,
                    activated: true,
                };
                // check if user exists in db
                const response = await addUserDispatch(newUser);
                if (response && response.status === 409) {
                    this.setState({
                        error: { email: true },
                    });
                    return;
                } else {
                    this.setState({
                        error: { email: false },
                    });
                }
                if (
                    this.state.passwordAssignmentMethod[0] ===
                    UserForm.INVITE_PASSWORD_CHANGE
                ) {
                    await sendPasswordChangeInvite(this.state.email);
                }

                if (this.props.dispatchCenters) {
                    const filteredDispatchCenter =
                        this.props.dispatchCenters.filter(
                            center => center.name === data.organization.name
                        );

                    if (
                        filteredDispatchCenter[0].language !== null &&
                        filteredDispatchCenter[0].language.length !== 0
                    ) {
                        await postUserConfiguration({
                            userID: newUser.email,
                            language: filteredDispatchCenter[0].language,
                        });
                    } else if (
                        !filteredDispatchCenter[0].language ||
                        filteredDispatchCenter[0].language.length === 0 ||
                        filteredDispatchCenter[0].language === null
                    ) {
                        await postUserConfiguration({
                            userID: newUser.email,
                            language: 'de',
                        });
                    }
                }

                if (
                    this.state.level1 !==
                        replaceText(this.props.texts, 'dropdown.default') ||
                    this.state.level2 !==
                        replaceText(this.props.texts, 'dropdown.default') ||
                    this.state.level3 !==
                        replaceText(this.props.texts, 'dropdown.default')
                ) {
                    let postUserConfigRef = await getUserConfiguration(
                        newUser.email
                    );
                    this.overwriteOrganigramUserConfig(postUserConfigRef);
                }
            }

            this.props.history.push(`/user`);
        } catch (error) {
            console.error(error);
        }
    };

    updateInput = () => {
        this.setState({
            name: this.nameRef.current.value,
            ipRestriction: this.ipRef.current.value,
        });
        if (!this.state.editMode) {
            this.setState({
                email: this.emailRef.current.value,
            });
        }
    };

    updateIpRestriction = () => {
        const ipInputValue = this.ipRef.current.value;

        if (isValidIpChars(ipInputValue)) {
            this.setState({
                error: {
                    ...this.state.error,
                    ipRestriction: false,
                },
            });
        } else {
            this.setState({
                error: {
                    ...this.state.error,
                    ipRestriction: true,
                },
            });
        }
    };

    selectPasswordAssignmentMethod = e => {
        this.setState({
            passwordAssignmentMethod: [e.value, e.label],
        });
        if (e.value === UserForm.INVITE_PASSWORD_CHANGE) {
            this.setState({
                needsPasswordInvitation: true,
                passwordChangeDisabled: false,
            });
        } else {
            this.setState({
                needsPasswordInvitation: false,
            });
        }
    };

    passwordChange = e => {
        this.setState({ passwordChanged: true, password: e.target.value });
    };

    passwordRepeatChange = e => {
        this.setState({
            passwordChanged: true,
            passwordRepeat: e.target.value,
        });
    };

    selectRole = ({ value, label }) => {
        this.setState({ roleLabel: label, role: value });
    };

    setOrgLevelById = (orgLevel, orgItemId, orgName) => {
        if (orgLevel === 1) {
            this.setState({ level1: orgItemId, level1Name: orgName });
        }
        if (orgLevel === 2) {
            this.setState({ level2: orgItemId, level2Name: orgName });
        }
        if (orgLevel === 3) {
            this.setState({ level3: orgItemId, level3Name: orgName });
        }
    };

    selectDispatchCenterExternally = label => {
        this.setState({
            dispatchCenter: label,
        });
    };

    selectDispatchCenter = ({ label }) => {
        this.setState({
            dispatchCenter: label,
        });
    };

    setIpRestriction = id => {
        const ipRestriction = this.props.dispatchCenters.filter(
            center => center.id === id
        )[0].ipRestriction;
        if (this.state.ipRestriction === '' || !this.state.ipRestriction)
            this.setState({ ipRestriction });
    };

    getSelectedDispatcherId = label => {
        let currentCenter = this.props.dispatchCenters.filter(center => {
            return label === center.name;
        });

        return currentCenter[0].addOnId;
    };

    overwriteOrganigramUserConfig = async postUserConfigRef => {
        const id = parseInt(this.props.match.params.id);
        let orgItemsArray = [];
        let levels = {};

        if (this.state.level1Name !== undefined) {
            levels.level1 = this.state.level1Name;
        }
        if (
            this.state.level1.length !== 0 &&
            this.state.level1 !==
                replaceText(this.props.texts, 'dropdown.default')
        ) {
            orgItemsArray.push(this.state.level1);
        }

        if (this.state.level2Name !== undefined) {
            levels.level2 = this.state.level2Name;
        }
        if (
            this.state.level2.length !== 0 &&
            this.state.level2 !==
                replaceText(this.props.texts, 'dropdown.default')
        ) {
            orgItemsArray.push(this.state.level2);
        }

        if (this.state.level3Name !== undefined) {
            levels.level3 = this.state.level3Name;
        }
        if (
            this.state.level3.length !== 0 &&
            this.state.level3 !==
                replaceText(this.props.texts, 'dropdown.default')
        ) {
            orgItemsArray.push(this.state.level3);
        }

        updateUserOrganigramLevelsDispatch(id, levels);

        await putUserOrgStructure(postUserConfigRef, orgItemsArray);
    };

    async componentDidMount() {
        const id = parseInt(this.props.match.params.id);
        const noSuperAdmin = this.props.currentUserRole !== 'superadmin';

        if (noSuperAdmin) {
            this.setState({
                dispatchCenter: this.props.currentUserOrganization.name,
            });
        }

        if (id) {
            // merge user objects
            const userProps = await getUserAPI(id, false);
            const userPropsWithUseProjection = this.props.users.filter(
                user => user.id === id
            )[0];
            const user = { ...userProps, ...userPropsWithUseProjection };

            this.setState({
                editMode: true,
                name: user.name,
                email: user.email,
                role: user.role,
                ipRestriction: user.ipRestriction,
                roleLabel: replaceText(this.props.texts, `role.${user.role}`),
                dispatchCenter: user.organization.name,
                passwordChanged: false,
                passwordHash: user.password,
                password: '             ',
                passwordRepeat: '             ',
                needsPasswordReset: user.needsPasswordReset
                    ? user.needsPasswordReset
                    : false,
                passwordChangeDisabled: user.passwordChangeDisabled
                    ? user.passwordChangeDisabled
                    : false,
                lastPasswordChange: user.lastPasswordChange
                    ? user.lastPasswordChange
                    : '',
                fromAzure: user.fromAzure ? user.fromAzure : false,
            });
        } else {
            this.setState({
                passwordChanged: true,
            });
        }

        if (noSuperAdmin && !id) {
            this.setIpRestriction(this.props.currentUserOrganization.id);
        }
    }

    render() {
        const passwordAssignmentOptions =
            this.state.dispatchCenter ===
            this.props.currentUserOrganization.name
                ? [
                      {
                          label: replaceText(
                              this.props.texts,
                              UserForm.DEFINE_PASSWORD
                          ),
                          id: UserForm.DEFINE_PASSWORD,
                      },
                      {
                          label: replaceText(
                              this.props.texts,
                              UserForm.INVITE_PASSWORD_CHANGE
                          ),
                          id: UserForm.INVITE_PASSWORD_CHANGE,
                      },
                  ]
                : [
                      {
                          label: replaceText(
                              this.props.texts,
                              UserForm.DEFINE_PASSWORD
                          ),
                          id: UserForm.DEFINE_PASSWORD,
                      },
                  ];

        const id = parseInt(this.props.match.params.id);

        const isNew = typeof id !== 'number' || Number.isNaN(id);

        const isSuperadmin = this.props.currentUserRole === 'superadmin';

        let isOrganigramFeatureEnabled = false;

        if (!isNew) {
            const organizationOfSelectedUser = this.props.users.filter(
                user => user.id === id
            );

            if (organizationOfSelectedUser) {
                const dispatchCenterOfSelectedUser =
                    this.props.dispatchCenters.filter(
                        center =>
                            center.id ===
                            organizationOfSelectedUser[0].organization.id
                    );

                const currentConfig = this.props.applicationConfigs.filter(
                    config =>
                        config.dispatchCenterId ===
                        dispatchCenterOfSelectedUser[0].addOnId
                )[0];

                if (currentConfig) {
                    isOrganigramFeatureEnabled =
                        currentConfig.enabledFeatures.indexOf(
                            'ORG_STRUCTURE'
                        ) !== -1;
                }
            }
        }

        let isAPI = false;

        let availableRoles = [
            {
                label: replaceText(this.props.texts, `role.user`),
                id: roles.user.scope,
            },
            {
                label: replaceText(this.props.texts, `role.admin`),
                id: roles.admin.scope,
            },
        ];

        let dispatchCenters = this.props.dispatchCenters.map(center => ({
            label: center.name,
            id: center.id,
        }));

        let sortedDispatchCenters = dispatchCenters.sort((a, b) =>
            a.label.localeCompare(b.label)
        );

        if (!isNew) {
            const user = this.props.users.filter(user => user.id === id)[0];
            isAPI = user.role === 'api';
        }

        if (isSuperadmin) {
            availableRoles.push({
                label: replaceText(this.props.texts, `role.superadmin`),
                id: roles.superadmin.scope,
            });
        }

        const classes = clsx('userForm', { 'userForm--api': isAPI });

        return (
            <form
                autoComplete="off"
                className={classes}
                onChange={this.updateInput}
                onSubmit={e => this.save(e, id)}>
                <div className={clsx({ 'has-error': this.state.error.name })}>
                    <label htmlFor="name">
                        <h3>{replaceText(this.props.texts, 'name')}</h3>
                    </label>

                    <input
                        id="name"
                        type="text"
                        ref={this.nameRef}
                        value={this.state.name}
                        onChange={() => {}}
                        data-lpignore="true"
                        maxLength={255}
                    />

                    <span className="hint">
                        {replaceText(this.props.texts, 'max.chars', 255)}
                    </span>
                    <span className="error-message">
                        {replaceText(this.props.texts, 'name.error.empty')}
                    </span>
                </div>
                <div className={clsx({ 'has-error': this.state.error.email })}>
                    {!this.state.editMode && (
                        <React.Fragment>
                            <label htmlFor="email">
                                <h3>
                                    {replaceText(this.props.texts, 'email')}
                                </h3>
                            </label>
                            {isAPI ? (
                                <input
                                    id="email"
                                    type="text"
                                    ref={this.emailRef}
                                    value={this.state.email}
                                    disabled
                                    data-lpignore="true"
                                    maxLength={255}
                                />
                            ) : (
                                <input
                                    id="email"
                                    type="text"
                                    ref={this.emailRef}
                                    value={this.state.email}
                                    onChange={() => {}}
                                    data-lpignore="true"
                                    maxLength={255}
                                />
                            )}
                            <span className="hint">
                                {replaceText(
                                    this.props.texts,
                                    'max.chars',
                                    255
                                )}
                            </span>
                            <span className="error-message">
                                {replaceText(this.props.texts, 'email.error')}
                            </span>
                        </React.Fragment>
                    )}
                </div>

                {isSuperadmin && (
                    <div className="dispatchConfig">
                        <div
                            className={clsx({
                                'has-error': this.state.error.dispatchCenter,
                            })}>
                            <label>
                                <h3>
                                    {replaceText(this.props.texts, 'center')}
                                </h3>
                            </label>
                            <Dropdown
                                id="user-dispatch-center-dropdown"
                                label={this.state.dispatchCenter}
                                clickHandler={this.selectDispatchCenter}>
                                <DropDownContent
                                    items={sortedDispatchCenters}
                                />
                            </Dropdown>
                            <span className="error-message">
                                {replaceText(this.props.texts, 'center.select')}
                            </span>
                        </div>
                    </div>
                )}

                <div className="passwordAssignmentConfig">
                    <div
                        className={clsx({
                            'has-error':
                                this.state.error.passwordAssignmentConfig,
                        })}>
                        <label>
                            <h3>
                                {replaceText(
                                    this.props.texts,
                                    'password.assignment'
                                )}
                            </h3>
                        </label>
                        <div
                            title={
                                this.state.fromAzure
                                    ? replaceText(
                                          this.props.texts,
                                          'user.azure.noPasswordChanging'
                                      )
                                    : ''
                            }>
                            <Dropdown
                                id="user-password-assignment-dropdown"
                                label={this.state.passwordAssignmentMethod[1]}
                                clickHandler={
                                    this.selectPasswordAssignmentMethod
                                }
                                disabled={this.state.fromAzure}>
                                <DropDownContent
                                    items={passwordAssignmentOptions}
                                />
                            </Dropdown>
                        </div>
                        <span className="error-message">
                            {replaceText(
                                this.props.texts,
                                'password.assignment.select'
                            )}
                        </span>
                    </div>
                    {this.state.passwordAssignmentMethod.length !== 0 &&
                    UserForm.INVITE_PASSWORD_CHANGE !==
                        this.state.passwordAssignmentMethod[0] ? (
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={!this.state.passwordChangeDisabled}
                                    onChange={() => {}}
                                    onClick={() => {
                                        this.setState({
                                            passwordChangeDisabled:
                                                !this.state
                                                    .passwordChangeDisabled,
                                        });
                                    }}></input>
                                <span>
                                    {replaceText(
                                        this.props.texts,
                                        'password.assignment.checkbox'
                                    )}
                                </span>
                            </label>
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                {this.state.passwordAssignmentMethod[0] ===
                UserForm.DEFINE_PASSWORD ? (
                    <div
                        className={clsx('passwordConfig', {
                            'has-error':
                                this.state.error.passwordRepeat ||
                                this.state.error.passwordEmpty,
                        })}>
                        <div>
                            <label htmlFor="password">
                                <h3>
                                    {replaceText(this.props.texts, 'password')}
                                </h3>
                            </label>
                            <input
                                id="password"
                                type="password"
                                value={this.state.password}
                                onChange={this.passwordChange}
                                data-lpignore="true"
                                maxLength={255}
                            />
                            <span className="hint">
                                {replaceText(
                                    this.props.texts,
                                    'max.chars',
                                    255
                                )}
                            </span>
                        </div>
                        <div>
                            <label htmlFor="passwordRepeat">
                                <h3>
                                    {replaceText(
                                        this.props.texts,
                                        'password.repeat'
                                    )}
                                </h3>
                            </label>
                            <input
                                id="passwordRepeat"
                                type="password"
                                value={this.state.passwordRepeat}
                                onChange={this.passwordRepeatChange}
                                data-lpignore="true"
                                maxLength={255}
                            />
                            <span className="hint">
                                {replaceText(
                                    this.props.texts,
                                    'max.chars',
                                    255
                                )}
                            </span>
                        </div>
                        <span className="error-message">
                            {replaceText(this.props.texts, 'password.error')}
                        </span>
                        <input type="hidden" value={this.state.passwordHash} />
                    </div>
                ) : (
                    ''
                )}

                {isOrganigramFeatureEnabled && (
                    <UserOrganigram
                        currentDispatchCenter={this.state.dispatchCenter}
                        selectDispatchCenterExternally={
                            this.selectDispatchCenterExternally
                        }
                        setOrgLevelById={this.setOrgLevelById}
                    />
                )}

                <div className="roleSelect">
                    <label>
                        <h3>{replaceText(this.props.texts, 'role')}</h3>
                    </label>
                    <Dropdown
                        id="user-role-dropdown"
                        label={this.state.roleLabel}
                        clickHandler={this.selectRole}>
                        <DropDownContent items={availableRoles} />
                    </Dropdown>
                </div>
                <div
                    className={clsx({
                        'has-error': this.state.error.ipRestriction,
                    })}>
                    <label htmlFor="ipRestriction">
                        <h3>
                            {replaceText(this.props.texts, 'ip.restriction')}
                        </h3>
                    </label>
                    <div className="userForm__row">
                        <textarea
                            onChange={this.updateIpRestriction}
                            ref={this.ipRef}
                            id="ipRestriction"
                            value={this.state.ipRestriction || ''}
                            maxLength={255}></textarea>
                        <span>
                            <span className="hint">
                                {replaceText(
                                    this.props.texts,
                                    'max.chars',
                                    255
                                )}
                            </span>
                            {replaceText(this.props.texts, 'ip.hint.1')}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://regex101.com/">
                                regex101.com
                            </a>
                            <br />
                            {replaceText(this.props.texts, 'ip.hint.2')}
                        </span>
                        <span className="error-message">
                            {replaceText(this.props.texts, 'ip.error')}
                        </span>
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn btn--primary"
                        disabled={
                            isNew &&
                            this.state.passwordAssignmentMethod.length === 0
                        }>
                        {isNew
                            ? replaceText(this.props.texts, 'action.newuser')
                            : replaceText(
                                  this.props.texts,
                                  'action.save.changes'
                              )}
                    </button>
                </div>
            </form>
        );
    }
}

// PropTypes for this Component
UserForm.propTypes = {
    edit: PropTypes.bool,
    users: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
    currentUserRole: PropTypes.string,
    currentUserOrganization: PropTypes.any,
    currentOrganigramConfig: PropTypes.object,
    dispatchCenters: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
            ref: PropTypes.string,
        })
    ),
    applicationConfigs: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    history: PropTypes.any,
    match: PropTypes.any,
    texts: PropTypes.object,
};

UserForm.defaultProps = {
    edit: false,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        users: state.users,
        currentUserRole: state.currentUser.role,
        currentUserOrganization: state.currentUser.organization,
        currentOrganigramConfig: state.currentOrganigramConfig,
        dispatchCenters: state.dispatchCenters.centers,
        applicationConfigs: state.applicationConfigs,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(connect(mapStateToProps)(UserForm));
