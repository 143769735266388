import store from '../store';

import {
    SET_CURRENT_USER,
    REMOVE_CURRENT_USER,
    SAVE_SYSTEM_MESSAGE,
    SAVE_SYSTEM_FOOTER,
    ADD_USER_SCOPES,
    ADD_APPLICATIONS,
    ADD_API_TOKEN,
    ADD_USER,
    REMOVE_API_TOKEN,
    OVERWRITE_APPLICATION_CONFIGS,
    SET_LANGUAGE,
    SET_TEXTS,
    OVERWRITE_INVITE_CONFIGS,
    SET_CURRENT_INVITE_CONFIG,
    RESET_CURRENT_INVITE_CONFIG,
    SET_CURRENT_SESSION_REPORT_CONFIG,
    RESET_CURRENT_SESSION_REPORT_CONFIG,
    OVERWRITE_SESSION_REPORT_CONFIGS,
    SET_CURRENT_ORGANIGRAM_CONFIG,
    RESET_CURRENT_ORGANIGRAM_CONFIG,
    SET_DISCLAIMER_DISPATCHER,
    SET_DISCLAIMER_CALLER,
    SET_DISCLAIMER_DISPATCHER_ENABLED,
    SET_DISCLAIMER_CALLER_ENABLED,
    SET_CONFIRMATION_ENABLED_FOR_DISPATCHER,
    SET_CONFIRMATION_TEXT,
    RESET_DISCLAIMER_SETTINGS,
} from '../actionTypes.js';
import {
    getScopesAPI,
    updateSystemHealth,
    getSystemHealth,
    updateWelcomeMessage,
    getWelcomeMessage,
    getApplicationsAPI,
    generateAPIUser,
} from '../../api/api';
import {
    mapCurrentUser,
    mapScopes,
    getWelcomeMessageAt,
    getRelevantApplications,
    mapUser,
    mapApplicationConfigs,
    mapInviteConfigs,
    mapSessionReportConfigs,
} from '../../api/apiHelper';
import { getUserAPI } from '../../api/userAPI';
import {
    getApplicationConfigsAPI,
    addApplicationConfigAPI,
    updateApplicationConfigAPI,
} from '../../api/applicationConfig';
import { setLanguageForUser } from '../../api/translations';
import { getAllInviteConfigs, getInviteConfig } from '../../api/conferenceAPI';
import {
    getAllSessionReportConfigs,
    getSessionReportConfig,
} from '../../api/sessionReportAPI';
import {
    getUserConfiguration,
    postUserConfiguration,
} from '../../api/userConfigurationAPI';

/**
 * All remaining actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

// CURRENT USER

const setCurrentUser = currentUser => {
    return { type: SET_CURRENT_USER, currentUser };
};

export const setCurrentUserDispatch = user => {
    const currentUser = mapCurrentUser(user);
    store.dispatch(setCurrentUser(currentUser));
};

const removeCurrentUser = () => {
    return { type: REMOVE_CURRENT_USER };
};

export const removeCurrentUserDispatch = () => {
    store.dispatch(removeCurrentUser());
};

// SCOPES

const addUserScopes = scopes => {
    return { type: ADD_USER_SCOPES, scopes };
};

export const getUserScopesDispatch = async () => {
    const scopes = await getScopesAPI();
    const mappedScopes = mapScopes(scopes);
    store.dispatch(addUserScopes(mappedScopes));
};

// APPLICATIONS

const addApplications = applications => {
    return { type: ADD_APPLICATIONS, applications };
};

export const getApplicationsDispatch = async () => {
    const applications = await getApplicationsAPI();
    const relevantApplications = getRelevantApplications(applications);
    store.dispatch(addApplications(relevantApplications));
};

// APPLICATION CONFIGS

const addApplicationConfigs = configs => {
    return { type: OVERWRITE_APPLICATION_CONFIGS, configs };
};

export const getApplicationConfigsDispatch = async () => {
    const configs = await getApplicationConfigsAPI();
    const mappedConfigs = mapApplicationConfigs(configs);
    store.dispatch(addApplicationConfigs(mappedConfigs));
};

export const addApplicationConfigDispatch = async ({
    features,
    currentCenter,
}) => {
    await addApplicationConfigAPI({ features, center: currentCenter });
    await getApplicationConfigsDispatch();
};

export const updateApplicationConfigDispatch = async ({
    features,
    currentConfig,
    currentCenter,
}) => {
    await updateApplicationConfigAPI({
        features,
        center: currentCenter,
        config: currentConfig,
    });
    await getApplicationConfigsDispatch();
};

// SYSTEM MESSAGES

const saveSystemMessage = (digit, messageBlock) => {
    return { type: SAVE_SYSTEM_MESSAGE, digit, messageBlock };
};

export const saveSystemMessageDispatch = async ({ digit, messageBlock }) => {
    const result = await updateWelcomeMessage({
        ...messageBlock,
        order: digit,
        id: store.getState().messages.messageBlocks[digit]
            ? store.getState().messages.messageBlocks[digit].id
            : null,
    });
    store.dispatch(saveSystemMessage(digit, result));
};

export const getSystemMessageDispatch = async () => {
    const results = await getWelcomeMessage();
    store.dispatch(saveSystemMessage(0, getWelcomeMessageAt(results, 0)));
    store.dispatch(saveSystemMessage(1, getWelcomeMessageAt(results, 1)));
    store.dispatch(saveSystemMessage(2, getWelcomeMessageAt(results, 2)));
};

const saveSystemFooter = ({ tooltip, health, id }) => {
    return { type: SAVE_SYSTEM_FOOTER, tooltip, health, id };
};

export const saveSystemFooterDispatch = async ({ tooltip, health }) => {
    const { text, status, id } = await updateSystemHealth({
        text: tooltip,
        status: health,
        id: store.getState().messages.messageFooter.id,
    });

    store.dispatch(saveSystemFooter({ id, tooltip: text, health: status }));
};

export const getSystemFooterDispatch = async () => {
    const { text, status, id } = await getSystemHealth();
    store.dispatch(saveSystemFooter({ id, tooltip: text, health: status }));
};

// API USER

const addAPIToken = apiToken => {
    return { type: ADD_API_TOKEN, apiToken };
};

const addAPIUser = user => {
    return { type: ADD_USER, user };
};

export const addAPIUserDispatch = async () => {
    const apiUserObject = await generateAPIUser();

    const apiUser = await getUserAPI(apiUserObject.id);
    if ((await getUserConfiguration(apiUser.email)) === null) {
        await postUserConfiguration({
            userID: apiUser.email,
            language: 'de',
        });
    }

    const mappedApiUser = mapUser(apiUser);

    store.dispatch(addAPIToken(apiUserObject.token));
    store.dispatch(addAPIUser(mappedApiUser));
};

const removeAPIToken = () => {
    return { type: REMOVE_API_TOKEN };
};

export const removeAPITokenDispatch = () => {
    store.dispatch(removeAPIToken());
};

// TEXTS
const setLanguage = language => {
    return {
        type: SET_LANGUAGE,
        language,
    };
};

export const setLanguageDispatch = language => {
    store.dispatch(setLanguage(language));
};

export const changeLanuage = async language => {
    try {
        await setLanguageForUser(language);
        setLanguageDispatch(language);
    } catch (e) {
        console.log(e);
    }
};

const setTexts = texts => {
    return {
        type: SET_TEXTS,
        texts,
    };
};

export const setTextsDispatch = texts => {
    store.dispatch(setTexts(texts));
};

// INVITE CONFIGS
const addInviteConfigs = configs => {
    return { type: OVERWRITE_INVITE_CONFIGS, configs };
};

export const getInviteConfigsDispatch = async () => {
    const configs = await getAllInviteConfigs();
    const mappedConfigs = mapInviteConfigs(configs);
    store.dispatch(addInviteConfigs(mappedConfigs));
};

// CURRENT INVITE CONFIG

const setCurrentInviteConfig = currentInviteConfig => {
    return { type: SET_CURRENT_INVITE_CONFIG, currentInviteConfig };
};

export const setCurrentInviteConfigDispatch = async id => {
    if (id) {
        const inviteConfig = await getInviteConfig(id);
        if (inviteConfig) {
            store.dispatch(setCurrentInviteConfig(inviteConfig));
        }
    }
};

const resetCurrentInviteConfig = () => {
    return { type: RESET_CURRENT_INVITE_CONFIG };
};

export const resetCurrentInviteConfigDispatch = () => {
    store.dispatch(resetCurrentInviteConfig());
};

// SESSION REPORT CONFIGS
const addSessionReportConfigs = configs => {
    return { type: OVERWRITE_SESSION_REPORT_CONFIGS, configs };
};

export const getSessionReportConfigsDispatch = async () => {
    const configs = await getAllSessionReportConfigs();
    const mappedConfigs = mapSessionReportConfigs(configs);
    store.dispatch(addSessionReportConfigs(mappedConfigs));
};

// SESSION REPORT CONFIG

const setCurrentSessionReportConfig = currentSessionReportConfig => {
    return {
        type: SET_CURRENT_SESSION_REPORT_CONFIG,
        currentSessionReportConfig,
    };
};

export const setCurrentSessionReportDispatch = async id => {
    if (id) {
        const sessionReportConfig = await getSessionReportConfig(id);
        if (sessionReportConfig) {
            store.dispatch(setCurrentSessionReportConfig(sessionReportConfig));
        }
    }
};

const resetCurrentSessionReportConfig = () => {
    return { type: RESET_CURRENT_SESSION_REPORT_CONFIG };
};

export const resetCurrentSessionReportDispatch = () => {
    store.dispatch(resetCurrentSessionReportConfig());
};

// CURRENT ORGANIGRAM CONFIG

const setCurrentOrganigramConfig = currentOrganigramConfig => {
    return { type: SET_CURRENT_ORGANIGRAM_CONFIG, currentOrganigramConfig };
};

export const setCurrentOrganigramConfigDispatch = data => {
    store.dispatch(setCurrentOrganigramConfig(data));
};

const resetCurrentOrganigramConfig = () => {
    return { type: RESET_CURRENT_ORGANIGRAM_CONFIG };
};

export const resetCurrentOrganigramConfigDispatch = () => {
    store.dispatch(resetCurrentOrganigramConfig());
};

// DISCLAIMER CONFIG

const setDisclaimerDispatcher = disclaimerTextDispatcher => {
    return { type: SET_DISCLAIMER_DISPATCHER, disclaimerTextDispatcher };
};

export const setDisclaimerDispatch = disclaimerTextDispatcher => {
    store.dispatch(setDisclaimerDispatcher(disclaimerTextDispatcher));
};

const setConfirmationText = confirmationText => {
    return { type: SET_CONFIRMATION_TEXT, confirmationText };
};

export const setConfirmationTextDispatch = confirmationText => {
    store.dispatch(setConfirmationText(confirmationText));
};

const setDisclaimerCaller = disclaimerTextBystander => {
    return { type: SET_DISCLAIMER_CALLER, disclaimerTextBystander };
};

export const setDisclaimerCallerDispatch = disclaimerTextBystander => {
    store.dispatch(setDisclaimerCaller(disclaimerTextBystander));
};

const setDisclaimerDispatcherEnabled = enabledForDispatcher => {
    return { type: SET_DISCLAIMER_DISPATCHER_ENABLED, enabledForDispatcher };
};

export const setDisclaimerDispatcherEnabledDispatch = enabledForDispatcher => {
    store.dispatch(setDisclaimerDispatcherEnabled(enabledForDispatcher));
};

const setDisclaimerCallerEnabled = enabledForBystander => {
    return { type: SET_DISCLAIMER_CALLER_ENABLED, enabledForBystander };
};

export const setDisclaimerCallerEnabledDispatch = enabledForBystander => {
    store.dispatch(setDisclaimerCallerEnabled(enabledForBystander));
};

const setConfirmationEnabledForDispatcher =
    confirmationEnabledForDispatcher => {
        return {
            type: SET_CONFIRMATION_ENABLED_FOR_DISPATCHER,
            confirmationEnabledForDispatcher,
        };
    };

export const setConfirmationEnabledForDispatcherDispatch =
    confirmationEnabledForDispatcher => {
        store.dispatch(
            setConfirmationEnabledForDispatcher(
                confirmationEnabledForDispatcher
            )
        );
    };

export const resetDisclaimerSettings = () => {
    return { type: RESET_DISCLAIMER_SETTINGS };
};

export const resetDisclaimerSettingsDispatch = () => {
    store.dispatch(resetDisclaimerSettings());
};
