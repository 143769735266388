import { DEFAULT_MIMETYPE } from '../config';
import { oauth } from '../OAuth';
import { restCall } from './api';

const mimeTypeAPI = process.env.REACT_APP_MIMETYPE_ENDPOINT;

export const getMimeTypeAPI = async (id = null) => {
    return await fetchMimeTypeAPI({}, id);
};

export const postMimeTypeAPI = async (value, id = null) => {
    return await fetchMimeTypeAPI({ method: 'POST' }, id, value);
};

const fetchMimeTypeAPI = async (params = {}, id = null, value = null) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = mimeTypeAPI;
    if (id && !value) {
        url = `${url}?dispatchCenterId=${id}`;
    }
    if (value && !id) {
        url = `${url}?value=${value}`;
    }
    if (id && value) {
        url = `${url}?dispatchCenterId=${id}&value=${value}`;
    }

    const result = await restCall(url, mergedParams, false);
    if (result) {
        return await result.text();
    }
    return DEFAULT_MIMETYPE;
};
