import { DisclaimerConfiguration } from '../../types';
import {
    SET_CONFIRMATION_ENABLED_FOR_DISPATCHER,
    SET_CONFIRMATION_TEXT,
    SET_DISCLAIMER_CALLER,
    SET_DISCLAIMER_CALLER_ENABLED,
    SET_DISCLAIMER_DISPATCHER,
    SET_DISCLAIMER_DISPATCHER_ENABLED,
    RESET_DISCLAIMER_SETTINGS,
} from '../actionTypes';

const initialState: DisclaimerConfiguration = {
    confirmationText: '',
    disclaimerTextDispatcher: '',
    disclaimerTextBystander: '',
    enabledForBystander: false,
    enabledForDispatcher: false,
    confirmationEnabledForDispatcher: false,
};

const disclaimer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISCLAIMER_DISPATCHER:
            return {
                ...state,
                disclaimerTextDispatcher: action.disclaimerTextDispatcher,
            };
        case SET_DISCLAIMER_CALLER:
            return {
                ...state,
                disclaimerTextBystander: action.disclaimerTextBystander,
            };
        case SET_CONFIRMATION_TEXT:
            return {
                ...state,
                confirmationText: action.confirmationText,
            };
        case SET_CONFIRMATION_ENABLED_FOR_DISPATCHER:
            return {
                ...state,
                confirmationEnabledForDispatcher:
                    action.confirmationEnabledForDispatcher,
            };
        case SET_DISCLAIMER_DISPATCHER_ENABLED:
            return {
                ...state,
                enabledForDispatcher: action.enabledForDispatcher,
            };
        case SET_DISCLAIMER_CALLER_ENABLED:
            return {
                ...state,
                enabledForBystander: action.enabledForBystander,
            };
        case RESET_DISCLAIMER_SETTINGS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default disclaimer;
