import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import clsx from 'clsx';

import {
    toggleUserSortingOrderDispatch,
    setUserSortingPropertyDispatch,
    setSortedUsersDispatch,
} from '../../redux/actions/users';

import Caret from '../icons/Caret';
import './UserSorting.scss';
import '../global/Sorting.scss';
import { replaceText } from '../../helper';
import { ApplicationConfig, DispatchCenter, User } from '../../types';
import { exportUsersAPI } from '../../api/userAPI';
import { createCsvFile } from '../../helper/createCSV';

//TODO: Add explicit types for applicationCofnigs and currentDispatchCenter
interface UserSortingProps {
    users: User[];
    sortingProperty: string;
    sortingOrder: string;
    texts: { [key: string]: string };
    currentRole: string;
    applicationConfigs: ApplicationConfig[];
    dispatchCenters: DispatchCenter[];
    currentOrganizationId: Number;
}

/**
 * Table headers for the user list entries contains the possibility to sort the list.
 */
const UserSorting: React.FC<UserSortingProps> = ({
    users,
    sortingProperty,
    sortingOrder,
    texts,
    currentRole,
    applicationConfigs,
    dispatchCenters,
    currentOrganizationId,
}) => {
    const [displayOrgStructuresColumns, setDisplayOrgStructuresColumns] =
        useState(false);
    const [displayDisclaimerColumn, setDisplayDisclaimerColumn] =
        useState(false);

    const sortUsers = useCallback(() => {
        const sortedUsers = [...users].sort((a, b) => {
            let valueA = a[sortingProperty];
            let valueB = b[sortingProperty];

            if (valueA === null || valueB === null) return 0;
            else if (sortingProperty === 'disclaimerAccpeted') {
                valueA = valueA ? 1 : 0;
                valueB = valueB ? 1 : 0;
            } else {
                valueA = valueA.toLowerCase();
                valueB = valueB.toLowerCase();
            }
            if (valueA > valueB) return sortingOrder === 'DESC' ? 1 : -1;
            if (valueA < valueB) return sortingOrder === 'DESC' ? -1 : 1;
            return 0;
        });

        setSortedUsersDispatch([...sortedUsers]);
    }, [users, sortingProperty, sortingOrder]);

    const clickHandler = property => {
        if (property === sortingProperty) {
            toggleUserSortingOrderDispatch();
        } else {
            setUserSortingPropertyDispatch(property);
        }
    };

    useEffect(() => {
        sortUsers();
    }, [sortUsers]);

    const downloadCSV = async () => {
        const result = await exportUsersAPI(true);
        createCsvFile(result, 'export.csv');
    };

    const classes = clsx(
        'userSorting',
        `userSorting--${sortingOrder}`,
        `userSorting--${sortingProperty}`
    );

    useEffect(() => {
        if (dispatchCenters.length !== 0 && applicationConfigs.length !== 0) {
            const currentCenter = dispatchCenters.find(
                center => center.organizationId === currentOrganizationId
            );
            const currentConfig = applicationConfigs.find(
                config => config.dispatchCenterId === currentCenter.addOnId
            );
            if (currentRole === 'superadmin') {
                setDisplayDisclaimerColumn(true);
                setDisplayOrgStructuresColumns(true);
            } else {
                if (
                    currentConfig.enabledFeatures.indexOf(
                        'DISCLAIMER_DISPATCHER'
                    ) !== -1
                ) {
                    setDisplayDisclaimerColumn(true);
                }
                if (
                    currentConfig.enabledFeatures.indexOf('ORG_STRUCTURE') !==
                    -1
                ) {
                    setDisplayOrgStructuresColumns(true);
                }
            }
        }
    }, [dispatchCenters, applicationConfigs, currentOrganizationId, currentRole]);

    return (
        <div className={classes}>
            <div
                className="col col--name"
                onClick={() => {
                    clickHandler('name');
                }}>
                <span title={'Name'}>{replaceText(texts, 'name')}</span>{' '}
                <Caret />
            </div>
            <div
                className="col col--email"
                onClick={() => {
                    clickHandler('email');
                }}>
                <span title={'Passwort'}>{replaceText(texts, 'email')}</span>{' '}
                <Caret />
            </div>
            {currentRole === 'superadmin' && (
                <div
                    className="col col--dispatchCenter"
                    onClick={() => {
                        clickHandler('dispatchCenter');
                    }}>
                    <span title={'Dispatch Center'}>
                        {replaceText(texts, 'center')}
                    </span>{' '}
                    <Caret />
                </div>
            )}
            <div
                className="col col--role"
                onClick={() => {
                    clickHandler('role');
                }}>
                <span title={'Rolle'}>{replaceText(texts, 'role')}</span>{' '}
                <Caret />
            </div>
            {displayOrgStructuresColumns && (
                <>
                    <div
                        className="col col--level1"
                        onClick={() => {
                            clickHandler('level1');
                        }}>
                        <span title={'Level-1'}>
                            {replaceText(texts, 'Level-1')}
                        </span>{' '}
                        <Caret />
                    </div>
                    <div
                        className="col col--level2"
                        onClick={() => {
                            clickHandler('level2');
                        }}>
                        <span title={'Level-2'}>
                            {replaceText(texts, 'Level-2')}
                        </span>{' '}
                        <Caret />
                    </div>
                    <div
                        className="col col--level3"
                        onClick={() => {
                            clickHandler('level3');
                        }}>
                        <span title={'Level-3'}>
                            {replaceText(texts, 'Level-3')}
                        </span>{' '}
                        <Caret />
                    </div>
                </>
            )}
            {displayDisclaimerColumn && (
                <div
                    className="col col--disclaimer"
                    onClick={() => {
                        clickHandler('disclaimerAccpeted');
                    }}>
                    <span title={'Disclaimer'}>
                        {replaceText(texts, 'disclaimer')}
                    </span>{' '}
                    <Caret />
                </div>
            )}
            <div className="col col--options">
                <button className="btn btn--primary" onClick={downloadCSV}>
                    {' '}
                    {replaceText(texts, 'export.csv')}{' '}
                </button>
            </div>
        </div>
    );
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        sortingOrder: state.userSorting.sortingOrder,
        sortingProperty: state.userSorting.sortingProperty,
        currentRole: state.currentUser.role,
        users: state.users,
        texts: state.texts.texts,
        applicationConfigs: state.applicationConfigs,
        dispatchCenters: state.dispatchCenters.centers,
        currentOrganizationId: state.currentUser.organization.id,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(UserSorting);
