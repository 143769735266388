import React from 'react';

function Delete() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <path d="m85.28,24.98h-10.53l-2.99-3c-.79-.78-1.85-1.22-2.95-1.22h-17.62c-1.11,0-2.16.45-2.95,1.22l-2.99,2.99h-10.54c-2.33,0-4.21,1.89-4.21,4.21s1.89,4.21,4.21,4.21h50.57c2.33,0,4.21-1.89,4.21-4.21s-1.89-4.21-4.21-4.21h0Zm-37.21,36.2c-1.62-1.66-1.59-4.32.06-5.94,1.63-1.6,4.24-1.6,5.88,0l5.98,5.94,5.94-5.94c1.66-1.62,4.32-1.59,5.94.06,1.6,1.63,1.6,4.24,0,5.88l-5.94,5.94,5.94,5.94c1.62,1.66,1.59,4.32-.06,5.94-1.63,1.6-4.24,1.6-5.88,0l-5.94-5.94-5.94,5.94c-1.62,1.66-4.28,1.69-5.94.06-1.66-1.62-1.69-4.28-.06-5.94.02-.02.04-.04.06-.06l5.94-5.94-5.98-5.94Zm-13.36,27.01c.01,4.65,3.78,8.41,8.43,8.43h33.71c4.65-.01,8.42-3.78,8.43-8.43v-50.57h-50.57v50.57Z"/>
        </svg>
    );
}

export default Delete;
