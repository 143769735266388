import { ADD_APPLICATIONS } from '../actionTypes';

export const initialState = [];

/**
 * applications reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === ADD_APPLICATIONS) {
        return [...action.applications];
    }
    return state;
};

export default reducers;
