import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

/**
 * Private route guard
 * checks for authentication and user role
 * redirects if not authenticated
 */

const PrivateRoute = ({
    component: Component,
    role,
    userRole,
    isLoggedIn,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (isLoggedIn) {
                    if (
                        userRole.indexOf(role) !== -1 ||
                        typeof role === 'undefined'
                    ) {
                        return <Component {...props} {...rest} />;
                    } else {
                        return <Redirect to={{ pathname: '/user' }} />;
                    }
                }
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                        }}
                    />
                );
            }}
        />
    );
};

// PropTypes for this Component
PrivateRoute.propTypes = {
    rest: PropTypes.any,
    component: PropTypes.any,
    isLoggedIn: PropTypes.bool,
    userRole: PropTypes.string,
    role: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        isLoggedIn: state.currentUser.isLoggedIn,
        userRole: state.currentUser.role,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(PrivateRoute);
