import { oauth } from '../OAuth';
import { restCall } from './api';
import { mapContact } from './apiHelper';

const dispatchCenterContactsAPIUrl =
    process.env.REACT_APP_DISPATCHCENTERS_ENDPOINT;

const contactsAPIUrl = process.env.REACT_APP_CONTACTS_ENDPOINT;

/**
 * get contacts for a dispatch centers
 * @returns {object} response data
 */
export const getDispatchCenterContactsAPI = async centerId => {
    return await fetchDispatchCenterContactsAPI({}, centerId);
};

/**
 * add a new contact
 * @param {object} data - name, phone and role of the contact
 * @returns {object} response data
 */
export const addContactAPI = async ({ data }) => {
    return await fetchContactsAPI({
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * edit an existing contact
 * @param {object} data - name, phone and role of the contact
 * @returns {object} response data
 */
export const editContactAPI = async ({ data, contactId }) => {
    return await fetchContactsAPI(
        {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        contactId
    );
};

/**
 * delete an existing contact
 * @param {number} contactId - id of the current dispatch center
 */
export const deleteContactAPI = async ({ contactId }) => {
    const mergedParams = {
        withCredentials: true,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    let url = `${contactsAPIUrl}/${contactId}`;

    await restCall(url, mergedParams, false);
};

/**
 * query the contact api
 * @param {object} params
 * @param {string} contactId
 * @returns {object} response data
 */
const fetchContactsAPI = async (params = {}, contactId = null) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = contactsAPIUrl;
    if (contactId) {
        url = `${url}/${contactId}`;
    }

    const result = await restCall(url, mergedParams);
    const transformedResult = mapContact(result);

    return transformedResult;
};

/**
 * query the dispatch center api and get all contacts
 * @param {object} params
 * @param {string} centerId
 * @returns {object} response data
 */
const fetchDispatchCenterContactsAPI = async (params = {}, centerId) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = `${dispatchCenterContactsAPIUrl}/${centerId}/contacts`;

    const result = await restCall(url, mergedParams);

    if (!result.page) {
        if (result._embedded) {
            const contacts = result._embedded.contacts;
            const transformedContacts = contacts.map(contact =>
                mapContact(contact)
            );
            return transformedContacts;
        }
        return result;
    }

    // get all results if there are more pages
    const limit = result.page.totalElements;
    const dispatchCentersContactsResults = await restCall(
        `${url}?limit=${limit}`,
        mergedParams
    );

    const contacts = dispatchCentersContactsResults._embedded.contacts;
    const transformedContacts = contacts.map(contact => mapContact(contact));
    return transformedContacts;
};
