import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './Tab.scss';

export const Tab = ({ clickHandler, isActive, children, id }) => {
    const classes = clsx('btn tab', { 'btn--active': isActive });
    return (
        <button id={id ? id : undefined} className={classes} onClick={clickHandler}>
            {children}
        </button>
    );
};

// PropTypes for this Component
Tab.propTypes = {
    id: PropTypes.string,
    clickHandler: PropTypes.func,
    isActive: PropTypes.bool,
    children: PropTypes.any,
};
