import store from '../store/index';

import {
    ADD_USER,
    UPDATE_USER_FILTER,
    SET_USER_CURRENT_PAGE,
    SET_FILTERED_USERS,
    SET_PAGINATED_USERS,
    TOGGLE_USER_FILTER_VISIBILITY,
    TOGGLE_USER_SORTING_ORDER,
    SET_USER_SORTING_PROPERTY,
    SET_SORTED_USERS,
    REMOVE_USER,
    UPDATE_USER,
    REPLACE_USERS,
    SET_USERS_FILTERED,
    RESET_USER_FILTER,
    CLOSE_USER_FILTER,
} from '../actionTypes.js';

import {
    getUsersAPI,
    updateUserAPI,
    addUserAPI,
    deleteUserByEmail,
} from '../../api/userAPI';
import { mapUsers, mapUser } from '../../api/apiHelper';

/**
 * All user actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const addUser = user => {
    return { type: ADD_USER, user };
};

export const addUserDispatch = async user => {
    const response = await addUserAPI(user, true);
    if (response.status === 409) {
        return response;
    }

    const mappedUser = mapUser(response);

    store.dispatch(addUser(mappedUser));
};

const getUsers = users => {
    return { type: REPLACE_USERS, users };
};

export const getUsersDispatch = async () => {
    const users = await getUsersAPI();
    const mappedUsers = mapUsers(users);
    store.dispatch(getUsers(mappedUsers));
};

const updateUser = user => {
    return { type: UPDATE_USER, user };
};

export const updateUserDispatch = async user => {
    const updatedUser = await updateUserAPI(user);
    const mappedUser = mapUser(updatedUser);

    store.dispatch(updateUser(mappedUser));
};

const removeUser = id => {
    return { type: REMOVE_USER, id };
};

export const removeUserDispatch = async (id, email) => {
    await deleteUserByEmail(email);
    store.dispatch(removeUser(id));
};

// FILTERING

const updateUserFilter = filter => {
    return { type: UPDATE_USER_FILTER, filter };
};

export const updateUserFilterDispatchThen = filter => {
    return new Promise(resolve => {
        store.dispatch(updateUserFilter(filter));
        resolve();
    });
};

const setFilteredUsers = filteredUsers => {
    return { type: SET_FILTERED_USERS, filteredUsers };
};

export const setFilteredUsersDispatch = filteredUsers => {
    store.dispatch(setFilteredUsers(filteredUsers));
};

const toggleUserFilterVisibility = () => {
    return { type: TOGGLE_USER_FILTER_VISIBILITY };
};

export const toggleUserFilterVisibilityDispatch = () => {
    store.dispatch(toggleUserFilterVisibility());
};

const closeUserFilter = () => {
    return { type: CLOSE_USER_FILTER };
};

export const closeUserFilterDispatch = () => {
    store.dispatch(closeUserFilter());
};

const setUsersFiltered = isFiltered => {
    return { type: SET_USERS_FILTERED, isFiltered };
};

export const setUsersFilteredDispatch = isFiltered => {
    store.dispatch(setUsersFiltered(isFiltered));
};

const resetUserFilters = () => {
    return { type: RESET_USER_FILTER };
};

export const resetUserFiltersDispatch = () => {
    store.dispatch(resetUserFilters());
};

// PAGINATION

const setUserCurrentPage = currentPage => {
    return { type: SET_USER_CURRENT_PAGE, currentPage };
};

export const setUserCurrentPageDispatch = currentPage => {
    store.dispatch(setUserCurrentPage(currentPage));
};

const setPaginatedUsers = paginatedUsers => {
    return { type: SET_PAGINATED_USERS, paginatedUsers };
};

export const setPaginatedUsersDispatch = paginatedUsers => {
    store.dispatch(setPaginatedUsers(paginatedUsers));
};

// SORTING

const toggleUserSortingOrder = () => {
    return { type: TOGGLE_USER_SORTING_ORDER };
};

export const toggleUserSortingOrderDispatch = () => {
    store.dispatch(toggleUserSortingOrder());
};

const setUserSortingProperty = sortingProperty => {
    return { type: SET_USER_SORTING_PROPERTY, sortingProperty };
};

export const setUserSortingPropertyDispatch = sortingProperty => {
    store.dispatch(setUserSortingProperty(sortingProperty));
};

const setSortedUsers = sortedUsers => {
    return { type: SET_SORTED_USERS, sortedUsers };
};

export const setSortedUsersDispatch = sortedUsers => {
    store.dispatch(setSortedUsers(sortedUsers));
};

export const updateUserOrganigramLevelsDispatch = (id, levels) => {
    const user = store.getState().users.find(user => user.id === id);
    const updatedUser = { ...user, ...levels };
    store.dispatch(updateUser(updatedUser));
}

export const resetUserDisclaimerAcceptedDispatch = (orgId) => {
    const users = store.getState().users.filter(user => user.organization.id === orgId);
    users.forEach(user => {
        store.dispatch(updateUser({ ...user, disclaimerAccpeted: false }));
    });
}