import PropTypes from 'prop-types';
import { OVERWRITE_APPLICATION_CONFIGS } from '../actionTypes';

export const initialState = [];

/**
 * application config reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === OVERWRITE_APPLICATION_CONFIGS) {
        return [...action.configs];
    }
    return state;
};

export const applicationProptype = {
    dispatchCenterId: PropTypes.number,
    id: PropTypes.number,
    enabledFeatures: PropTypes.arrayOf(PropTypes.string),
};

export default reducers;
