import React from 'react';

function Checked() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12.001">
            <path d="M14.563.29a1.211 1.211 0 0 0-1.724.154L5.76 9.007 2.011 5.813a1.211 1.211 0 0 0-1.724.154 1.256 1.256 0 0 0 .15 1.75l4.686 3.993c.517.443 1.29.372 1.725-.153l7.865-9.515a1.251 1.251 0 0 0-.15-1.747Z" />
        </svg>
    );
}

export default Checked;
