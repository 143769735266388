import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getMimeTypeAPI, postMimeTypeAPI } from '../../api/mimeTypeAPI';
import './CenterImageFormat.scss';
import { DEFAULT_MIMETYPE } from '../../config';

const CenterImageFormatComponent = ({ centers }) => {
    const [format, setFormat] = useState(DEFAULT_MIMETYPE);
    const [centerId, setCenterId] = useState(null);
    const params = useParams();

    useEffect(() => {
        const defer = async () => {
            const mimetype = await getMimeTypeAPI(centerId);
            setFormat(mimetype);
        };
        defer();
    }, [centerId]);

    useEffect(() => {
        const orgId = parseInt(params.id);
        const currentCenter = centers.filter(center => center.id === orgId)[0];
        setCenterId(currentCenter.addOnId);
    }, [centers, params]);

    const handleChange = async e => {
        const value = e.currentTarget.value;
        await postMimeTypeAPI(value, centerId);
        setFormat(value);
    };

    return (
        <div className="centerImageFormat">
            <div>
                <label htmlFor="formatPNG">
                    <input
                        type="radio"
                        name="imageFormat"
                        id="formatPNG"
                        checked={format === 'PNG'}
                        onChange={handleChange}
                        value="PNG"
                    />
                    <span>.png</span>
                </label>
            </div>
            <div>
                <label htmlFor="formatJPG">
                    <input
                        type="radio"
                        name="imageFormat"
                        id="formatJPG"
                        checked={format === 'JPG'}
                        onChange={handleChange}
                        value="JPG"
                    />
                    <span>.jpeg</span>
                </label>
            </div>
        </div>
    );
};

// PropTypes for this Component
CenterImageFormatComponent.propTypes = {
    centers: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        centers: state.dispatchCenters.centers,
    };
};

// Connect Props and Dispatch to Component
export const CenterImageFormat = connect(mapStateToProps)(
    CenterImageFormatComponent
);
