import React from 'react';

function Toggle() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M19.998 16.04a5.021 5.021 0 1 0-5.021-5.021 5.024 5.024 0 0 0 5.021 5.025M23.6 17.841h-7.2a5.4 5.4 0 0 0-5.4 5.392v8.4a.6.6 0 0 0 .6.6h16.8a.6.6 0 0 0 .6-.6v-8.4a5.4 5.4 0 0 0-5.4-5.4" />
        </svg>
    );
}

export default Toggle;
