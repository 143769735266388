const defaults = {
    authToken: null,
    bearerToken: null,
    username: null,
};

const oauthLogin = {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    loginCallback: process.env.REACT_APP_AUTH_LOGIN_CALLBACK,
    scope: process.env.REACT_APP_AUTH_SCOPE,
    state: process.env.REACT_APP_AUTH_STATE,
    loginUrl: process.env.REACT_APP_AUTH_LOGIN_URL,
};

export let oauth = { ...defaults };

/**
 * logout from application
 */
export const logout = () => {
    oauth = { ...defaults };
};

/**
 * redirect to login
 */
export const goToLogin = () => {
    const url = `${oauthLogin.loginUrl}?client_id=${
        oauthLogin.clientId
    }&scope=${encodeURIComponent(oauthLogin.scope)}&response_type=code&state=${
        oauthLogin.state
    }&redirect_uri=${encodeURIComponent(
        oauthLogin.loginCallback
    )}&response_mode=query`;

    window.location = url;
    // window.location = '/oauth';
};

/**
 * authenticate using the url params
 */
export const authenticate = async () => {
    const check = getQueryVariable('state');
    oauth.authToken = getQueryVariable('code');

    if (check === oauthLogin.state) {
        if (oauth.authToken) {
            const parse = JSON.parse(atob(oauth.authToken.split('.')[1]));
            oauth.userName = parse.sub;
            oauth.bearerToken = oauth.authToken;
            return;
        }
    }
};

/**
 * get a query variable from the url
 * @param {string} variable - key
 * @returns {string} value
 */
export const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
};
