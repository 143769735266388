import { ADD_API_TOKEN, REMOVE_API_TOKEN } from '../actionTypes';

export const initialState = '';

/**
 * api token reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const reducers = (state = initialState, action) => {
    if (action.type === ADD_API_TOKEN) {
        return action.apiToken;
    }
    if (action.type === REMOVE_API_TOKEN) {
        return '';
    }
    return state;
};

export default reducers;
