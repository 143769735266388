import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { connect } from 'react-redux';
import { replaceText } from '../../helper';

/**
 * Shows all error messages passed to the redux error store
 */

class ErrorDisplay extends PureComponent {
    render() {
        let error = replaceText(this.props.texts, 'error.unknown');
        let addon = replaceText(this.props.texts, 'error.addon');
        switch (this.props.match.params.message) {
            case '500':
                error = replaceText(this.props.texts, 'error.500');
                break;
            case '403':
                error = replaceText(this.props.texts, 'error.403');
                addon = replaceText(this.props.texts, 'error.403.addon');
                break;
            default:
                break;
        }

        return (
            <div>
                <Header hideLanguage={true} />
                <div className="container container--small">
                    <div className="alert alert--error">
                        <h3>
                            {replaceText(this.props.texts, 'error.headline')}
                        </h3>
                        <p>
                            <strong>{error}</strong>
                        </p>
                        <p>{addon}</p>
                    </div>
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
ErrorDisplay.propTypes = {
    match: PropTypes.any,
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return { texts: state.texts.texts };
};

export default connect(mapStateToProps)(ErrorDisplay);
