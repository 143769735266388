import PropTypes from 'prop-types';
import {
    ADD_USER,
    REMOVE_USER,
    UPDATE_USER,
    REPLACE_USERS,
} from '../actionTypes';

export const initialState = [];

/**
 * users reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const usersReducer = (state = initialState, action) => {
    if (action.type === REMOVE_USER) {
        return state.filter(user => user.id !== action.id);
    }
    if (action.type === UPDATE_USER) {
        return state.map(user => {
            if (user.id === action.user.id) {
                return { ...user, ...action.user };
            }
            return user;
        });
    }
    if (action.type === ADD_USER) {
        if (state.filter(user => user.id === action.user.id).length > 0)
            return state;
        return [...state, action.user];
    }
    if (action.type === REPLACE_USERS) {
        return [...action.users];
    }
    return state;
};

export const userProptypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    activated: PropTypes.bool,
    organization: PropTypes.shape(),
    scopeList: PropTypes.array,
    ipRestriction: PropTypes.string,
    role: PropTypes.string,
    disclaimerAccpeted: PropTypes.bool,
    dispatchCenter: PropTypes.string,
    level1: PropTypes.string,
    level2: PropTypes.string,
    level3: PropTypes.string,
};

export default usersReducer;
